import { Fragment, useRef, useEffect, useState } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Pagination from 'rc-pagination';
import { HTML5Backend } from 'react-dnd-html5-backend';

import IconButton from '../IconButton/IconButton';
import DragDropIcon from '../Icons/DragDrop';
import TriangeLeftIcon from '../Icons/TriangleLeft';
import TriangleRightIcon from '../Icons/TriangleRight';
import TriangleDownIcon from '../Icons/TriangleDown';
import Dropdown from '../Dropdown/Dropdown';
import Checkbox from '../Checkbox/Checkbox';
import Text from '../Text/Text';
import Tooltip from '../Tooltip/Tooltip';

const TableRow = ({
	headings,
	index,
	row,
	rowKey,
	disabled,
	selected,
	selectable,
	loading,
	handleCheckedChanged,
	handleRowMovedPreview,
	handleRowMoved,
	renderCell,
	dragDisabled,
	handleClick,
	handleMouseOver,
	handleMouseLeave,
}) => {
	const ref = useRef(null);
	const [canDrag, setCanDrag] = useState(false);

	const [{ handlerId }, drop] = useDrop({
		accept: 'TABLE_ROW',
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover: (item, monitor) => {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}
			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			// Determine mouse position
			const clientOffset = monitor.getClientOffset();
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// dont allow dragging of items that have their index present in `dragDisabled`
			if (
				dragDisabled.includes(dragIndex) ||
				dragDisabled.includes(hoverIndex)
			) {
				return;
			}

			handleRowMovedPreview(dragIndex, hoverIndex);
			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			// eslint-disable-next-line no-param-reassign
			item.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type: 'TABLE_ROW',
		canDrag,
		item: () => {
			return { id: row?.[rowKey], index };
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
		end: handleRowMoved,
	});

	const renderCellDraggable = (r, cId) => {
		if (cId === 'dragdrop') {
			return dragDisabled.includes(index) ? null : (
				<div
					ref={drag}
					onMouseEnter={() => setCanDrag(true)}
					onMouseLeave={() => setCanDrag(false)}
				>
					<IconButton
						icon={DragDropIcon}
						onClick={() => {}}
						className="w-2.5"
						cursor="cursor-move"
					/>
				</div>
			);
		}

		return renderCell(r, cId);
	};

	const isSelectable = selectable && !loading;
	drag(drop(ref));
	return (
		<tr
			ref={ref}
			className={clsx(
				'relative',
				disabled.includes(row?.[rowKey]) && 'opacity-50 cursor-not-allowed',
				isDragging && 'opacity-50 bg-ca-purple bg-opacity-10',
				handleClick && 'hover:bg-purple-50 cursor-pointer'
			)}
			onClick={handleClick ? () => handleClick(row) : undefined}
			onMouseOver={handleMouseOver ? () => handleMouseOver(row) : undefined}
			onMouseLeave={handleMouseLeave ? () => handleMouseLeave(row) : undefined}
			onFocus={handleMouseOver ? () => handleMouseOver(row) : undefined}
			data-handler-id={handlerId}
		>
			{isSelectable && (
				<td className="pl-5 pr-3">
					<Checkbox
						disabled={disabled.includes(row?.[rowKey])}
						checked={selected.includes(row?.[rowKey])}
						onChange={(checked) => handleCheckedChanged(row?.[rowKey], checked)}
					/>
				</td>
			)}
			{headings.map((col, colIndex) => (
				<td
					className={clsx(
						'p-3 text-sm text-ca-black leading-none whitespace-nowrap select-text',
						{
							'text-left': col?.align === 'left' || !col?.align,
							'text-right': col?.align === 'right',
							'text-center': col?.align === 'center',
						},
						colIndex === 0 && !isSelectable && 'pl-5',
						colIndex === headings.length - 1 && 'pr-5',
						col.id === 'dragdrop' && 'w-14'
					)}
					key={`${row?.[rowKey]}_${col.id}`}
				>
					{renderCellDraggable(row, col.id)}
				</td>
			))}
		</tr>
	);
};

TableRow.propTypes = {
	headings: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.string,
			tooltip: PropTypes.string,
			sortable: PropTypes.bool,
			align: PropTypes.oneOf(['left', 'center', 'right']),
		})
	),
	index: PropTypes.number.isRequired,
	row: PropTypes.any,
	rowKey: PropTypes.string,
	renderCell: PropTypes.func,
	handleCheckedChanged: PropTypes.func,
	handleRowMoved: PropTypes.func,
	handleRowMovedPreview: PropTypes.func,
	handleClick: PropTypes.func,
	handleMouseOver: PropTypes.func,
	disabled: PropTypes.array,
	selected: PropTypes.array,
	loading: PropTypes.bool,
	selectable: PropTypes.bool,
	dragDisabled: PropTypes.arrayOf(PropTypes.number),
};

TableRow.defaultProps = {
	headings: [],
	row: {},
	rowKey: 'id',
	renderCell: (row, columnId) => {
		row?.[columnId]?.toString();
	},
	selectable: false,
	selected: [],
	handleCheckedChanged: () => {},
	handleRowMoved: () => {},
	handleRowMovedPreview: () => {},
	disabled: [],
	loading: false,
	dragDisabled: [],
};

const Table = ({
	className,
	headings,
	rows: sourceRows,
	rowKey,
	renderCell,
	pagination,
	onPageChange,
	onItemsPerPageChange,
	sort,
	onSortChange,
	selectable,
	selected,
	onSelectedChange,
	onDragChange,
	onRowClick,
	onRowMouseOver,
	onRowMouseLeave,
	disabled,
	dragDisabled,
	open,
	renderOpen,
	loading,
	itemsLoading,
	emptyState,
	isInline,
}) => {
	const [rows, setRows] = useState([]);
	const allChecked = !rows.find((row) => !selected.includes(row?.[rowKey]));

	useEffect(() => {
		setRows(() => sourceRows);
	}, [sourceRows]);

	const handleCheckedChanged = (id, checked) => {
		if (checked) {
			onSelectedChange([...selected, id]);
		} else {
			onSelectedChange(selected.filter((rId) => rId !== id));
		}
	};

	const handleCheckAll = () => {
		if (allChecked) {
			onSelectedChange(
				selected.filter((s) => !rows.find((row) => row?.[rowKey] === s))
			);
		} else {
			onSelectedChange(
				[...selected, ...rows.map((row) => row?.[rowKey])]
					.filter(
						// filter out disabled rows
						(value) => !disabled.includes(value)
					)
					.filter(
						// filter out duplicates
						(value, index, self) => self.indexOf(value) === index
					)
			);
		}
	};

	const handleRowMoved = () => {
		onDragChange(rows?.map(({ [rowKey]: rKey }) => rKey));
	};

	const handleRowMovedPreview = (dragIndex, hoverIndex) => {
		setRows((prevRows) =>
			update(prevRows, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevRows[dragIndex]],
				],
			})
		);
	};

	if (!headings.length) return null;

	const isSelectable = selectable && !loading;

	return (
		<DndProvider backend={HTML5Backend}>
			<div
				className={clsx(
					'relative bg-white',
					!isInline ? 'rounded-lg shadow-ca-hack-silver' : ''
				)}
			>
				<div className="overflow-x-auto">
					<table
						className={clsx(
							'w-full table-auto divide-y divide-ca-silver',
							className
						)}
					>
						<thead>
							<tr className="relative">
								{isSelectable && (
									<th className="pl-5 pr-3">
										<Checkbox checked={allChecked} onChange={handleCheckAll} />
									</th>
								)}
								{headings.map((col, colIndex) => (
									<th
										key={col.id}
										className={clsx(
											'p-3 font-bold text-xs text-ca-black leading-none',
											{
												'text-left': col?.align === 'left' || !col?.align,
												'text-right': col?.align === 'right',
												'text-center': col?.align === 'center',
											},
											colIndex === 0 && !isSelectable && 'pl-5',
											colIndex === headings.length - 1 && 'pr-5',
											col?.sortable && 'cursor-pointer'
										)}
										onClick={() => {
											if (!col?.sortable) return;

											if (col?.id === sort?.key) {
												onSortChange({
													key: col?.id,
													direction: sort?.direction === 'asc' ? 'desc' : 'asc',
												});
											} else {
												onSortChange({
													key: col?.id,
													direction: 'desc',
												});
											}
										}}
										style={{ maxWidth: col?.maxWidth || 'unset' }}
									>
										<Tooltip content={col?.tooltip}>
											<span>{col?.label}</span>
										</Tooltip>
										{col?.id === sort?.key && (
											<TriangleDownIcon
												className={clsx(
													'inline ml-2 w-2.5 text-ca-gray',
													sort?.direction === 'asc' && 'transform rotate-180'
												)}
											/>
										)}
									</th>
								))}
							</tr>
						</thead>
						<tbody className="min-h-12 divide-y divide-ca-silver">
							{!loading && !rows.length && emptyState ? (
								<tr className="relative">
									<td className="text-center" colSpan="1000">
										<Text className="py-24">{emptyState}</Text>
									</td>
								</tr>
							) : null}
							{!loading && (!!rows.length || emptyState)
								? rows.map((row, index) => (
										<Fragment key={row?.[rowKey]}>
											<TableRow
												index={index}
												row={row}
												rowKey={rowKey}
												headings={headings}
												disabled={disabled}
												selected={selected}
												open={open}
												selectable={selectable}
												loading={loading}
												handleCheckedChanged={handleCheckedChanged}
												handleClick={onRowClick}
												handleMouseOver={onRowMouseOver}
												handleMouseLeave={onRowMouseLeave}
												renderCell={renderCell}
												renderOpen={renderOpen}
												handleRowMovedPreview={handleRowMovedPreview}
												handleRowMoved={handleRowMoved}
												dragDisabled={dragDisabled}
											/>
											{renderOpen && open?.includes(row?.[rowKey]) ? (
												<tr className="relative">
													<td colSpan={headings.length + (selectable ? 1 : 0)}>
														{renderOpen(row?.[rowKey])}
													</td>
												</tr>
											) : null}
										</Fragment>
								  ))
								: null}
							{loading &&
								Array(itemsLoading)
									.fill(0)
									.map((i, index) => (
										// eslint-disable-next-line react/no-array-index-key
										<tr key={`skeleton_loader_${index}`}>
											{headings.map((col, colIndex) => (
												<td
													className={clsx(
														'p-3',
														colIndex === 0 && 'pl-5',
														colIndex === headings.length - 1 && 'pr-5'
													)}
													// eslint-disable-next-line react/no-array-index-key
													key={`skeleton_loader_${index}_${col.id}`}
												>
													<div className="w-full h-6 bg-ca-silver rounded-full animate-pulse" />
												</td>
											))}
										</tr>
									))}
						</tbody>
					</table>
				</div>
				{!!Object.keys(pagination).length && (
					<div className="flex justify-between border-t border-ca-silver">
						<div className="flex items-center space-x-2 ml-2">
							{pagination?.itemsPerPageOptions && (
								<>
									<Dropdown
										onChange={onItemsPerPageChange}
										value={pagination.itemsPerPage}
										options={pagination.itemsPerPageOptions.map((n) => ({
											value: n,
											label: n,
										}))}
										size="small"
									/>
									<Text type="secondary" size="text-xs">
										results per page
									</Text>
								</>
							)}
						</div>
						<div className="flex divide-x divide-x-ca-silver">
							<Pagination
								className="flex items-center mr-2.5"
								current={pagination.currentPage}
								total={pagination.items}
								pageSize={pagination.itemsPerPage}
								onChange={onPageChange}
								locale="en"
								itemRender={(current, type) => {
									if (
										type === 'page' ||
										type === 'jump-prev' ||
										type === 'jump-next'
									) {
										return (
											<button
												key={current}
												type="button"
												className={clsx(
													'py-0.5 px-2 mx-1 text-xs tabular-nums cursor-pointer rounded transition-colors',
													current === pagination.currentPage
														? 'font-bold text-white bg-ca-purple'
														: 'text-ca-black hover:bg-ca-silver'
												)}
											>
												{type === 'page' ? current : '...'}
											</button>
										);
									}

									return null;
								}}
							/>
							<button
								type="button"
								onClick={() =>
									onPageChange(Math.max(pagination.currentPage - 1, 1))
								}
								className={clsx(
									'p-4 text-ca-gray transition-colors',
									pagination.currentPage === 1
										? 'cursor-not-allowed'
										: 'hover:text-ca-black'
								)}
								disabled={pagination.currentPage === 1}
							>
								<TriangeLeftIcon className="w-2" />
							</button>
							<button
								type="button"
								onClick={() =>
									onPageChange(
										Math.min(
											pagination.currentPage + 1,
											Math.ceil(pagination.items / pagination.itemsPerPage)
										)
									)
								}
								className={clsx(
									'p-4 text-ca-gray',
									pagination.currentPage ===
										Math.ceil(pagination.items / pagination.itemsPerPage)
										? 'cursor-not-allowed'
										: 'hover:text-ca-black'
								)}
								disabled={
									pagination.currentPage ===
									Math.ceil(pagination.items / pagination.itemsPerPage)
								}
							>
								<TriangleRightIcon className="w-2" />
							</button>
						</div>
					</div>
				)}
			</div>
		</DndProvider>
	);
};

Table.propTypes = {
	className: PropTypes.string,
	headings: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.string,
			tooltip: PropTypes.string,
			sortable: PropTypes.bool,
			align: PropTypes.oneOf(['left', 'center', 'right']),
			maxWidth: PropTypes.string,
		})
	),
	rows: PropTypes.arrayOf(PropTypes.any),
	rowKey: PropTypes.string,
	renderCell: PropTypes.func,
	pagination: PropTypes.shape({
		currentPage: PropTypes.number,
		items: PropTypes.number,
		itemsPerPage: PropTypes.number,
		itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
	}),
	onPageChange: PropTypes.func,
	onItemsPerPageChange: PropTypes.func,
	onRowClick: PropTypes.func,
	onRowMouseOver: PropTypes.func,
	onRowMouseLeave: PropTypes.func,
	sort: PropTypes.shape({
		key: PropTypes.string,
		direction: PropTypes.oneOf(['asc', 'desc']),
	}),
	onSortChange: PropTypes.func,
	selectable: PropTypes.bool,
	selected: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	),
	onSelectedChange: PropTypes.func,
	onDragChange: PropTypes.func,
	dragDisabled: PropTypes.arrayOf(PropTypes.number),
	disabled: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	),
	open: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	),
	renderOpen: PropTypes.func,
	loading: PropTypes.bool,
	itemsLoading: PropTypes.number,
	emptyState: PropTypes.string,
	isInline: PropTypes.bool,
};

Table.defaultProps = {
	headings: [],
	rows: [],
	rowKey: 'id',
	renderCell: (row, columnId) => {
		row?.[columnId]?.toString();
	},
	pagination: {},
	onPageChange: () => {},
	onItemsPerPageChange: () => {},
	sort: {},
	onSortChange: () => {},
	selectable: false,
	selected: [],
	onSelectedChange: () => {},
	onDragChange: () => {},
	dragDisabled: [],
	disabled: [],
	open: [],
	renderOpen: null,
	loading: false,
	itemsLoading: 10,
	emptyState: '',
	isInline: false,
};

export default Table;
