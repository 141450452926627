import { Fragment, useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import search from '../../utils/search';

import Text from '../Text/Text';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import Dropdown from '../Dropdown/Dropdown';
import Slider from '../Slider/Slider';

const FilterValueMenu = ({
	field,
	value,
	options,
	type,
	decimals,
	inputProps,
	percentage,
	visible,
	className,
	onApply,
	onCancel,
	onRemove,
}) => {
	const [localValue, setLocalValue] = useState(value);
	const step = options?.[1] - options?.[0] <= 1 ? 5 : 1;
	useEffect(() => {
		if (type === 'Range') {
			if (!value) {
				setLocalValue([
					options?.[0].toFixed(decimals),
					options?.[1].toFixed(decimals),
				]);
			} else if (!Array.isArray(value)) {
				const [min, max] = value.split(' - ');
				setLocalValue([
					parseFloat(min).toFixed(decimals),
					parseFloat(max).toFixed(decimals),
				]);
			}
		} else {
			setLocalValue(value);
		}
	}, [value]);

	const handleChecked = (option, checked) => {
		if (checked) {
			setLocalValue((lv) => [...lv, option]);
		} else {
			setLocalValue((lv) => lv.filter((v) => v !== option));
		}
	};

	const [query, setQuery] = useState('');

	return (
		<Transition
			appear
			show={visible}
			as={Fragment}
			enter="transition ease-out duration-100"
			enterFrom="transform opacity-0 scale-95"
			enterTo="transform opacity-100 scale-100"
			leave="transition ease-in duration-75"
			leaveFrom="transform opacity-100 scale-100"
			leaveTo="transform opacity-0 scale-95"
		>
			<div
				className={clsx(
					'origin-top-right absolute left-0 mt-2 p-3 bg-white rounded-lg shadow-ca focus:outline-none z-20',
					className
				)}
			>
				<Text size="text-xs" className="font-bold">
					{field}
				</Text>
				<div
					className={clsx(
						'py-4 space-y-2',
						type === 'Checkbox' && 'max-h-40 overflow-y-auto'
					)}
				>
					{type === 'List' && (
						<Dropdown
							onChange={setLocalValue}
							value={localValue}
							options={options.map((option) => ({
								label: option,
								value: option,
							}))}
							className="w-full"
							{...inputProps}
						/>
					)}

					{type === 'Checkbox' && (
						<input
							className="text-sm text-black"
							placeholder="Search"
							onChange={(event) => setQuery(event.target.value)}
						/>
					)}

					{type === 'Checkbox' && (
						<>
							{options
								.filter((option) => {
									if (query === '') {
										return option;
									}
									if (search(option, query)) {
										return option;
									}

									return null;
								})
								.map((option) => (
									<Checkbox
										key={option}
										checked={localValue?.includes(option)}
										label={option}
										onChange={(checked) => handleChecked(option, checked)}
										{...inputProps}
									/>
								))}
						</>
					)}
					{type === 'Range' && Array.isArray(localValue) && (
						<Slider
							value={(localValue || []).map((lv) =>
								(lv * (percentage ? 100 : 1)).toFixed(decimals)
							)}
							className="mb-2 w-64"
							onChange={(val) =>
								setLocalValue(
									val.map((lv) => lv.toFixed(decimals) / (percentage ? 100 : 1))
								)
							}
							min={percentage ? options?.[0] * 100 : options?.[0]}
							max={percentage ? options?.[1] * 100 : options?.[1]}
							step={step}
							textInputProps={percentage ? { endAdornment: '%' } : {}}
						/>
					)}
				</div>
				<div className="flex justify-between">
					{onRemove && (
						<Button size="small" variant="secondary" onClick={onRemove}>
							Remove
						</Button>
					)}
					{onCancel && (
						<Button size="small" variant="secondary" onClick={onCancel}>
							Cancel
						</Button>
					)}
					<Button
						disabled={!localValue || !localValue?.length}
						size="small"
						onClick={() => onApply(localValue)}
					>
						Apply
					</Button>
				</div>
			</div>
		</Transition>
	);
};

FilterValueMenu.propTypes = {
	field: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.arrayOf(PropTypes.number),
		PropTypes.string,
		PropTypes.number,
	]),
	options: PropTypes.arrayOf(PropTypes.any),
	type: PropTypes.oneOf([null, 'List', 'Checkbox', 'Range']),
	inputProps: PropTypes.any,
	percentage: PropTypes.bool,
	decimals: PropTypes.number,
	visible: PropTypes.bool,
	className: PropTypes.string,
	onApply: PropTypes.func,
	onCancel: PropTypes.func,
	onRemove: PropTypes.func,
};

FilterValueMenu.defaultProps = {
	value: '',
	options: [],
	type: null,
	percentage: false,
	decimals: 0,
	visible: false,
	inputProps: {},
	className: '',
	onApply: () => {},
	onCancel: null,
	onRemove: null,
};

export default FilterValueMenu;
