import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { GET_STRATEGY_RUN_STATUS } from '../../api/strategies';

import useChannelQuery from '../../hooks/useChannelQuery';

import Text from '../Text/Text';
import Tooltip from '../Tooltip/Tooltip';

const AlgorithmStatus = ({ strategySlug }) => {
	const { data } = useChannelQuery(
		['strategy-run-status', strategySlug],
		() => GET_STRATEGY_RUN_STATUS(strategySlug),
		{
			// poll algorithm status every 30 sec, even when tab is in the background
			refetchInterval: 30000,
			refetchIntervalInBackground: true,
		}
	);

	// hide this component when no status has been received yet
	if (!data?.last_started_run) {
		return null;
	}

	return (
		<Tooltip
			content={
				<>
					{!!data?.last_started_run && (
						<>
							Last started at:{' '}
							{dayjs(data?.last_started_run).format('DD/MM/YYYY [at] HH:mm:ss')}
							. <br />
						</>
					)}
					{!!data?.last_successful_run && (
						<>
							Last successfully finished at:{' '}
							{dayjs(data?.last_successful_run).format(
								'DD/MM/YYYY [at] HH:mm:ss'
							)}
							. <br />
							<br />
						</>
					)}
					{!!data?.last_errored_run && !data?.last_was_successful && (
						<>
							Last run failed at:{' '}
							{dayjs(data?.last_errored_run).format('DD/MM/YYYY [at] HH:mm:ss')}
							. <br />
							<br />
						</>
					)}
				</>
			}
		>
			<span className="flex items-center">
				<Text type="secondary" size="text-xs">
					Last algorithm run:{' '}
					{dayjs(data.last_started_run).format('DD/MM/YYYY [at] HH:mm:ss')}
				</Text>
				{data?.is_running && (
					<span className="relative flex h-2 w-2 ml-2">
						<span className="absolute inline-flex h-full w-full rounded-full opacity-80 animate-ping bg-ca-orange" />
						<span className="relative inline-flex rounded-full h-2 w-2 bg-ca-orange" />
					</span>
				)}
				{!data?.is_running && data?.is_up_to_date && (
					<span className="relative flex h-2 w-2 ml-1 rounded-full bg-ca-green" />
				)}
				{!data?.is_running &&
					!data?.is_up_to_date &&
					!!data?.last_errored_run &&
					!data?.last_was_successful && (
						<span className="relative flex h-2 w-2 ml-1 rounded-full bg-ca-red" />
					)}
			</span>
		</Tooltip>
	);
};

AlgorithmStatus.propTypes = {
	strategySlug: PropTypes.string.isRequired,
};

export default AlgorithmStatus;
