import PropTypes from 'prop-types';

const Checkmark = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 11 8"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m11 1.6-6.7683 6.4-.0011-.001v.001l-4.2306-4 1.6918-1.6 2.5388 2.4 5.0776-4.8z"
			fill="currentColor"
		/>
	</svg>
);

Checkmark.propTypes = {
	className: PropTypes.string,
};

Checkmark.defaultProps = {
	className: '',
};

export default Checkmark;
