import { GET_CUMULIO_SCENARIO_COMPARISON_SSO } from '../../shared/api/reports';

import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';

const ScenarioOverview = () => {
	return (
		<>
			<div className="relative py-6">
				<FullCumulioDashboard
					queryKey="cumulio-scenario-comparison"
					ssoQuery={GET_CUMULIO_SCENARIO_COMPARISON_SSO}
					showLoading={false}
				/>
			</div>
		</>
	);
};

export default ScenarioOverview;
