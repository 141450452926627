import PropTypes from 'prop-types';
import EngineButton from '../EngineButton/EngineButton';
import { GET_STRATEGY_RUN_STATUS, RUN_STRATEGY } from '../../api/strategies';

const AlgorithmRunButton = ({ name, strategyId }) => {
	return (
		<EngineButton
			name={name}
			strategyId={strategyId}
			statusGetCall={GET_STRATEGY_RUN_STATUS}
			runPostCall={RUN_STRATEGY}
			engineName="algorithm"
		/>
	);
};
AlgorithmRunButton.propTypes = {
	name: PropTypes.string,
	strategyId: PropTypes.string.isRequired,
};
export default AlgorithmRunButton;
