import PropTypes from 'prop-types';

import Text from '../Text/Text';

const Title = ({ children }) => (
	<Text size="text-base" className="font-bold mb-8">
		{children}
	</Text>
);

const Content = ({ children }) => <div className="p-10">{children}</div>;

const Actions = ({ children, className = '' }) => (
	<div className={`py-5 px-10 rounded-b-2xl bg-ca-ghost-white ${className}`}>
		{children}
	</div>
);

const Root = ({ children }) => {
	return (
		<div className="max-w-lg bg-white rounded-2xl shadow-ca">{children}</div>
	);
};

const OnlyChildrenPropTypes = {
	children: PropTypes.node.isRequired,
};

Root.propTypes = OnlyChildrenPropTypes;
Title.propTypes = OnlyChildrenPropTypes;
Content.propTypes = OnlyChildrenPropTypes;
Actions.propTypes = OnlyChildrenPropTypes;

export default { Root, Title, Content, Actions };
