import Title from '../../shared/components/Title/Title';
import Greeting from '../../shared/components/Greeting/Greeting';
import ChannelSelector from '../../shared/components/ChannelSelector/ChannelSelector';

import SupportNav from './SupportNav';

const ActualsHeader = () => (
	<>
		<div className="flex justify-between">
			<Title>Data Validation</Title>
			<ChannelSelector />
		</div>
		<div className="my-2">
			<Greeting />
		</div>
		<div className="mt-6">
			<SupportNav />
		</div>
	</>
);

export default ActualsHeader;
