import { Redirect, useLocation } from 'react-router-dom';
import { FunctionComponent, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import useJWT from '../../data/jwt';

import LogOutIcon from '../Icons/LogOut';
import HelpIcon from '../Icons/Help';
import PlatformSelect from '../PlatformSelect/PlatformSelect';
import SidebarLink, { SidebarLinkProps } from './SidebarLink';
import useChannelStore from '../../data/channels';
import Tooltip from '../Tooltip/Tooltip';

export interface SidebarLinkType extends SidebarLinkProps {
	id: string;
	isEnabled: boolean | [string];
}

interface Props {
	activePlatform: string;
	links: SidebarLinkType[];
}

const Sidebar: FunctionComponent<Props> = ({ activePlatform, links }) => {
	const { isLoading, isAuthenticated, logout: auth0Logout } = useAuth0();
	const { setJWT } = useJWT();
	const location = useLocation();
	const isGlobalMode =
		window._ENV_.REACT_APP_GLOBAL_CHANNEL_ENABLED &&
		location.pathname.startsWith('/global');
	const { activeChannel } = useChannelStore();

	const [activeLinks, setActiveLinks] = useState(links);

	useEffect(() => {
		const isEnabledCheck = (isEnabled: boolean | [string]): boolean => {
			if (typeof isEnabled === 'boolean') {
				return isEnabled;
			}
			return isEnabled.includes(activeChannel);
		};
		setActiveLinks(
			links.filter(
				({ id, isEnabled }) =>
					(isEnabledCheck(isEnabled) && !isGlobalMode) ||
					(isGlobalMode && id === 'global')
			)
		);
	}, [activeChannel, isGlobalMode]);

	console.debug(isLoading);
	console.debug(isAuthenticated);
	if (!isLoading && !isAuthenticated) {
		return (
			<Redirect
				to={{
					pathname: '/login',
					state: { from: location },
				}}
			/>
		);
	}

	const logout = () => {
		setJWT({ jwt: null });
		auth0Logout({ returnTo: window.location.origin });
	};

	const openDocPage = () => {
		window.open('http://knowledge.crunchplatform.eu');
	};

	return (
		<div className="fixed w-40 h-screen flex flex-col justify-between bg-white border-r border-ca-black border-opacity-5 z-10">
			<div>
				<div className="flex justify-center py-10">
					<PlatformSelect activePlatform={activePlatform} />
				</div>
				<ul className="flex flex-col py-10 group">
					{activeLinks.map(({ id, ...rest }) => (
						<SidebarLink key={id} {...rest} />
					))}
				</ul>
			</div>
			<div className="flex justify-center items-center p-10 flex-col">
				<Tooltip content="Link to Knowledge Base">
					<button
						className="p-4 border-2 border-ca-silver rounded-full hover:border-ca-purple focus:border-ca-purple group transition-colors mb-2"
						type="button"
						onClick={openDocPage}
					>
						<HelpIcon className="w-4 text-ca-gray group-hover:text-ca-purple transition-colors" />
					</button>
				</Tooltip>
				<button
					className="p-4 border-2 border-ca-silver rounded-full hover:border-ca-purple focus:border-ca-purple group transition-colors"
					type="button"
					onClick={logout}
				>
					<LogOutIcon className="w-4 text-ca-gray group-hover:text-ca-purple transition-colors" />
				</button>
			</div>
		</div>
	);
};

export default Sidebar;
