import create from 'zustand';
import { persist } from 'zustand/middleware';

const useChannelStore = create(
	persist(
		(set) => ({
			channels: [],
			activeChannel: '',
			setChannels: (channels) => set({ channels }),
			setActiveChannel: (activeChannel) => set({ activeChannel }),
		}),
		{
			name: 'ca-channels-store',
			whitelist: ['activeChannel'],
		}
	)
);

export default useChannelStore;
