import React from 'react';
import clsx from 'clsx';
import tailwindColors from 'tailwindcss/colors';
import * as d3 from 'd3';
import range from '../../shared/utils/range';
import Text from '../../shared/components/Text/Text';
import LoadingSpinner from '../../shared/components/LoadingSpinner/LoadingSpinner';
import { ObjectiveScenario } from '../types/residualValueTypes';

type IntensityLevel = number;

type ResidualValuePresetSliderProps = {
	className?: string;
	options: ObjectiveScenario[];
	value: IntensityLevel | 'custom';
	onChange: (e: any) => {};
};

type SliderBarProps = {
	/** Css value like rgb(...), #ff00ff etc */
	backgroundColorCss: string;
	/** Css value like rgb(...), #ff00ff etc */
	foregroundColorCss: string;
	/** Between 0 and 1 */
	progress: number;
	className?: string;
};

const SliderBar: React.FC<SliderBarProps> = (props) => {
	return (
		<div
			className={clsx(
				props.className ?? '',
				'relative rounded-full h-1 w-full'
			)}
			style={{ backgroundColor: props.backgroundColorCss }}
		>
			<div
				className="absolute rounded-full h-1 left-0 top-0 bottom-0 transition-all"
				style={{
					backgroundColor: props.foregroundColorCss,
					right: `${100 - props.progress * 100}%`,
				}}
			/>
		</div>
	);
};

type SliderLabelProps = {
	/** Between 0 and 1 */
	progress: number;
	text: string;
};

const SliderLabel: React.FC<SliderLabelProps> = (props) => {
	return (
		<div
			className="absolute -translate-x-1/2 z-0 transition-all pointer-events-none select-none"
			style={{ left: `${props.progress * 100}%` }}
		>
			<Text className="absolute top-3 left-1/2 -translate-x-1/2 text-zinc-800 px-2 pb-0.5 rounded-md transition-all">
				{props.text}
			</Text>
		</div>
	);
};

const NOTCH_SIZES = {
	sm: {
		tailwindClass: 'w-2 h-2 border',
		px: 8,
	},
	lg: {
		tailwindClass: 'w-4 h-4 border-2',
		px: 16,
	},
} as const;

type SliderNotchProps = {
	/** Between 0 and 1 */
	progress: number;
	size: keyof typeof NOTCH_SIZES;
	colorCss?: string;
	borderColorCss?: string;
};

const SliderNotch: React.FC<SliderNotchProps> = (props) => {
	return (
		<div
			className={clsx(
				NOTCH_SIZES[props.size].tailwindClass,
				'absolute rounded-full transition-all'
			)}
			style={{
				borderColor: props.borderColorCss,
				backgroundColor: props.colorCss,
				left: `${props.progress * 100}%`,
				transform: `translate(${
					-props.progress * NOTCH_SIZES[props.size].px
				}px, -50%)`,
			}}
		/>
	);
};

type SliderNotchesProps = {
	step: number;
	min: number;
	max: number;
	value: number;
	activeFillColorCss: string;
	activeBorderColorCss: string;
	inactiveFillColorCss: string;
	inactiveBorderColorCss: string;
};

const SliderNotches: React.FC<SliderNotchesProps> = (props) => {
	return (
		<div className="row-start-1 row-span-1 col-start-1 col-span-1 relative">
			{range(props.min, props.max, props.step).map((t) => (
				<SliderNotch
					progress={t}
					size={Math.abs(t - props.value) <= Number.EPSILON ? 'lg' : 'sm'}
					colorCss={
						t - props.value < -Number.EPSILON
							? props.activeFillColorCss
							: props.inactiveFillColorCss
					}
					borderColorCss={
						t - props.value <= Number.EPSILON
							? props.activeBorderColorCss
							: props.inactiveBorderColorCss
					}
					key={`notch-${t}`}
				/>
			))}
		</div>
	);
};

const ResidualValuePresetSlider: React.FC<ResidualValuePresetSliderProps> = (
	props
) => {
	if (props?.options?.length === 0 || props.value === undefined) {
		return (
			<div>
				Loading...
				<LoadingSpinner variant="lg" />
			</div>
		);
	}

	const colorScale = d3.scaleLinear(
		[0, 0.3, 0.75, 1],
		[
			tailwindColors.emerald['400'],
			tailwindColors.green['500'],
			tailwindColors.yellow['500'],
			tailwindColors.red['500'],
		]
	);

	const orderedDefaultScenarios = (props.options ?? []).sort(
		(a, b) => a.intensity_level - b.intensity_level
	);
	const step = props.options?.length < 2 ? 1 : 1 / (props.options?.length - 1);
	let activeName = '';
	let labelPosition = NaN;
	let activeHexColor = '';
	let sliderValue = NaN;

	if (props.value === 'custom') {
		activeName = props.value;
		labelPosition = 0.5;
		sliderValue = NaN;
	} else if (props.value !== undefined) {
		const foundIndex =
			orderedDefaultScenarios.findIndex(
				(scenario) => scenario.intensity_level === props.value
			) ?? 1;
		sliderValue =
			orderedDefaultScenarios.length === 1 ? step : foundIndex * step;

		const valueIndex = orderedDefaultScenarios.findIndex(
			(scenario) => scenario.intensity_level === props.value
		);
		activeName = orderedDefaultScenarios[valueIndex].name;
		labelPosition = sliderValue;
		activeHexColor = d3.color(colorScale(sliderValue))?.formatHex() ?? '';
	}

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;
		const scenarioIndex =
			orderedDefaultScenarios.length === 1
				? 0
				: Math.round((parseFloat(value) * 1) / step);
		props.onChange(orderedDefaultScenarios[scenarioIndex].intensity_level);
	};

	return (
		<div className={clsx(props.className, 'flex gap-4')}>
			<Text size="text-sm" type="secondary" key="slider-low">
				Low
			</Text>
			<div className="relative grid grid-rows-1 grid-cols-1 items-center flex-grow">
				<SliderBar
					className="row-start-1 col-start-1"
					progress={sliderValue}
					backgroundColorCss={tailwindColors.zinc['200']}
					foregroundColorCss={activeHexColor}
				/>
				<SliderLabel progress={labelPosition} text={activeName} />
				<SliderNotches
					min={0}
					max={1}
					step={step}
					value={sliderValue}
					activeFillColorCss={activeHexColor}
					activeBorderColorCss={activeHexColor}
					inactiveFillColorCss={tailwindColors.zinc['200']}
					inactiveBorderColorCss={tailwindColors.zinc['300']}
				/>
				<input
					type="range"
					min={0}
					max={1}
					step={step}
					value={sliderValue.toString()}
					className="row-start-1 col-start-1 z-10 opacity-0 w-full"
					onChange={handleOnChange}
				/>
			</div>
			<Text size="text-sm" type="secondary" key="slider-high">
				High
			</Text>
		</div>
	);
};

export default ResidualValuePresetSlider;
