import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useHistory } from 'react-router-dom';

import { useMutation, useQueryClient } from 'react-query';

import useChannelQuery from '../../shared/hooks/useChannelQuery';

import {
	DELETE_STRATEGY,
	GET_STRATEGY_RUN_STATUS,
	LIST_STRATEGIES,
	RUN_STRATEGY,
} from '../../shared/api/strategies';
import Button from '../../shared/components/Button/Button';
import IconButton from '../../shared/components/IconButton/IconButton';
import CheckmarkIcon from '../../shared/components/Icons/Checkmark';
import CrossIcon from '../../shared/components/Icons/Cross';
import InfoIcon from '../../shared/components/Icons/Info';
import TrashIcon from '../../shared/components/Icons/Trash';
import Modal from '../../shared/components/Modal/Modal';
import CircularProgress from '../../shared/components/Progress/CircularProgress';
import LinearProgress from '../../shared/components/Progress/LinearProgress';
import Table from '../../shared/components/Table/Table';
import Tooltip from '../../shared/components/Tooltip/Tooltip';
import useChannelQueries from '../../shared/hooks/useChannelQueries';
import useModal from '../../shared/hooks/useModal';
import get from '../../shared/utils/get';
import StrategyCreateModal from '../components/StrategyCreateModal';
import TriangleRightIcon from '../../shared/components/Icons/TriangleRight';
import Text from '../../shared/components/Text/Text';

const HEADINGS = [
	{ id: 'name', label: 'Name', align: 'left' },
	{
		id: 'status.last_started_run',
		label: 'Last start date',
		align: 'center',
		isDate: true,
	},
	{
		id: 'status.last_successful_run',
		label: 'Last succesful date',
		align: 'center',
		isDate: true,
	},
	{
		id: 'status.last_was_successful',
		label: 'Status',
		align: 'center',
	},
	{ id: 'actions', label: '', align: 'right' },
];

const STRATEGY_QUERY_KEY = ['strategies'];

const StrategyOverview = () => {
	dayjs.extend(relativeTime);
	const history = useHistory();
	const queryClient = useQueryClient();
	const {
		isLoading,
		data,
		channel: activeChannel,
	} = useChannelQuery(STRATEGY_QUERY_KEY, LIST_STRATEGIES);
	const { open, close } = useModal();
	const statusQueries = useChannelQueries({
		queries: data?.map((strategy) => {
			return {
				queryKey: ['strategy-run-status', strategy.slug],
				queryFn: () => GET_STRATEGY_RUN_STATUS(strategy.slug),
				// poll algorithm status every 30 sec, even when tab is in the background
				refetchInterval: 30000,
				refetchIntervalInBackground: true,
			};
		}),
	});
	const statuses = statusQueries?.map((statusQuery) => statusQuery.data);
	const strategies = data?.map((strategy) => {
		const status = statuses.find((s) => s?.strategy === strategy?.slug);
		return {
			...strategy,
			status,
		};
	});

	const { isLoading: isDeleteLoading, mutate: deleteStrategy } = useMutation(
		DELETE_STRATEGY,
		{
			onMutate: async (strategyId) => {
				await queryClient.cancelQueries({
					queryKey: [activeChannel, 'strategies'],
				});

				// Snapshot the previous value
				const previousStrategies = queryClient.getQueryData([
					activeChannel,
					'strategies',
				]);

				// Optimistically update to the new value
				queryClient.setQueryData([activeChannel, 'strategies'], (old) =>
					old.filter((strategy) => strategy.id !== strategyId)
				);

				// Return a context object with the snapshotted value
				return { previousStrategies };
			},
			onError: (context) => {
				queryClient.setQueryData(
					[activeChannel, 'strategies'],
					context.previousStrategies
				);
			},
			// Always refetch after error or success:
			onSettled: () => {
				queryClient.invalidateQueries({
					queryKey: [activeChannel, 'strategies'],
				});
				close();
			},
		}
	);
	const { mutate: runStrategy } = useMutation(RUN_STRATEGY, {
		onMutate: async (strategySlug) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			const queryKey = [activeChannel, 'strategy-run-status', strategySlug];
			await queryClient.cancelQueries(queryKey);

			const previousRunStatus = queryClient.getQueryData(queryKey);

			// override opportunities to have a loading state set to `true` for the overwritten opp
			queryClient.setQueriesData(queryKey, () => ({
				...previousRunStatus,
				is_running: true,
			}));
			// Return a context object with the snapshotted value
			return { previousRunStatus };
		},
		// If the mutation fails, use the context returned from onMutate to roll back
		onError: (err, strategySlug, context) => {
			const queryKey = [activeChannel, 'strategy-run-status', strategySlug];
			queryClient.setQueryData(queryKey, context.previousRunStatus);
		},
		// Always refetch after error or success:
		onSettled: (strategySlug) => {
			const queryKey = [activeChannel, 'strategy-run-status', strategySlug];
			queryClient.invalidateQueries(queryKey);
		},
	});

	const confirmDeleteStrategy = (strategy) => {
		close();
		open(<CircularProgress />);
		deleteStrategy(strategy.id);
	};

	const clickDeleteStrategy = (strategy) => {
		open(
			<Modal.Root>
				<Modal.Content>
					<Modal.Title>Delete strategy {`"${strategy.name}"`}</Modal.Title>
					Are you sure?
				</Modal.Content>
				<Modal.Actions>
					<div className="flex justify-between">
						<Button variant="secondary" onClick={close}>
							Cancel
						</Button>
						<Button
							variant="danger"
							disabled={isDeleteLoading}
							onClick={() => confirmDeleteStrategy(strategy)}
						>
							Delete
						</Button>
					</div>
				</Modal.Actions>
			</Modal.Root>
		);
	};

	const newStrategyClick = () => {
		open(<StrategyCreateModal />);
	};

	const handleRun = (strategy) => {
		runStrategy(strategy.slug);
		close();
	};

	const runAlgorithmClick = (strategy) =>
		open(
			<Modal.Root>
				<Modal.Content>
					<Modal.Title>Run algorithm for {strategy.name} strategy</Modal.Title>
					<div className="space-y-4">
						<Text>
							Are you sure you want to run the algorithm for this strategy?
							<br /> This will update the opportunities to match the settings
							for the <strong>{strategy.name}</strong> strategy.
						</Text>
						<Text className="italic">
							This might take up to 30 minutes and can not be restarted until it
							has been completed.
						</Text>
						{!!strategy?.status?.last_errored_run &&
							!strategy?.status?.last_was_successful && (
								<>
									<Text className="text-ca-red">
										Warning: The last algorithm run of this strategy was
										unsuccesful.
									</Text>
									<Text type="secondary">
										If the problem persists,{' '}
										<a
											className="underline"
											href="mailto:support@crunch.fashion"
										>
											contact Crunch
										</a>
										.
									</Text>
								</>
							)}
					</div>
				</Modal.Content>
				<Modal.Actions>
					<div className="flex justify-between">
						<Button variant="secondary" onClick={close}>
							Cancel
						</Button>
						<Button onClick={() => handleRun(strategy)}>Run algorithm</Button>
					</div>
				</Modal.Actions>
			</Modal.Root>
		);
	const strategyAddEnabled = strategies?.length < 10;

	return (
		<>
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress visible={isLoading} />
			</div>
			<div className="py-6 space-y-6">
				{strategyAddEnabled ? (
					<Button size="small" onClick={newStrategyClick} variant="primary">
						Add strategy
					</Button>
				) : (
					<Tooltip
						content="You can only have 10 strategies per channel."
						placement="right"
					>
						<span className="relative inline-flex">
							<Button
								size="small"
								onClick={newStrategyClick}
								disabled
								variant="secondary"
							>
								Add strategy
							</Button>
						</span>
					</Tooltip>
				)}
				<Table
					loading={isLoading}
					itemsLoading={1}
					headings={HEADINGS}
					rows={strategies}
					emptyState="No strategies exist. Create one with the button above."
					onRowClick={(row) =>
						history.push(`/strategy/strategies/${row?.slug}`)
					}
					disabled={data
						?.filter((row) => row.slug === undefined)
						.map((row) => row.id)}
					renderCell={(row, columnId) => {
						const isRunning = row?.status?.is_running;
						const lastStartedRun = row?.status?.last_started_run;
						if (columnId === 'status.last_was_successful' && row?.status) {
							const lastSuccess = get(row, columnId);
							if (isRunning) {
								return (
									<Tooltip
										content={
											<>
												Strategy running.{` `}
												{row?.status?.last_successful_run
													? `Started at: ${dayjs(
															row?.status?.last_started_run
													  ).format('DD/MM/YYYY [at] HH:mm:ss')}`
													: null}
											</>
										}
									>
										<span className="relative inline-flex h-3 w-3">
											<span className="absolute inline-flex h-full w-full rounded-full opacity-80 animate-ping bg-ca-green" />
											<span className="relative inline-flex rounded-full h-3 w-3 bg-ca-green" />
										</span>
									</Tooltip>
								);
							}
							if (lastStartedRun === null) {
								return (
									<Tooltip content="The strategy hasn't run yet. Run it with the run button on the right.">
										<span className="inline-flex">
											<InfoIcon className="h-5 w-5 text-ca-purple" />
										</span>
									</Tooltip>
								);
							}
							if (lastSuccess) {
								return (
									<Tooltip content="Last run was successful">
										<span className="inline-flex">
											<CheckmarkIcon className="h-3 text-green-600" />
										</span>
									</Tooltip>
								);
							}

							return (
								<Tooltip content="Last run failed">
									<span className="inline-flex">
										<CrossIcon className="h-3 text-red-600" />
									</span>
								</Tooltip>
							);
						}
						if (columnId === 'actions') {
							return (
								<>
									<span className="relative inline-flex">
										{row?.status && (
											<IconButton
												tooltip={
													isRunning
														? 'Strategy is already running'
														: 'Run Strategy'
												}
												icon={TriangleRightIcon}
												disabled={isRunning}
												onClick={(event) => {
													runAlgorithmClick(row);
													event.stopPropagation();
												}}
											/>
										)}
										<IconButton
											tooltip="Delete"
											icon={TrashIcon}
											onClick={(event) => {
												clickDeleteStrategy(row);
												event.stopPropagation();
											}}
										/>
									</span>
								</>
							);
						}
						const heading = HEADINGS.find((h) => h.id === columnId);
						let colVal = get(row, columnId);
						let tooltipValue = null;
						if (heading?.isDate) {
							if (colVal) {
								const fromNowVal = dayjs(colVal).fromNow();
								tooltipValue = dayjs(colVal).format('DD/MM/YYYY [at] HH:mm:ss');
								colVal = fromNowVal;
							} else {
								colVal = 'Never';
								tooltipValue = 'Run the strategy on the right';
							}
						}

						return (
							<>
								<Tooltip content={tooltipValue}>
									<div className="py-3">{colVal}</div>
								</Tooltip>
							</>
						);
					}}
					className="text-center"
				/>
			</div>
		</>
	);
};

export default StrategyOverview;
