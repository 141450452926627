import { ChangeEvent, FunctionComponent } from 'react';
import { Control, Controller, UseFieldArrayRemove } from 'react-hook-form';
import IconButton from '../../../shared/components/IconButton/IconButton';
import Cross from '../../../shared/components/Icons/Cross';
import Info from '../../../shared/components/Icons/Info';
import InputWithLabel from '../../../shared/components/InputWithLabel/InputWithLabel';
import TextInput from '../../../shared/components/TextInput/TextInput';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';
import {
	PenalizeBrokenSizeChartsSecondaryObjective,
	Configuration,
	FixBrokenSizeChartsSecondaryObjective,
	SecondaryObjective,
	SecondaryObjectiveType,
} from '../../../shared/models/configuration';
import ScopeDescription from './ScopeDescription';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';

type WithIds<Type> = Type & {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
};

type BaseFieldsetProps<C> = C & {
	control: Control<Configuration>;
	remove: UseFieldArrayRemove;
	index: number;
};

interface Props {
	secondaryObjective: SecondaryObjective;
	control: Control<Configuration>;
	remove: UseFieldArrayRemove;
	index: number;
}

const TooltipText: FunctionComponent = () => (
	<p>
		The higher this number, the more effort we will use to achieve this
		objective.
		<br /> <br />
		This weight is relative to the revenue.
		<br /> <br />
		<i>
			(Example: If the weight is 2.0, you are stating that you believe this
			secondary objective is twice as important as your revenue gain and you
			will gladly miss some revenue to achieve this.)
		</i>
	</p>
);

const FixBrokenSizeChartsSecondaryObjectiveFieldset: FunctionComponent<
	WithIds<BaseFieldsetProps<FixBrokenSizeChartsSecondaryObjective>>
> = ({ scope, remove, index, control, weight, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<div className="flex gap-4 justify-between items-center">
			<p className="text-ca-gray">Attempt to fix broken size charts.</p>
			<div className="flex gap-4 justify-between w-36">
				<InputWithLabel
					htmlFor={`secondaryObjectives.${index}.weight`}
					label={
						<span className="flex gap-2">
							Weight{' '}
							<Tooltip content={<TooltipText />}>
								<button>
									<Info className="w-4" />
								</button>
							</Tooltip>
						</span>
					}
					className="flex-row"
				>
					<Controller
						name={`secondaryObjectives.${index}.weight`}
						control={control}
						rules={{ required: 'Required field', min: 0.1 }}
						render={({ field }) => (
							<TextInput
								type="number"
								className="w-24"
								endAdornment={undefined}
								startAdornment={undefined}
								error=""
								id=""
								placeholder={weight}
								size="regular"
								value={field.value}
								min={0.1}
								step={0.1}
								onChange={(event: ChangeEvent<HTMLInputElement>) =>
									field.onChange(parseFloat(event.target.value))
								}
							/>
						)}
					/>
				</InputWithLabel>
				<IconButton
					onClick={() => remove(index)}
					icon={Cross}
					className="w-2.5"
				/>
			</div>
		</div>

		<ScopeDescription {...scope} {...ids} />
	</div>
);

const PenalizeBrokenSizeChartsSecondaryObjectiveFieldset: FunctionComponent<
	WithIds<BaseFieldsetProps<PenalizeBrokenSizeChartsSecondaryObjective>>
> = ({ scope, remove, index, control, weight, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<div className="flex gap-4 justify-between items-center">
			<p className="text-ca-gray">
				Penalize broken size charts in order to promote collecting extreme
				sizes.
			</p>
			<div className="flex gap-4 justify-between w-36">
				<InputWithLabel
					htmlFor={`secondaryObjectives.${index}.weight`}
					label={
						<span className="flex gap-2">
							Weight{' '}
							<Tooltip content={<TooltipText />}>
								<button>
									<Info className="w-4" />
								</button>
							</Tooltip>
						</span>
					}
				>
					<Controller
						name={`secondaryObjectives.${index}.weight`}
						control={control}
						rules={{ required: 'Required field', min: 0.1 }}
						render={({ field }) => (
							<TextInput
								type="number"
								className="w-24"
								endAdornment={undefined}
								startAdornment={undefined}
								error=""
								id=""
								placeholder={weight}
								size="regular"
								value={field.value}
								min={0.1}
								step={0.1}
								onChange={(event: ChangeEvent<HTMLInputElement>) =>
									field.onChange(parseFloat(event.target.value))
								}
							/>
						)}
					/>
				</InputWithLabel>
				<IconButton
					onClick={() => remove(index)}
					icon={Cross}
					className="w-2.5"
				/>
			</div>
		</div>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const SecondaryObjectiveFieldset: FunctionComponent<WithIds<Props>> = ({
	secondaryObjective,
	...rest
}) => {
	const renderSecondaryObjectiveFieldset = () => {
		switch (secondaryObjective.type) {
			case SecondaryObjectiveType.FixBrokenSizeCharts:
				return (
					<FixBrokenSizeChartsSecondaryObjectiveFieldset
						{...secondaryObjective}
						{...rest}
					/>
				);
			case SecondaryObjectiveType.PenalizeBrokenSizeCharts:
				return (
					<PenalizeBrokenSizeChartsSecondaryObjectiveFieldset
						{...secondaryObjective}
						{...rest}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<div className="flex gap-3 items-center">
			{renderSecondaryObjectiveFieldset()}
		</div>
	);
};

export default SecondaryObjectiveFieldset;
