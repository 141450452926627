import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Nav = ({ active, items }) => (
	<div className="w-full flex py-1 border-b border-ca-silver">
		{items.map((item) => (
			<Link key={item.id} to={item.to}>
				<span
					className={clsx(
						'relative py-2 mr-10 hover:text-ca-purple hover:font-bold transition-colors',
						item.id === active ? 'text-ca-purple font-bold' : 'text-ca-gray'
					)}
				>
					{item.label}
					<span
						className={clsx(
							'h-0.5 absolute left-0 bottom-px w-full bg-ca-purple opacity-0',
							item.id === active && 'opacity-100'
						)}
					/>
				</span>
			</Link>
		))}
	</div>
);

Nav.propTypes = {
	active: PropTypes.string,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.string,
			to: PropTypes.string,
		})
	).isRequired,
};

Nav.defaultProps = {
	active: '',
};

export default Nav;
