import { FunctionComponent } from 'react';
import { useQuery } from 'react-query';
import { CumulioDashboardComponent } from '@cumul.io/react-cumulio-dashboard';
import { GET_INVENTORY_ALLOCATION_MOVEMENTS_DASHBOARD } from '../../../shared/api/inventory-allocation-reports';
import { GetReportDashboardResponseDTO } from '../../../shared/models/schema';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';

interface Props {
	reportId: InventoryAllocationReport['id'];
	allocationId: InventoryAllocation['id'];
}

const InventoryAllocationReportMovements: FunctionComponent<Props> = ({
	reportId,
	allocationId,
}) => {
	const { isLoading, data } = useQuery<
		unknown,
		{},
		GetReportDashboardResponseDTO
	>(['movement-dashboard', allocationId, reportId], () =>
		GET_INVENTORY_ALLOCATION_MOVEMENTS_DASHBOARD(allocationId, reportId)
	);

	if (isLoading) {
		return <p>Loading...</p>;
	}

	return (
		<div id="cumulio-container">
			<CumulioDashboardComponent
				dashboardId={data?.dashboard_id}
				authKey={data?.id}
				authToken={data?.token}
				loaderBackground="#FAFAFB"
				loaderFontColor="transparent"
				loaderSpinnerColor="#6111C7"
				loaderSpinnerBackground="transparent"
				loaderLogoColor="transparent"
			/>
		</div>
	);
};

export default InventoryAllocationReportMovements;
