import PropTypes from 'prop-types';
import clsx from 'clsx';

const Text = ({ type, size, className, children, style }) => (
	<p
		style={style}
		className={clsx(
			size,
			type === 'primary' && 'text-ca-black',
			type === 'secondary' && 'text-ca-gray',
			type === 'inverted' && 'text-white',
			className
		)}
	>
		{children}
	</p>
);

Text.propTypes = {
	size: PropTypes.oneOf([
		'text-xxs',
		'text-xs',
		'text-sm',
		'text-base',
		'text-lg',
		'text-xl',
		'text-2xl',
		'text-3xl',
		'text-4xl',
		'text-5xl',
		'text-6xl',
		'text-ca-screen',
		'text-ca-section',
	]),
	type: PropTypes.oneOf(['primary', 'secondary', 'inverted']),
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	style: PropTypes.object,
};

Text.defaultProps = {
	size: 'text-sm',
	type: 'primary',
	className: '',
	style: {},
};

export default Text;
