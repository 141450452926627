import api from './api';

export const GET_CATEGORIES_FILTERS = () => {
	return api.get('api/v2/categories/filters').json();
};

export const GET_CATEGORIES = () => {
	return api.get('api/v2/categories').json();
};

export const GET_CATEGORY = (id) => {
	return api.get(`api/v2/categories/${id}`).json();
};

export const CREATE_CATEGORY = (payload) => {
	return api
		.post('api/v2/categories', {
			json: payload,
		})
		.json();
};

export const UPDATE_CATEGORY = (id, payload) => {
	return api
		.put(`api/v2/categories/${id}`, {
			json: payload,
		})
		.json();
};

export const DELETE_CATEGORY = (id) => {
	return api.delete(`api/v2/categories/${id}`).json();
};

export const UPDATE_CATEGORIES_PRIORITIES = (payload) => {
	return api
		.post(`api/v2/categories/priorities`, {
			json: payload,
		})
		.json();
};

export const GET_CATEGORIES_SCHEMA = () => {
	return api.get('api/v2/categories/schema').json();
};

export const GET_CATEGORIES_PRODUCTS = (searchParams) => {
	return api
		.get('api/v2/categories/products', {
			searchParams,
		})
		.json();
};
