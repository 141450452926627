import { Route, Redirect } from 'react-router-dom';

import RouterSwitch from '../../shared/components/RouterSwitch/RouterSwitch';
import Page from '../../shared/components/Page/Page';
import ActualsHeader from '../components/ActualsHeader';
import ActualsInventory from './ActualsInventory';
import ActualsSales from './ActualsSales';
import ActualsYoYComparison from './ActualsYoYComparison';
import ActualsProduct from './ActualsProduct';

const ActualsView = () => (
	<Page>
		<ActualsHeader />
		<RouterSwitch>
			<Route exact path="/actuals">
				<Redirect to="/actuals/inventory" />
			</Route>
			<Route exact path="/actuals/inventory" component={ActualsInventory} />
			<Route exact path="/actuals/sales" component={ActualsSales} />
			<Route
				exact
				path="/actuals/yoycomparison"
				component={ActualsYoYComparison}
			/>
			<Route exact path="/actuals/product" component={ActualsProduct} />
		</RouterSwitch>
	</Page>
);

export default ActualsView;
