import { FunctionComponent, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Nav from '../../../shared/components/Nav/Nav';

export interface TabLink {
	id: string;
	label: string;
	to: string;
}

interface Props {
	tabs: TabLink[];
}

const Tabs: FunctionComponent<Props> = ({ tabs }) => {
	const { pathname } = useLocation();
	const match = useMemo(
		() =>
			[...tabs]
				// we sort the nav items from most specific to least specific
				// otherwise every route would match the overview `/strategy`
				.sort((a, b) => b.to.split('/').length - a.to.split('/').length)
				.find((item) => pathname.startsWith(item.to)),

		[pathname]
	);

	return <Nav active={match?.id} items={tabs} />;
};

export default Tabs;
