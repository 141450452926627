import PropTypes from 'prop-types';
import Dropdown from '../Dropdown/Dropdown';

const MarkdownTransferDropdown = ({
	value,
	onChange,
	buckets,
	unselectableBuckets,
	className,
	unit,
}) => {
	const totalInBuckets = buckets?.reduce(
		(acc, bucket) => acc + bucket.count,
		0
	);

	const options = buckets
		.map((bucket) => {
			return {
				value: bucket.markdown,
				label: `${bucket.markdown}% (${bucket.count} ${unit} ${
					totalInBuckets > 0
						? `| ${Math.round(
								(bucket.count / totalInBuckets) * 100
						  )} % of ${unit})`
						: ')'
				}`,
				disabled: unselectableBuckets.some(
					(hay) => hay.markdown === bucket.markdown
				),
			};
		})
		.sort((a, b) => a.value - b.value);

	return (
		<Dropdown
			options={options}
			className={className ?? 'w-full'}
			value={value}
			onChange={onChange}
		/>
	);
};

MarkdownTransferDropdown.propTypes = {
	value: PropTypes.number,
	buckets: PropTypes.array,
	unselectableBuckets: PropTypes.array,
	unit: PropTypes.string,
	onChange: PropTypes.func,
	className: PropTypes.string,
};

MarkdownTransferDropdown.defaultProps = {
	onChange: () => {},
	buckets: [],
	unselectableBuckets: [],
	className: '',
};

export default MarkdownTransferDropdown;
