import { Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { GET_STRATEGY } from '../../../shared/api/strategies';

import useChannelQuery from '../../../shared/hooks/useChannelQuery';
import get from '../../../shared/utils/get';
import pluralise from '../../../shared/utils/pluralise';

import Button from '../../../shared/components/Button/Button';
import IconButton from '../../../shared/components/IconButton/IconButton';
import CheckmarkIcon from '../../../shared/components/Icons/Checkmark';
import CrossIcon from '../../../shared/components/Icons/Cross';
import EditIcon from '../../../shared/components/Icons/Edit';
import InfoIcon from '../../../shared/components/Icons/Info';
import LinearProgress from '../../../shared/components/Progress/LinearProgress';
import Table from '../../../shared/components/Table/Table';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';
import Title from '../../../shared/components/Title/Title';
import AlgorithmRunButton from '../../../shared/components/AlgorithmRunButton/AlgorithmRunButton';
import BusinessRuleCheckerButton from '../../../shared/components/BusinessRuleCheckerButton/BusinessRuleCheckerButton';
import LoadingSpinner from '../../../shared/components/LoadingSpinner/LoadingSpinner';

const HEADINGS = [
	{ id: 'category.name', label: 'Category' },
	{ id: 'category.category_products_count', label: 'Products' },
	{ id: 'objective_residual_value', label: 'Optimization objective' },
	{ id: 'business_rules', label: 'Business rule(s)' },
	{ id: 'end_period', label: 'End of markdown period' },
	{ id: 'actions', label: '', align: 'right' },
];

const StrategyComponentsOverview = () => {
	const history = useHistory();
	const { strategyId } = useParams();

	const { isLoading, isFetching, data } = useChannelQuery(
		['strategy', strategyId],
		() => GET_STRATEGY(strategyId)
	);

	return (
		<>
			<div className="fixed left-32 right-0 top-0">
				<LinearProgress visible={isFetching} />
			</div>
			<div className="mt-6 flex justify-between">
				<Title type="section">{data?.name}</Title>
			</div>
			<div className="mt-6 flex justify-between">
				<Button onClick={() => history.push('/strategy')}>
					&#8592; Go back
				</Button>
			</div>
			<div className="py-6 space-y-6">
				<Table
					loading={isLoading}
					itemsLoading={5}
					headings={HEADINGS}
					rows={data?.components}
					renderCell={(row, columnId) => {
						if (columnId === 'actions') {
							return (
								<>
									<IconButton
										tooltip="Edit"
										icon={EditIcon}
										onClick={() =>
											history.push(
												`/strategy/strategies/${data?.slug}/components/${row?.id}/edit`
											)
										}
									/>
								</>
							);
						}

						if (columnId === 'objective_residual_value') {
							return (
								<span className="flex flex-col space-y-2">
									<span className="flex items-center">
										<span className="flex items-center space-x-1">
											Strategy: {row?.objective_scenario_name ?? 'default'}
											<LoadingSpinner
												variant="sm"
												hidden={!isFetching}
												antiVisualJumping
											/>
										</span>
									</span>
									<span className="flex items-center">
										Shipping cost{' '}
										{row?.objective_include_shipping_cost ? (
											<CheckmarkIcon className="inline ml-1 h-2 text-ca-green" />
										) : (
											<CrossIcon className="inline ml-1 h-2 text-ca-red" />
										)}
									</span>
									<span className="flex items-center">
										Return cost{' '}
										{row?.objective_include_return_cost ? (
											<CheckmarkIcon className="inline ml-1 h-2 text-ca-green" />
										) : (
											<CrossIcon className="inline ml-1 h-2 text-ca-red" />
										)}
									</span>
								</span>
							);
						}

						if (columnId === 'business_rules') {
							if (row?.[columnId]?.length > 2) {
								return (
									<span className="flex items-center">
										{row?.[columnId]?.[0]?.title}, {row?.[columnId]?.[1]?.title}
										&nbsp;+&nbsp;
										<Tooltip
											content={
												<>
													{row?.[columnId]?.slice(2).map((br) => (
														<Fragment key={br?.id}>
															{br?.title}
															<br />
														</Fragment>
													))}
												</>
											}
										>
											<span className="flex items-center space-x-1">
												<span>
													{pluralise(
														row?.[columnId]?.length - 2,
														'rule',
														'rules'
													)}
												</span>
												<InfoIcon className="inline-block text-ca-gray h-3.5" />
											</span>
										</Tooltip>
									</span>
								);
							}

							return row?.[columnId]?.length
								? row?.[columnId]?.map(({ title }) => title).join(', ')
								: 'None';
						}

						return get(row, columnId);
					}}
				/>
			</div>
			<div className="flex justify-end gap-4">
				{data && <AlgorithmRunButton strategyId={strategyId} />}
				{data && window._ENV_.REACT_APP_BUSINESS_RULE_CHECKER_ENABLED && (
					<BusinessRuleCheckerButton strategyId={strategyId} />
				)}
			</div>
		</>
	);
};

export default StrategyComponentsOverview;
