import { GET_CUMULIO_ACTUALS_SALES_SSO } from '../../shared/api/reports';
import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';

const ActualsSales = () => {
	return (
		<FullCumulioDashboard
			queryKey="cumulio-actuals-sales"
			ssoQuery={GET_CUMULIO_ACTUALS_SALES_SSO}
		/>
	);
};

export default ActualsSales;
