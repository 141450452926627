import { GET_CUMULIO_ACTUALS_PRODUCT_SSO } from '../../shared/api/reports';
import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';

const ActualsProduct = () => {
	return (
		<FullCumulioDashboard
			queryKey="cumulio-actuals-product"
			ssoQuery={GET_CUMULIO_ACTUALS_PRODUCT_SSO}
		/>
	);
};

export default ActualsProduct;
