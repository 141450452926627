import { createContext, useState, useCallback, Fragment } from 'react';
import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useKeyPress from '../hooks/useKeyPress';

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
	const [modal, setModal] = useState();
	const [sizeClass, setSizeClass] = useState('');

	const open = (m, size = '') => {
		setModal(m);
		setSizeClass(size);
		document.body.style.overflow = 'hidden';
	};

	const close = useCallback(() => {
		setModal();
		setSizeClass('');
		document.body.style = '';
	}, [setModal]);

	useKeyPress('Escape', close);

	return (
		<ModalContext.Provider value={{ open, close }}>
			{children}
			<Transition appear show={!!modal} as={Fragment}>
				<div className="z-50 fixed left-0 right-0 bottom-0 top-0">
					<div
						role="presentation"
						className="absolute left-0 right-0 bottom-0 top-0 backdrop-filter backdrop-blur-sm"
						style={{ background: 'rgba(230, 230, 230, 0.5)' }}
						onClick={close}
					/>
					<div
						className={clsx(
							'absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2',
							sizeClass
						)}
					>
						<Transition.Child
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<div>{modal}</div>
						</Transition.Child>
					</div>
				</div>
			</Transition>
		</ModalContext.Provider>
	);
};

ModalProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export { ModalContext, ModalProvider };
