import {
	FunctionComponent,
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from 'react';
import { useQuery } from 'react-query';
import {
	GET_INVENTORY_ALLOCATION_PRODUCTS,
	GET_INVENTORY_ALLOCATION_PRODUCT_IDS,
	GET_INVENTORY_ALLOCATION_PRODUCT_SCHEMA,
} from '../../../shared/api/inventory-allocation-products';
import Table from '../../../shared/components/Table/Table';
import {
	FilterDTO,
	GetProductIdsResponseDTO,
	GetProductsParametersDTO,
	GetProductsResponseDTO,
	GetProductsSchemaParametersDTO,
	GetProductsSchemaResponseDTO,
	ProductDTO,
	ProductFieldTypeDTO,
} from '../../../shared/models/schema';
import ProductsFilter from './ProductsFilter';
import { useDebouncedQuery } from '../../../shared/hooks/useDebouncedQuery';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';

interface Props {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
	selectedProducts: ProductDTO['product_id'][];
	defaultFilters?: any[];
	isReadOnly?: boolean;
	setSelectedProducts?: (products: ProductDTO['product_id'][]) => void;
	setShouldAskForConfirmation?: (shouldAskForConfirmation: boolean) => void;
}

interface Pagination {
	page: number;
	size: number;
}

const DEFAULT_PAGINATION: Pagination = {
	size: 50,
	page: 1,
};

const ProductsTable: FunctionComponent<Props> = ({
	selectedProducts = [],
	isReadOnly = false,
	setSelectedProducts,
	defaultFilters = [],
	setShouldAskForConfirmation,
	inventoryAllocationId,
	reportId,
}) => {
	const [internalSelectedProducts, setInternalSelectedProducts] =
		useState<ProductDTO['product_id'][]>(selectedProducts);
	const defaultFiltersMap = new Map();
	(defaultFilters ?? []).forEach(({ filter_id, values }: any) => {
		defaultFiltersMap.set(filter_id, values);
	});
	const [filters, setFilters] =
		useState<Map<string, FilterDTO>>(defaultFiltersMap);
	const [pagination, setPagination] = useState<Pagination>(DEFAULT_PAGINATION);
	const [isShowingOnlySelected, toggleShowSelected] = useReducer((value) => {
		setPagination({ ...pagination, page: 1 });
		return !value;
	}, isReadOnly);
	const { isLoading: isSchemaLoading, data: schema } = useQuery<
		GetProductsSchemaParametersDTO,
		unknown,
		GetProductsSchemaResponseDTO
	>(['product-schema'], GET_INVENTORY_ALLOCATION_PRODUCT_SCHEMA);

	const {
		isLoading: isProductsLoading,
		isFetching: isProductsFetching,
		data,
	} = useDebouncedQuery<
		GetProductsParametersDTO,
		unknown,
		GetProductsResponseDTO
	>(
		[
			'products',
			pagination,
			Object.fromEntries(filters),
			isShowingOnlySelected ? internalSelectedProducts : [],
		],
		() =>
			GET_INVENTORY_ALLOCATION_PRODUCTS(
				new URLSearchParams([
					['allocation_id', inventoryAllocationId],
					['report_id', reportId],
					['sort', 'product_id:asc'],
					['page', pagination.page.toString()],
					['size', pagination.size.toString()],
					...(isShowingOnlySelected
						? [
								[
									'filter',
									`"id": "product_id","value": ${JSON.stringify(
										internalSelectedProducts
									)}`,
								],
						  ]
						: []),
					...Array.from(filters.entries()).map(([id, value]) => [
						'filter',
						`"id":"${id}","value":${JSON.stringify(value)}`,
					]),
				])
			),
		{
			staleTime: 5 * 60 * 1000,
			keepPreviousData: true,
			enabled: true,
			debounceDelay: 1000,
		}
	);

	const { isLoading: isFilteredIdsLoading, data: filteredIds } = useQuery<
		{},
		unknown,
		GetProductIdsResponseDTO
	>(
		[
			'product-ids',
			Object.fromEntries(filters),
			isShowingOnlySelected ? internalSelectedProducts : [],
		],
		() =>
			GET_INVENTORY_ALLOCATION_PRODUCT_IDS(
				new URLSearchParams([
					['allocation_id', inventoryAllocationId],
					['report_id', reportId],
					...(isShowingOnlySelected
						? [
								[
									'filter',
									`"id": "product_id","value": ${JSON.stringify(
										internalSelectedProducts
									)}`,
								],
						  ]
						: []),
					...Array.from(filters.entries()).map(([id, value]) => [
						'filter',
						`"id":"${id}","value":${JSON.stringify(value)}`,
					]),
				])
			)
	);

	const headings = useMemo(
		() =>
			schema?.map((s) => ({
				id: s.id,
				label: s.name,
				tooltip: s.tooltip,
				sortable: s.sortable,
			})),
		[schema]
	);

	const isLoading =
		isSchemaLoading ||
		isProductsLoading ||
		isFilteredIdsLoading ||
		isProductsFetching;

	const onSelect = useCallback(
		(id: string) => {
			const isNotId = (selectedId: string): boolean => selectedId !== id;

			if (internalSelectedProducts.includes(id)) {
				setSelectedProducts?.([...internalSelectedProducts.filter(isNotId)]);
				setInternalSelectedProducts?.([
					...internalSelectedProducts.filter(isNotId),
				]);
			} else {
				setSelectedProducts?.([...internalSelectedProducts, id]);
				setInternalSelectedProducts?.([...internalSelectedProducts, id]);
			}
		},
		[setSelectedProducts, setInternalSelectedProducts, internalSelectedProducts]
	);

	const onDeselectAll = useCallback(() => {
		setSelectedProducts?.([]);
		setInternalSelectedProducts?.([]);
	}, [setSelectedProducts, setInternalSelectedProducts]);

	const onSelectAll = useCallback(() => {
		const areAllIdsAlreadySelected: boolean = (filteredIds || []).every((id) =>
			internalSelectedProducts.includes(id)
		);

		if (areAllIdsAlreadySelected) {
			setSelectedProducts?.([
				...internalSelectedProducts.filter(
					(id) => !(filteredIds || []).includes(id)
				),
			]);
			setInternalSelectedProducts?.([
				...internalSelectedProducts.filter(
					(id) => !(filteredIds || []).includes(id)
				),
			]);
		} else {
			setSelectedProducts?.([
				...new Set([...internalSelectedProducts, ...(filteredIds || [])]),
			]);
			setInternalSelectedProducts?.([
				...new Set([...internalSelectedProducts, ...(filteredIds || [])]),
			]);
		}
	}, [
		filteredIds,
		internalSelectedProducts,
		setSelectedProducts,
		setInternalSelectedProducts,
	]);

	const onFiltersChange = (newFilters: any) => {
		setFilters(newFilters);
		setPagination((p) => ({ ...p, page: 1 }));
		onDeselectAll();
	};

	useEffect(() => {
		setShouldAskForConfirmation?.(internalSelectedProducts.length === 0);
	}, [internalSelectedProducts]);

	const renderCell = useCallback(
		(row: ProductDTO, columnId: keyof ProductDTO) => {
			const fieldType = schema?.find(({ id }) => id === columnId)?.field_type;

			switch (fieldType) {
				case ProductFieldTypeDTO.Normal:
				case ProductFieldTypeDTO.CurrentStock:
				case ProductFieldTypeDTO.Id:
				case ProductFieldTypeDTO.OptimalStock:
					return row?.[columnId];
				case ProductFieldTypeDTO.Image:
					return row?.[columnId] ? (
						<img
							className="inline-block w-16"
							src={row?.[columnId] as string}
							alt={row?.product_id}
						/>
					) : null;
				default:
					return row?.[columnId];
			}
		},
		[data]
	);

	return (
		<>
			<div className="flex flex-row justify-between mb-4">
				<ProductsFilter
					filters={filters}
					onChange={onFiltersChange}
					isReadOnly={isReadOnly}
				/>
				<span />
				{isReadOnly ? (
					<div className="flex items-center gap-8">
						<span className="text-ca-black">
							<strong>{internalSelectedProducts.length}</strong> Products in
							scope
						</span>
					</div>
				) : (
					<div className="flex items-center gap-8">
						<button onClick={toggleShowSelected} className="text-ca-purple">
							<strong>{internalSelectedProducts.length}</strong> Products in
							scope
						</button>
						<button onClick={onDeselectAll} className="text-ca-purple">
							Deselect all
						</button>
					</div>
				)}
			</div>
			<Table
				className="block overflow-x-scroll max-h-[32rem]"
				loading={isLoading}
				itemsLoading={10}
				rowKey="product_id"
				headings={headings}
				rows={data?.items || []}
				emptyState="No products in scope."
				disabled={!setSelectedProducts ? filteredIds : []}
				selectable={!isReadOnly}
				selected={internalSelectedProducts}
				onSelectedChange={(ids: string[]) => {
					const longestArray =
						ids.length > internalSelectedProducts.length
							? ids
							: internalSelectedProducts;
					const shortestArray =
						ids.length < internalSelectedProducts.length
							? ids
							: internalSelectedProducts;
					const difference = longestArray.filter(
						(id) => !shortestArray.includes(id)
					);

					if (difference.length > 1) {
						onSelectAll();
					} else {
						onSelect(difference[0]);
					}
				}}
				pagination={{
					currentPage: pagination.page,
					itemsPerPage: pagination.size,
					items: data?.total ?? 0,
				}}
				onPageChange={(page) => {
					setPagination({ ...pagination, page });
				}}
				renderCell={renderCell}
			/>
		</>
	);
};

export default ProductsTable;
