import React, { FunctionComponent, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import useModal from '../../shared/hooks/useModal';
import Modal from '../../shared/components/Modal/Modal';
import Button from '../../shared/components/Button/Button';
import Text from '../../shared/components/Text/Text';
import TextInput from '../../shared/components/TextInput/TextInput';
import Title from '../../shared/components/Title/Title';
import MarkdownTransferCard from '../../shared/components/MarkdownTransferDropdown/MarkdownTransferCard';
import MarkdownTransferDropdown from '../../shared/components/MarkdownTransferDropdown/MarkdownTransferDropdown';
import MarkdownTransferRadios from '../../shared/components/MarkdownTransferDropdown/MarkdownTransferRadios';
import MarkdownTransferSelection from '../../shared/components/MarkdownTransferDropdown/MarkdownTransferSelection';
import MarkdownTransferActiveFilters from './MarkdownTransferActiveFilters';
import MarkdownTransferBrace from './MarkdownTransferBrace';
import MarkdownTransferPhrase from './MarkdownTransferPhrase';
import useStrategyStore from '../../shared/data/strategies';
import { FullCumulioDashboardHandle } from '../../shared/components/FullCumulioDashboard/FullCumulioDashboardTypes';
import { EUnit, EElementState } from '../types/MarkdownTransferTypes';
import {
	GET_BUCKETS,
	GET_NUMBER_TRANSFERS_BUSINESS_RULES,
} from '../../shared/api/opportunities';
import Tooltip from '../../shared/components/Tooltip/Tooltip';
import Info from '../../shared/components/Icons/Info';
import LoadingSpinner from '../../shared/components/LoadingSpinner/LoadingSpinner';
import range from '../../shared/utils/range';

const CUMULIO_FILTERS: { cumulioChartId: string; bigQueryFilterId: string }[] =
	[
		{
			cumulioChartId: 'd515cb22-5eaa-4265-9f4f-39fce1be167c',
			bigQueryFilterId: 'Season',
		},
		{
			cumulioChartId: '318c9751-2fcd-4aa5-a54e-970481311856',
			bigQueryFilterId: 'Target',
		},
		{
			cumulioChartId: '12d13eb3-c7cb-4a2c-b5f3-ba82501f4d96',
			bigQueryFilterId: 'Collection',
		},
		{
			cumulioChartId: '580adceb-5eaf-4d84-a51b-5f23319c7edc',
			bigQueryFilterId: 'Brand',
		},
		{
			cumulioChartId: 'a32ca20c-6b2d-4066-8d20-65067cd92bc5',
			bigQueryFilterId: 'Category_1',
		},
		{
			cumulioChartId: '8d3f1faf-3756-4e6e-b59a-541853a355c0',
			bigQueryFilterId: 'Category_2',
		},
		{
			cumulioChartId: '7852ff06-0255-4862-8eae-1bd5a1020478',
			bigQueryFilterId: 'Category_3',
		},
		{
			cumulioChartId: '285ebe45-2c15-4999-af9f-ccae2f4c5a77',
			bigQueryFilterId: 'Category_4',
		},
		{
			cumulioChartId: 'e48b84b3-d921-4839-a272-af979be38526',
			bigQueryFilterId: 'Category_5',
		},
	];

interface Props {
	cumulioRef: React.RefObject<FullCumulioDashboardHandle>; // don't know which type this should be...
	transferMarkdownFn: (variables: {
		strategySlug: string;
		filterSearchParams: URLSearchParams;
		from_bucket: number;
		to_bucket: number;
		amount: number;
		units: string;
	}) => void;
}

/** bucket data received from backend */
type RawBucket = { pid_count: number; markdown: string; stock_sum: number };
/** bucket type used within frontend */
type Bucket = {
	/** Between 0 and 100. (Example: 50 represents 50%) */
	markdown: number;
	/** Amount of units in bucket */
	count: number;
};

type Filter = {
	key: string;
	value: string[];
};

interface CumulioFilter {
	expression: string;
	parameters: [any, string[]];
	properties: { itemId: string; origin: string; [key: string]: any };
}

enum EMarkdownTransferFormState {
	promptUnit = 0,
	promptFromBucket = 1,
	promptAmount = 2,
	promptToBucket = 3,
	promptApply = 4,
}

interface StateSettings {
	state: EMarkdownTransferFormState;
	fromCardState: EElementState;
	amountCardState: EElementState;
	toCardState: EElementState;
	phraseState: EElementState;
	applyState: EElementState;
}

const STATES_SETTINGS: StateSettings[] = [
	{
		state: EMarkdownTransferFormState.promptUnit,
		fromCardState: EElementState.disabled,
		toCardState: EElementState.disabled,
		amountCardState: EElementState.disabled,
		phraseState: EElementState.disabled,
		applyState: EElementState.disabled,
	},
	{
		state: EMarkdownTransferFormState.promptFromBucket,
		fromCardState: EElementState.focused,
		toCardState: EElementState.disabled,
		amountCardState: EElementState.disabled,
		phraseState: EElementState.default,
		applyState: EElementState.disabled,
	},
	{
		state: EMarkdownTransferFormState.promptToBucket,
		fromCardState: EElementState.default,
		toCardState: EElementState.focused,
		amountCardState: EElementState.disabled,
		phraseState: EElementState.default,
		applyState: EElementState.disabled,
	},
	{
		state: EMarkdownTransferFormState.promptAmount,
		fromCardState: EElementState.default,
		toCardState: EElementState.default,
		amountCardState: EElementState.focused,
		phraseState: EElementState.default,
		applyState: EElementState.disabled,
	},
	{
		state: EMarkdownTransferFormState.promptApply,
		fromCardState: EElementState.background,
		amountCardState: EElementState.background,
		toCardState: EElementState.background,
		phraseState: EElementState.focused,
		applyState: EElementState.default,
	},
];

enum EEvent {
	onChangeUnit = 'onChangeUnit',
	onChangeFrom = 'onChangeFrom',
	onChangeAmount = 'onChangeAmount',
	onChangeTo = 'onChangeTo',
	onClickFrom = 'onClickFrom',
	onClickAmount = 'onClickAmount',
	onClickTo = 'onClickTo',
	onBlurAmount = 'onBlurAmount',
	onConfirmAmount = 'onConfirmAmount',
}

const getSettings = (state: EMarkdownTransferFormState): StateSettings => {
	return (
		STATES_SETTINGS.find((setting) => setting.state === state) ??
		STATES_SETTINGS[0]
	);
};

const validateInteger = (
	toValidate: string
): { ok: boolean; validValue: number } => {
	if (toValidate === null) return { ok: false, validValue: NaN };

	const regex = /^[0-9]+$/;
	const parsedInt = parseInt(toValidate, 10);
	const result = regex.test(toValidate) && !Number.isNaN(parsedInt);
	return { ok: result, validValue: result ? parsedInt : NaN };
};

const validateIntegerMinMax = (
	toValidate: string,
	min: number,
	max: number
) => {
	const internalMax = Math.max(min, max);
	const internalMin = Math.min(min, max);
	const result = validateInteger(toValidate);
	if (result.ok) {
		if (result.validValue > internalMax) {
			result.validValue = internalMax;
		} else if (result.validValue < internalMin) {
			result.validValue = internalMin;
		}
	}
	return result;
};

const getMaxAllowedAmount = (
	buckets: Bucket[],
	from: Bucket['markdown']
): number => {
	return buckets?.find((bucket) => bucket.markdown === from)?.count ?? Infinity;
};

/* 
	Examples:
	'>20%' 	-> 	20
	'0%' 	-> 	0
 */
const extractPercentage = (from: string): string => {
	const pattern = /(>)*\d{1,2}%/g;
	const result = from.match(pattern);
	if (result?.length && result.length > 0) {
		return result[0];
	}
	return '';
};

const toInputValue = (internalValue: Bucket['markdown'] | null): string =>
	internalValue !== null ? `${internalValue}%` : '...';
const toInternalValue = (inputValue: string): Bucket['markdown'] =>
	parseInt(extractPercentage(inputValue), 10);
const toDecimalValue = (markdown: Bucket['markdown'] | null): number =>
	markdown !== null ? Number((markdown / 100).toFixed(2)) : NaN;
/**
 * Example:
 * - input: [ { key: "Category_1", value:[ "Coats" ] } ]
 * - output: [ [ 'filter': '"id":"Category_1","value":[ "Coats" ]' } ] ]
 */
const filtersToSearchParams = (
	filters: Filter[] | undefined
): URLSearchParams => {
	return new URLSearchParams(
		(filters ?? []).map(({ key, value }) => [
			'filter',
			`"id":"${key}","value":${JSON.stringify(value)}`,
		])
	);
};

const checkState = (
	state: EMarkdownTransferFormState,
	unit: EUnit | null,
	setState: Function
) => {
	if (
		state === EMarkdownTransferFormState.promptUnit &&
		(unit === EUnit.Products || unit === EUnit.Stock)
	) {
		setState(EMarkdownTransferFormState.promptFromBucket);
	}
};

const MarkdownTransferModal: FunctionComponent<Props> = (props) => {
	const { close } = useModal();
	const { activeStrategy } = useStrategyStore();
	const [unit, setUnit] = useState<EUnit | null>(EUnit.Products);
	const [from, setFrom] = useState<number | null>(null);
	const [to, setTo] = useState<number | null>(null);
	const [amountDirty, setAmountDirty] = useState<string | null>('');
	const [amount, setAmountInternal] = useState<number | null>(null);
	const [amountError] = useState<string>(''); // no really used right now
	const setAmount = (newAmount: number | null) => {
		setAmountInternal(newAmount);
		setAmountDirty(typeof newAmount === 'number' ? String(newAmount) : '');
	};
	const validateDirtyAmount = (max: number): boolean => {
		if (amountDirty === null) {
			return false;
		}
		const validationResult = validateIntegerMinMax(
			amountDirty,
			Math.min(max, 1),
			max
		);
		if (validationResult.ok) {
			setAmount(validationResult.validValue);
			setAmountDirty(validationResult.validValue.toString());
			return true;
		}
		setAmountDirty((amount ?? '').toString());
		return false;
	};

	const [state, setStateInternal] = useState<EMarkdownTransferFormState>(
		EMarkdownTransferFormState.promptUnit
	);

	const queryClient = useQueryClient();
	const filtersQuery = useQuery({
		queryKey: ['activeFilters'],
		queryFn: async () => {
			if (!props.cumulioRef) {
				throw new Error(
					'No cumulioRef passed to <MarkdownTransferModal>. Please pass a cumulioRef.'
				);
			}
			if (!props.cumulioRef.current) {
				throw new Error('CumulioRef is not referencing anything (yet).');
			}

			return props.cumulioRef.current.getFilters();
		},
		select: (cumulioFilters: CumulioFilter[]) => {
			const data: Filter[] = [];
			(cumulioFilters ?? []).forEach((cumulioFilter) => {
				const key = CUMULIO_FILTERS.find(
					(filterData) =>
						filterData.cumulioChartId === cumulioFilter.properties.itemId
				)?.bigQueryFilterId;
				if (key === undefined) {
					return;
				}
				const value = cumulioFilter.parameters[1];

				data.push({ key, value });
			});
			return data;
		},
		cacheTime: 0,
		retry: 10,
	});

	const bucketsQuery = useQuery({
		queryKey: [
			'buckets',
			unit as string,
			...(filtersQuery.data ?? []).map((filter: Filter) => filter.value),
		],
		queryFn: () =>
			GET_BUCKETS(activeStrategy, filtersToSearchParams(filtersQuery.data)),
		select: (data) => {
			if (Array.isArray(data)) {
				const receivedBuckets = data?.map(
					(rawBucket: RawBucket): Bucket => ({
						markdown: toInternalValue(rawBucket.markdown),
						count: rawBucket.pid_count,
					})
				);

				// Missing buckets (which have 0 pid_count) are added in
				const allBuckets = receivedBuckets;
				range(0, 100, 10).forEach((i) => {
					const bucket = receivedBuckets.find(
						(receivedBucket) => receivedBucket.markdown === i
					);
					if (!bucket) {
						allBuckets.push({ markdown: i, count: 0 });
					}
				});
				return allBuckets.sort((a, b) => a.markdown - b.markdown);
			}
			return [] as Bucket[];
		},
		enabled: !!unit,
		staleTime: 120 * 1000,
		retry: 10,
	});

	const numberTransfersQuery = useQuery({
		queryKey: [
			'amount',
			unit as string,
			from,
			to,
			...(filtersQuery.data ?? []).map((filter) => filter.value),
		],
		queryFn: () => {
			return GET_NUMBER_TRANSFERS_BUSINESS_RULES({
				strategySlug: activeStrategy,
				from: toDecimalValue(from),
				to: toDecimalValue(to),
				filters: filtersToSearchParams(filtersQuery.data),
				unit,
			});
		},
		onSuccess: (data: number) => {
			validateDirtyAmount(data);
		},
		enabled: from !== null && to !== null,
		staleTime: 120 * 1000,
	});

	const stateSettings = useMemo(() => getSettings(state), [state]);
	const confirmAmountButtonRef = useRef<HTMLButtonElement>(null);

	const setState = (nextState: EMarkdownTransferFormState) => {
		if (nextState === EMarkdownTransferFormState.promptFromBucket) {
			setFrom(null);
			setAmount(null);
			setTo(null);
		}

		setStateInternal(nextState);
	};

	const onEventHappens = (type: EEvent, value: any = null) => {
		if (type === EEvent.onChangeUnit) {
			setUnit(value as EUnit); // what if value is not a valid EUnit?
			setState(EMarkdownTransferFormState.promptFromBucket);
		}

		if (type === EEvent.onChangeFrom && value !== null) {
			if (state === EMarkdownTransferFormState.promptFromBucket) {
				setState(EMarkdownTransferFormState.promptToBucket);
			} else {
				const maxAllowed = getMaxAllowedAmount(bucketsQuery.data ?? [], value);
				if (amount !== null && amount > maxAllowed) {
					setAmount(maxAllowed);
				}
			}

			setFrom(value);
		}

		if (type === EEvent.onChangeTo && value !== null) {
			setTo(value);
			setState(EMarkdownTransferFormState.promptAmount);
		}

		if (type === EEvent.onBlurAmount) {
			if (!numberTransfersQuery.isSuccess) {
				return;
			}

			validateDirtyAmount(numberTransfersQuery.data as number);
		}

		if (type === EEvent.onConfirmAmount) {
			if (
				!numberTransfersQuery.isFetching &&
				numberTransfersQuery.isSuccess &&
				numberTransfersQuery.data
			) {
				if (
					validateDirtyAmount(numberTransfersQuery.data as number) &&
					state === EMarkdownTransferFormState.promptAmount
				) {
					setState(EMarkdownTransferFormState.promptApply);
				}
			}
		}

		if (state === EMarkdownTransferFormState.promptApply) {
			if (
				type === EEvent.onClickFrom ||
				type === EEvent.onClickAmount ||
				type === EEvent.onClickTo
			) {
				setState(EMarkdownTransferFormState.promptAmount);
			}
		}
	};

	const handleTransferClicked = () => {
		if (
			[from, to, amount].some((item) => item === null || Number.isNaN(item))
		) {
			return;
		}

		queryClient.invalidateQueries({ queryKey: ['buckets'] });
		queryClient.invalidateQueries({ queryKey: ['amount'] });
		props.transferMarkdownFn({
			strategySlug: activeStrategy,
			filterSearchParams: filtersToSearchParams(filtersQuery.data),
			from_bucket: toDecimalValue(from),
			to_bucket: toDecimalValue(to),
			units: unit ?? EUnit.Products,
			amount: amount ?? 1,
		});
	};

	checkState(state, unit, setState);
	const isLoading = bucketsQuery.isFetching || filtersQuery.isFetching;

	// todo: fix refetch stale. If stale, the query shouldn't show the old data...
	return (
		<article className="w-screen max-w-[1080px] bg-white rounded-2xl shadow-ca ">
			<Modal.Content>
				<header className="border-b pb-4 mb-8 text-ca-purple">
					<Title>Change markdown distribution</Title>
					<Text className="font-normal" type="secondary">
						Easily change the markdown distribution of your products
					</Text>
				</header>
				<MarkdownTransferSelection>
					<MarkdownTransferRadios
						value={unit}
						options={[EUnit.Products]}
						comingSoonOptions={[EUnit.Stock]}
						onChange={(value: string) =>
							onEventHappens(EEvent.onChangeUnit, value)
						}
					/>
					<MarkdownTransferActiveFilters
						filters={(filtersQuery.data ?? []).map((data) => data.value).flat()}
					/>
				</MarkdownTransferSelection>
				<div className="grid grid-cols-[1fr_1fr_max-content] gap-4">
					<MarkdownTransferCard
						title="From bucket"
						text={`The amount of ${
							unit ?? '...'
						} with this markdown will decrease(↘)`}
						className=""
						state={isLoading ? 'disabled' : stateSettings.fromCardState}
						onClick={() => {
							onEventHappens(EEvent.onClickFrom);
						}}
					>
						{bucketsQuery.isFetching ? (
							<div className="flex gap-2">
								<Text type="secondary">Loading...</Text>
								<LoadingSpinner variant="md" />
							</div>
						) : (
							<MarkdownTransferDropdown
								value={from}
								onChange={(value: string) =>
									onEventHappens(EEvent.onChangeFrom, value)
								}
								unselectableBuckets={(bucketsQuery.data ?? []).filter(
									(bucket) => bucket.markdown === to || bucket.count === 0
								)}
								buckets={bucketsQuery.data ?? []}
								unit={unit}
							/>
						)}
					</MarkdownTransferCard>

					<MarkdownTransferCard
						title="To Bucket"
						text={`The amount of ${
							unit ?? '...'
						} with this markdown will increase(↗)`}
						className=""
						state={isLoading ? 'disabled' : stateSettings.toCardState}
						onClick={() => onEventHappens(EEvent.onClickTo)}
					>
						{bucketsQuery.isFetching ? (
							<div className="flex gap-2">
								<Text type="secondary">Loading...</Text>
								<LoadingSpinner variant="md" />
							</div>
						) : (
							<MarkdownTransferDropdown
								className=""
								value={to}
								onChange={(value: string) =>
									onEventHappens(EEvent.onChangeTo, value)
								}
								unselectableBuckets={(bucketsQuery.data ?? []).filter(
									(bucket) => bucket.markdown === from
								)}
								buckets={bucketsQuery.data ?? []}
								unit={unit}
							/>
						)}
					</MarkdownTransferCard>
					<MarkdownTransferCard
						title="Amount"
						text="How many products do you want to transfer?"
						className="w-56"
						state={isLoading ? 'disabled' : stateSettings.amountCardState}
						onClick={() => onEventHappens(EEvent.onClickAmount)}
					>
						<div className="flex gap-2">
							<TextInput
								id="amount"
								className="w-full"
								value={amountDirty}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setAmountDirty(e.currentTarget.value);
								}}
								error={amountError}
								placeholder="Amount"
								type="text"
								endAdornment={undefined}
								startAdornment={undefined}
								size="regular"
								onBlur={() => onEventHappens(EEvent.onBlurAmount)}
							/>
							<Button
								disabled={
									numberTransfersQuery.isFetching ||
									stateSettings.amountCardState === EElementState.disabled ||
									stateSettings.amountCardState === EElementState.background
								}
								onClick={() => {
									onEventHappens(EEvent.onConfirmAmount);
								}}
								size="small"
								ref={confirmAmountButtonRef}
							>
								Next
							</Button>
						</div>
						<div className=" flex text-xs mt-1">
							<Tooltip
								content={((): React.ReactNode => {
									if (from === null || numberTransfersQuery.isFetching)
										return 'loading...';
									const maxInBucket = getMaxAllowedAmount(
										bucketsQuery.data ?? [],
										from ?? 0
									);

									const maxAllowed = numberTransfersQuery?.data as number;

									return maxAllowed !== undefined
										? `${
												maxInBucket - maxAllowed
										  } (Out of ${maxInBucket}) ${unit} are
											not possible to transfer because they would violate your
											business rules.`
										: 'Takes into account your business rules.';
								})()}
							>
								<span className="flex items-end gap-1">
									<Text className="text-gray-500">Out of max </Text>
									{numberTransfersQuery?.isFetching ? (
										<LoadingSpinner
											variant="sm"
											hidden={!numberTransfersQuery.isFetching}
											antiVisualJumping
										/>
									) : (
										<Text className="text-gray-500">
											<strong>{numberTransfersQuery?.data}</strong>
										</Text>
									)}
									<Info className="h-4 text-gray-500 inline-block px-0.5" />
								</span>
							</Tooltip>
						</div>
					</MarkdownTransferCard>
				</div>
				<MarkdownTransferBrace state={stateSettings.phraseState} />
				<MarkdownTransferPhrase
					state={stateSettings.phraseState}
					unit={unit}
					fromObject={{
						text: toInputValue(from),
						highlighted: state === EMarkdownTransferFormState.promptFromBucket,
					}}
					amountObject={{
						text: amount === null ? '...' : String(amount),
						highlighted: state === EMarkdownTransferFormState.promptAmount,
					}}
					toObject={{
						text: toInputValue(to),
						highlighted: state === EMarkdownTransferFormState.promptToBucket,
					}}
				/>
			</Modal.Content>
			<Modal.Actions>
				<div className="flex justify-between">
					<Button variant="secondary" onClick={close}>
						Cancel
					</Button>
					<Button
						variant="primary"
						disabled={stateSettings.applyState === EElementState.disabled}
						onClick={handleTransferClicked}
					>
						Transfer{amount && ` ${amount}`}
						{unit && ` ${unit}`}
					</Button>
				</div>
			</Modal.Actions>
		</article>
	);
};
export default MarkdownTransferModal;
