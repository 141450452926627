import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';
import { GET_CUMULIO_ACTUALS_YOYCOMPARISON_SSO } from '../../shared/api/reports';

const ActualsYoYComparison = () => {
	return (
		<FullCumulioDashboard
			queryKey="cumulo-actuals-yoycomparison"
			ssoQuery={GET_CUMULIO_ACTUALS_YOYCOMPARISON_SSO}
		/>
	);
};

export default ActualsYoYComparison;
