import create from 'zustand';
import { persist } from 'zustand/middleware';

const useStrategyStore = create(
	persist(
		(set) => ({
			strategies: [],
			activeStrategy: '',
			setStrategies: (strategies) => set({ strategies }),
			setActiveStrategy: (activeStrategy) => set({ activeStrategy }),
		}),
		{
			name: 'ca-strategies-store',
			whitelist: ['activeStrategy'],
		}
	)
);

export default useStrategyStore;
