import React, { useEffect, useRef } from 'react';
import { CumulioDashboardComponent } from '@cumul.io/react-cumulio-dashboard';
import useChannelQuery from '../../hooks/useChannelQuery';
import LinearProgress from '../Progress/LinearProgress';
import Button from '../Button/Button';
import RepeatIcon from '../Icons/Repeat';

const FullCumulioDashboard = React.forwardRef(
	(
		{ queryKey, ssoQuery, showLoading = true, noRefreshButton = false },
		ref
	) => {
		const {
			data,
			isFetching: isDataFetching,
			isFetchedAfterMount,
		} = useChannelQuery(queryKey, ssoQuery, {
			refetchOnMount: 'always',
			refetchOnReconnect: 'always',
		});
		const cumulioRef = useRef(null);

		useEffect(async () => {
			await cumulioRef.current?.reloadDashboard();
		}, [data?.token]);

		React.useImperativeHandle(
			ref,
			() => {
				return {
					getData: (cumulioItemId) => {
						if (cumulioRef.current && cumulioItemId) {
							return cumulioRef.current.getData(cumulioItemId);
						}
						return null;
					},
					getFilters: () => {
						if (cumulioRef.current) {
							return cumulioRef.current.getFilters();
						}
						return null;
					},
					reloadDashboard: () => {
						if (cumulioRef?.current?.reloadDashboard) {
							return cumulioRef.current.reloadDashboard();
						}
						return Promise.resolve();
					},
				};
			},
			[]
		);

		return (
			<>
				{showLoading && (
					<div className="absolute left-32 right-0 top-0">
						<LinearProgress visible={isDataFetching} />
					</div>
				)}
				{data?.dashboard_id &&
					data?.id &&
					data?.token &&
					!isDataFetching &&
					isFetchedAfterMount && (
						<div className="relative mt-4">
							<div className="relative overflow-hidden z-0">
								<div className="flex flex-row justify-end">
									{!noRefreshButton && (
										<Button
											className="inline-flex items-center"
											variant="primary"
											size="small"
											onClick={async () =>
												console.log(await cumulioRef.current?.reloadDashboard())
											}
										>
											Reload dashboard <RepeatIcon className="h-3.5 ml-2" />
										</Button>
									)}
								</div>
								<CumulioDashboardComponent
									dashboardId={data?.dashboard_id}
									ref={cumulioRef}
									authKey={data?.id}
									authToken={data?.token}
									loaderBackground="#F5F5F7"
									loaderFontColor="transparent"
									loaderSpinnerColor="#6111C7"
									loaderSpinnerBackground="transparent"
									loaderLogoColor="transparent"
									itemsRendered={(e) => console.log('itemsRendered', e)}
									load={(e) => console.log('loaded', e)}
								/>
							</div>
						</div>
					)}
			</>
		);
	}
);

export default FullCumulioDashboard;
