import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Typemark from '../../shared/components/Branding/Typemark';
import Text from '../../shared/components/Text/Text';
import Button from '../../shared/components/Button/Button';
import CircularProgress from '../../shared/components/Progress/CircularProgress';
import RepeatIcon from '../../shared/components/Icons/Repeat';

import background from '../../shared/images/background.png';

const LoginView = () => {
	const [userHasInteracted, setUserHasInteracted] = useState(false);
	const { loginWithPopup, isAuthenticated, isLoading, error } = useAuth0();
	const { state } = useLocation();

	if (isAuthenticated && !isLoading) {
		if (state?.from?.pathname) {
			return <Redirect to={state?.from?.pathname} />;
		}

		return <Redirect to="/" />;
	}

	const handleSignIn = () => {
		setUserHasInteracted(true);
		loginWithPopup();
	};

	const title = window._ENV_.REACT_APP_VULCAN_ENABLED
		? 'Crunch Stock Assistant'
		: 'Crunch Markdown Assistant';

	return (
		<>
			<Helmet title="Crunch Platform | Login" />
			<div className="flex">
				<div className="relative w-full p-12 min-h-screen flex flex-col md:p-24 lg:w-1/2">
					<div className="flex flex-col my-auto items-center">
						<Typemark className="min-h-12 max-h-12 text-ca-purple" />
						<Text className="my-4" size="text-ca-section">
							{title}
						</Text>
						<div className="mt-6">
							{isLoading && (
								<div className="flex flex-col items-center">
									<CircularProgress />
									{userHasInteracted && (
										<Text className="mt-6" size="text-base" type="secondary">
											Follow the instructions in the pop-up to sign in.
										</Text>
									)}
								</div>
							)}
							{!isLoading && error && (
								<div className="flex flex-col justify-center">
									<Text
										className="text-center"
										size="text-base"
										type="secondary"
									>
										Uh-oh. Something went wrong!
									</Text>
									{error?.error_description && (
										<Text
											className="text-center mt-4"
											size="text-sm"
											type="secondary"
										>
											{error.error_description}
										</Text>
									)}
									<div className="mt-8 flex justify-center">
										<Button
											className="flex items-center"
											variant="primary"
											onClick={handleSignIn}
										>
											Click here to try again
											<RepeatIcon className="h-3.5 ml-2" />
										</Button>
									</div>
									<Text
										className="mt-8 text-center"
										size="text-base"
										type="secondary"
									>
										Please{' '}
										<a
											className="underline"
											href="mailto:support@crunch.fashion"
										>
											contact Crunch
										</a>{' '}
										if the problem persists.
									</Text>
								</div>
							)}
							{!isLoading && !error && (
								<Button
									disabled={isLoading || isAuthenticated}
									onClick={handleSignIn}
									variant="primary"
								>
									Sign in &#8594;
								</Button>
							)}
						</div>
					</div>
				</div>
				<div
					className="w-1/2 min-h-screen bg-cover bg-right-bottom hidden lg:block"
					style={{ backgroundImage: `url(${background})` }}
				/>
			</div>
		</>
	);
};

export default LoginView;
