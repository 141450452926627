import { Redirect, Route } from 'react-router-dom';

import ScenarioOverview from './ScenarioOverview';
import ScenarioComparison from './ScenarioComparison';

import RouterSwitch from '../../shared/components/RouterSwitch/RouterSwitch';
import Page from '../../shared/components/Page/Page';
import Title from '../../shared/components/Title/Title';
import ChannelSelector from '../../shared/components/ChannelSelector/ChannelSelector';
import ScenarioNav from '../components/ScenarioNav';

const ScenarioView = () => {
	return (
		<Page>
			<div className="relative flex justify-between">
				<Title>Scenario</Title>
				<ChannelSelector />
			</div>
			<div className="mt-6 relative">
				<ScenarioNav />
			</div>
			<RouterSwitch>
				<Route exact path="/scenario">
					<Redirect to="/scenario/overview" />
				</Route>
				<Route exact path="/scenario/overview" component={ScenarioOverview} />
				<Route
					exact
					path="/scenario/comparison"
					component={ScenarioComparison}
				/>
			</RouterSwitch>
		</Page>
	);
};

export default ScenarioView;
