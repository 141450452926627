import { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

import { LIST_STRATEGIES } from '../api/strategies';
import usePrevious from '../hooks/usePrevious';

import CircularProgress from '../components/Progress/CircularProgress';
import useStrategyStore from '../data/strategies';
import useChannelQuery from '../hooks/useChannelQuery';

const StrategyContext = createContext();

const StrategyProvider = ({ children }) => {
	const { setStrategies, activeStrategy, setActiveStrategy } =
		useStrategyStore();
	const prevActiveStrategy = usePrevious(activeStrategy);
	const queryClient = useQueryClient();
	const IS_STRATOS_ENABLED = window._ENV_.REACT_APP_STRATOS_ENABLED;

	const { isFetching } = useChannelQuery('strategies', LIST_STRATEGIES, {
		enabled: IS_STRATOS_ENABLED,
		onSuccess: (data) => {
			if (data.length) {
				setStrategies(data);
				// check if the active channel from cache exists
				const active = data?.find(({ slug }) => slug === activeStrategy);

				// if no active channel was set, default set it to the first channel in the list
				if (!active) {
					const strategy = data?.[0]?.slug;
					setActiveStrategy(strategy || '');
				}
			}
		},
	});

	useEffect(() => {
		// active channel changed
		if (
			activeStrategy &&
			prevActiveStrategy &&
			activeStrategy !== prevActiveStrategy
		) {
			// reset all queries here
			queryClient.refetchQueries();
		}
	}, [activeStrategy, prevActiveStrategy]);

	if (!IS_STRATOS_ENABLED) {
		return children;
	}

	if (isFetching && !activeStrategy) {
		return (
			<div className="min-h-screen flex justify-center items-center">
				<CircularProgress />
			</div>
		);
	}

	if (!activeStrategy) {
		return null;
	}

	return children;
};

StrategyProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export { StrategyProvider, StrategyContext };
