import PropTypes from 'prop-types';
import clsx from 'clsx';

const InputWithLabel = ({
	label,
	htmlFor,
	children,
	className,
	labelClassName,
}) => (
	<div
		className={clsx(
			'flex flex-wrap items-center text-ca-gray focus-within:text-ca-black',
			className
		)}
	>
		<p className={clsx('text-sm', labelClassName)}>
			<label htmlFor={htmlFor}>{label}</label>
		</p>
		{children}
	</div>
);

InputWithLabel.propTypes = {
	label: PropTypes.node.isRequired,
	htmlFor: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	labelClassName: PropTypes.string,
};

InputWithLabel.defaultProps = {
	className: '',
	labelClassName: '',
};

export default InputWithLabel;
