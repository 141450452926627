import PropTypes from 'prop-types';
import clsx from 'clsx';

import Text from '../Text/Text';
import CheckmarkIcon from '../Icons/Checkmark';

const Checkbox = ({ checked, label, disabled, onChange }) => {
	return (
		<button
			disabled={disabled}
			type="button"
			tabIndex="-1"
			onClick={() => !disabled && onChange(!checked)}
			className={clsx(
				'flex items-center transition-colors leading-none',
				disabled && 'cursor-not-allowed'
			)}
		>
			<span
				tabIndex={disabled ? '-1' : '0'}
				role="button"
				className={clsx(
					'inline-flex justify-center items-center w-4 h-4 bg-white rounded border border-ca-silver focus:outline-none',
					disabled && 'pointer-events-none',
					!disabled &&
						'focus:border-ca-purple focus:ring-4 focus:ring-opacity-10 focus:ring-ca-purple'
				)}
				style={{ minHeight: '16px', minWidth: '16px' }}
			>
				{checked && (
					<CheckmarkIcon
						className={clsx(
							'h-2',
							disabled ? 'text-ca-gray' : 'text-ca-purple'
						)}
					/>
				)}
			</span>
			{label && (
				<Text
					className="text-left ml-2"
					type={checked ? 'primary' : 'secondary'}
				>
					{label}
				</Text>
			)}
		</button>
	);
};

Checkbox.propTypes = {
	checked: PropTypes.bool,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
};

Checkbox.defaultProps = {
	checked: false,
	label: '',
	disabled: false,
	onChange: () => {},
};

export default Checkbox;
