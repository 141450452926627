import { Fragment, useState, useRef } from 'react';
import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import useOnClickOutside from '../../hooks/useOnClickOutside';
import useKeyPress from '../../hooks/useKeyPress';

import Tag from '../Tag/Tag';
import Text from '../Text/Text';
import TriangleDownIcon from '../Icons/TriangleDown';
import CircularProgress from '../Progress/CircularProgress';

const TagInput = ({
	id,
	value,
	placeholder,
	options,
	error,
	loading,
	className,
	onChange,
}) => {
	const ref = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const val = options?.find((o) => o?.value === value);

	useOnClickOutside(ref, () => setIsOpen(false));
	useKeyPress('Escape', () => setIsOpen(false));

	// cleanup the search filter for easier compare
	const searchValueFiltered = searchValue.trim().toLocaleLowerCase();

	// filter out the options if they are:
	// - currently active
	// - do not match the search filter
	const filteredOptions = options.filter((o) => {
		if (o.value === val?.value) {
			return false;
		}

		return o.label.toLocaleLowerCase().includes(searchValueFiltered);
	});

	// check if the searched value matches one of the options for `100%`
	const isExactMatch = options.find(
		(option) => option.label.toLocaleLowerCase() === searchValueFiltered
	);

	const handleChange = (newValue, isNew) => {
		onChange(newValue, isNew);
		setIsOpen(false);

		// cleanup search value after calling `onChange`
		setSearchValue('');
	};

	return (
		<div
			ref={ref}
			tabIndex="-1"
			role="textbox"
			className={clsx('relative inline-block text-left z-30', className)}
		>
			<div
				role="button"
				tabIndex="-1"
				className={clsx(
					'inline-flex justify-between items-center w-full pr-4 rounded-lg border border-ca-silver bg-white text-sm leading-none cursor-pointer transition-colors focus-within:outline-none focus-within:border-ca-purple focus-within:ring-4 focus-within:ring-opacity-10 focus-within:ring-ca-purple',
					isOpen && 'border-ca-purple ring-4 ring-opacity-10 ring-ca-purple',
					!error && 'focus-within:border-ca-purple focus-within:ring-ca-purple',
					error && 'text-ca-red border-ca-red focus:ring-ca-red'
				)}
				onClick={() => setIsOpen(!isOpen)}
				onKeyDown={null}
			>
				{val?.label ? (
					<div className="flex py-2 mt-px mb-px pl-2">
						<Tag
							label={val.label}
							removable
							onClick={() => handleChange(null)}
						/>
					</div>
				) : (
					<input
						id={id}
						className="w-full h-10 py-3 pl-4 text-ca-black placeholder-ca-gray bg-white text-sm rounded-lg leading-none focus:outline-none"
						placeholder={placeholder}
						value={searchValue}
						onChange={(e) => setSearchValue(e.target.value)}
					/>
				)}
				<TriangleDownIcon className="text-ca-gray ml-2 h-2 pointer-events-none" />
			</div>
			{error && typeof error === 'string' && (
				<div className="w-full mt-1 text-ca-red text-xs text-right">
					{error}
				</div>
			)}
			<Transition
				show={isOpen}
				as={Fragment}
				enter="relative transition ease-out duration-100 transform"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="relative transition ease-in duration-75 transform"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
			>
				<div className="relative z-10">
					{loading && (
						<div className="origin-top-right absolute left-0 w-full mt-2 bg-white rounded-lg shadow-ca focus:outline-none z-20">
							<div className="flex justify-center py-4">
								<CircularProgress />
							</div>
						</div>
					)}
					{!loading &&
						(!!filteredOptions.length || !!searchValue?.trim().length) && (
							<div
								className={clsx(
									'origin-top-right absolute left-0 w-full max-h-40 overflow-auto bg-white rounded-lg shadow-ca focus:outline-none z-20',
									error ? '-mt-3' : 'mt-2'
								)}
							>
								{!!filteredOptions.length && (
									<>
										<div className="p-4 space-y-1">
											{filteredOptions.map((option) => (
												<div key={option.value}>
													<Tag
														label={option.label}
														onClick={() => handleChange(option.value)}
													/>
												</div>
											))}
										</div>
										<hr className="border-ca-silver" />
									</>
								)}
								{!isExactMatch && !!searchValue?.trim().length && (
									<div className="p-4 flex justify-between flex-wrap">
										<Text type="secondary">New tag:</Text>
										<Tag
											label={searchValue}
											onClick={() => handleChange(searchValue.trim(), true)}
										/>
									</div>
								)}
							</div>
						)}
				</div>
			</Transition>
		</div>
	);
};

TagInput.propTypes = {
	id: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		})
	),
	error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	loading: PropTypes.bool,
	className: PropTypes.string,
	onChange: PropTypes.func,
};

TagInput.defaultProps = {
	placeholder: 'Type or choose',
	value: undefined,
	options: [],
	error: false,
	loading: false,
	className: '',
	onChange: () => {},
};

export default TagInput;
