import PropTypes from 'prop-types';
import EngineButton from '../EngineButton/EngineButton';
import {
	RUN_BUSINESS_RULE_CHECKER,
	GET_BUSINESS_RULE_CHECKER_STATUS,
} from '../../api/strategies';

const BusinessRuleCheckerButton = ({ name, strategyId }) => {
	return (
		<EngineButton
			name={name}
			strategyId={strategyId}
			statusGetCall={GET_BUSINESS_RULE_CHECKER_STATUS}
			runPostCall={RUN_BUSINESS_RULE_CHECKER}
			engineName="business rule checker"
		/>
	);
};
BusinessRuleCheckerButton.propTypes = {
	name: PropTypes.string,
	strategyId: PropTypes.string.isRequired,
};
export default BusinessRuleCheckerButton;
