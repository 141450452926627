import {
	QueryClient,
	QueryClientProvider,
	QueryCache,
	MutationCache,
} from 'react-query';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import PropTypes from 'prop-types';

import useToast from './shared/data/toast';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: (failureCount, err) => {
				if (failureCount >= 3 && err?.response?.status !== 401) {
					return false;
				}

				return err?.response?.status !== 404;
			},
		},
	},
	queryCache: new QueryCache({
		onError: async ({ response }) => {
			// no 401 toast for channels call
			if (
				response?.status === 401 &&
				response?.url.includes('/api/v2/channels')
			) {
				return;
			}

			// no toasts for algorithm run status
			if (response?.url.includes('/run/status')) {
				return;
			}

			if (response) {
				const { detail } = await response.json();

				const { show, toasts } = useToast.getState();

				if (window.location.pathname === '/login') {
					// don't show error toasts on login screen
					return;
				}

				// check if there is already a 401/403 toast being shown
				// to prevent the user from being flooded by "Error 401/403: Unauthorized" messages
				if (response?.status === 401 || response?.status === 403) {
					const unauthorizedToast = toasts.find(
						(t) =>
							t.message.includes('Error 401') ||
							(t.message.includes('Error 403') && t.visible)
					);

					if (unauthorizedToast) return;
				}

				show(
					`Error ${response?.status}: ${
						typeof detail === 'string'
							? detail
							: response?.statusText || 'Unknown error'
					}`,
					{
						type: 'error',
					}
				);
			}
		},
	}),
	mutationCache: new MutationCache({
		onError: async ({ response }) => {
			if (response) {
				const { detail } = await response.json();

				useToast
					.getState()
					.show(
						`Error ${response?.status}: ${
							typeof detail === 'string'
								? detail
								: response?.statusText || 'Unknown error'
						}`,
						{
							type: 'error',
						}
					);
			}
		},
	}),
});

const localStoragePersistor = createWebStoragePersistor({
	storage: window.localStorage,
});

persistQueryClient({
	queryClient,
	persistor: localStoragePersistor,
});

const QClientProvider = ({ children }) => (
	<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

QClientProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default QClientProvider;
