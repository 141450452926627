import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import worker from './mocks/worker';

// Set `onUnhandledRequest` to `warn` to log unhandled requests to the console.
if (process.env.REACT_APP_ENVIRONMENT === 'local') {
	worker.start({
		onUnhandledRequest: 'bypass',
	});
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);
