import { useState, useEffect } from 'react';

const useDebounce = (value, delay, validator = () => true) => {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(
		() => {
			// Update debounced value after delay
			const handler = setTimeout(() => {
				// optionally a validator can be passed to deteremine when debounces should be skipped (e.g. minLength on a search term)
				if (validator(value)) {
					setDebouncedValue(value);
				}
			}, delay);
			// Cancel the timeout if value changes (also on delay change or unmount)
			// This is how we prevent debounced value from updating if value is changed ...
			// .. within the delay period. Timeout gets cleared and restarted.
			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay] // Only re-call effect if value or delay changes
	);

	return debouncedValue;
};

export default useDebounce;
