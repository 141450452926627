import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Nav from '../../shared/components/Nav/Nav';

const NAV_ITEMS = [
	{
		id: 'overview',
		label: 'Overview',
		to: '/scenario/overview',
	},
	{
		id: 'comparison',
		label: 'Comparison',
		to: '/scenario/comparison',
	},
];

const ScenarioNav = () => {
	const { pathname } = useLocation();
	const match = useMemo(
		() =>
			[...NAV_ITEMS]
				.sort((a, b) => b.to.split('/').length - a.to.split('/').length)
				.find((item) => pathname.startsWith(item.to)),

		[pathname]
	);

	return <Nav active={match?.id} items={NAV_ITEMS} />;
};

export default ScenarioNav;
