import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouterSwitch = ({ children }) => {
	return (
		<Switch>
			{children}
			<Route path="*">
				<Redirect to="/page-not-found" />
			</Route>
		</Switch>
	);
};

RouterSwitch.propTypes = {
	children: PropTypes.node.isRequired,
};

export default RouterSwitch;
