import api from './api';

export const GET_STRATEGY = (id) => {
	return api.get(`api/v2/strategies/${id}`).json();
};

export const LIST_STRATEGIES = () => {
	return api.get(`api/v2/strategies`).json();
};

export const GET_DEFAULT_STRATEGY = () => {
	return api.get('api/v2/strategies/default').json();
};

export const GET_TEST_STRATEGY = () => {
	return api.get('api/v2/strategies/test').json();
};

export const CREATE_STRATEGY = (payload) => {
	return api
		.post('api/v2/strategies', {
			json: payload,
		})
		.json();
};

export const DELETE_STRATEGY = (id) => {
	return api.delete(`api/v2/strategies/${id}`).json();
};

export const GET_STRATEGY_COMPONENT = (strategyId, componentId) => {
	return api
		.get(`api/v2/strategies/${strategyId}/components/${componentId}`)
		.json();
};

export const UPDATE_STRATEGY_COMPONENT = (strategyId, componentId, payload) => {
	return api
		.put(`api/v2/strategies/${strategyId}/components/${componentId}`, {
			json: payload,
		})
		.json();
};

export const GET_STRATEGIES_SUMMARY = (strategy) => {
	return api.get(`api/v2/strategies/summary/${strategy}`).json();
};

export const GET_STRATEGY_RUN_STATUS = (strategyId) => {
	return api.get(`api/v2/strategies/${strategyId}/run/status`).json();
};

export const RUN_STRATEGY = (strategyId) => {
	return api.post(`api/v2/strategies/${strategyId}/run`).json();
};

export const GET_BUSINESS_RULE_CHECKER_STATUS = (strategyId) => {
	return api
		.get(`api/v2/strategies/${strategyId}/business_rule_checker/status`)
		.json();
};

export const RUN_BUSINESS_RULE_CHECKER = (strategyId) => {
	return api
		.post(`api/v2/strategies/${strategyId}/business_rule_checker`)
		.json();
};

export const GET_DEFAULT_OBJECTIVE_SCENARIOS = () => {
	return api.get(`api/v2/strategy-settings/default-objective-scenarios`).json();
};
