import { ChangeEvent, FunctionComponent } from 'react';
import { Control, Controller, UseFieldArrayRemove } from 'react-hook-form';
import IconButton from '../../../shared/components/IconButton/IconButton';
import Cross from '../../../shared/components/Icons/Cross';
import TextInput from '../../../shared/components/TextInput/TextInput';
import useModal from '../../../shared/hooks/useModal';
import {
	Configuration,
	MarketingExpectation,
	Scope,
} from '../../../shared/models/configuration';
import { ProductDTO, StockLocationDTO } from '../../../shared/models/schema';
import LocationsTableModal from '../modals/LocationsTableModal';
import ProductsTableModal from '../modals/ProductsTableModal';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';

interface Props {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
	marketingExpectation: MarketingExpectation;
	control: Control<Configuration>;
	remove: UseFieldArrayRemove;
	index: number;
	onChange: (scope: Scope, index: number) => void;
}

const MarketingExpectationFieldset: FunctionComponent<Props> = ({
	inventoryAllocationId,
	reportId,
	marketingExpectation,
	control,
	remove,
	index,
	onChange,
}) => {
	const { open } = useModal();

	const onSelectedProductsChange = (products: ProductDTO['id'][]): void => {
		onChange({ ...marketingExpectation.scope, products }, index);
	};

	const onSelectedLocationsChange = (
		locations: StockLocationDTO['stock_location_id'][]
	): void => {
		onChange({ ...marketingExpectation.scope, locations }, index);
	};

	function openProductsModal(): void {
		open(
			<ProductsTableModal
				inventoryAllocationId={inventoryAllocationId}
				reportId={reportId}
				selectedProducts={marketingExpectation.scope.products}
				setSelectedProducts={onSelectedProductsChange}
			/>,
			'w-4/5'
		);
	}

	function openLocationsModal(): void {
		open(
			<LocationsTableModal
				inventoryAllocationId={inventoryAllocationId}
				reportId={reportId}
				selectedLocations={marketingExpectation.scope.locations}
				setSelectedLocations={onSelectedLocationsChange}
			/>,
			'w-4/5'
		);
	}

	return (
		<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg">
			<div className="flex gap-4 justify-between">
				<p className="text-ca-gray">
					Due to{' '}
					<Controller
						name={`marketingExpectations.${index}.campaignName`}
						control={control}
						rules={{ required: 'Required field' }}
						render={({ field }) => (
							<TextInput
								type="text"
								className="w-36"
								endAdornment={undefined}
								startAdornment={undefined}
								error=""
								id=""
								placeholder="Campaign name"
								size="regular"
								value={field.value}
								onChange={field.onChange}
							/>
						)}
					/>{' '}
					we expect{' '}
					<button type="button" onClick={openProductsModal}>
						<strong className="text-ca-purple">{`${marketingExpectation.scope.products.length} products`}</strong>
					</button>{' '}
					over{' '}
					<button type="button" onClick={openLocationsModal}>
						<strong className="text-ca-purple">{`${marketingExpectation.scope.locations.length} locations`}</strong>
					</button>{' '}
					to sell{' '}
					<Controller
						name={`marketingExpectations.${index}.salesModifier`}
						control={control}
						rules={{ required: 'Required field', min: 0.1 }}
						render={({ field }) => (
							<TextInput
								type="number"
								className="w-24 mt-4"
								endAdornment={undefined}
								startAdornment={undefined}
								error=""
								id=""
								placeholder={marketingExpectation.salesModifier}
								size="regular"
								value={field.value}
								min={0.1}
								step={0.1}
								onChange={(event: ChangeEvent<HTMLInputElement>) =>
									field.onChange(parseFloat(event.target.value))
								}
							/>
						)}
					/>{' '}
					times as well this period.
				</p>
				<IconButton
					onClick={() => remove(index)}
					icon={Cross}
					className="w-2.5"
				/>
			</div>
		</div>
	);
};

export default MarketingExpectationFieldset;
