import clsx from 'clsx';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Auth0Provider } from '@auth0/auth0-react';
import { ReactQueryDevtools } from 'react-query/devtools';
import JWTProvider from './shared/components/JWT/JWTProvider';
import Sidebar, { SidebarLinkType } from './shared/components/Sidebar/Sidebar';
import ToastQueue from './shared/components/Toast/ToastQueue';
import { ChannelProvider } from './shared/context/ChannelContext';
import { ModalProvider } from './shared/context/ModalContext';

import LoginView from './login/views/Login';

import QueryClientProvider from './QueryClientProvider';

import EnabledRoutes from './EnabledRoutes';
import ExperimentIcon from './shared/components/Icons/Experiment';
import StratosSidebar from './shared/components/StratosSidebar/StratosSidebar';
import { StrategyProvider } from './shared/context/StrategyContext';

const VULCAN_SIDEBAR_ITEMS: SidebarLinkType[] = [
	{
		id: 'inventory-allocations',
		label: 'Inventory Allocations',
		to: '/stock/inventory-allocations',
		icon: <ExperimentIcon />,
		isEnabled: true,
	},
];

const App = () => {
	return (
		<HelmetProvider>
			<QueryClientProvider>
				{process.env.NODE_ENV === 'development' && (
					<ReactQueryDevtools position="bottom-right" />
				)}
				<ModalProvider>
					<BrowserRouter>
						<Auth0Provider
							domain={window._ENV_.REACT_APP_AUTH0_DOMAIN}
							clientId={window._ENV_.REACT_APP_AUTH0_CLIENT_ID}
							redirectUri={`${window.location.origin}/login`}
							scope="read:current_user"
							audience={`https://${window._ENV_.REACT_APP_AUTH0_DOMAIN}/api/v2/`}
							organization={window._ENV_.REACT_APP_AUTH0_ORGANIZATION_ID}
							useRefreshTokens
							cacheLocation="localstorage"
						>
							<JWTProvider>
								<div
									className={clsx(
										'bg-ca-ghost-white min-h-screen',
										process.env.NODE_ENV === 'development'
											? // quickly identify which text is missing a color definition in DEV-mode
											  'text-red-600'
											: 'text-ca-black'
									)}
								>
									<Switch>
										<Route exact path="/login" component={LoginView} />
										<ChannelProvider>
											<Route exact path="*">
												<Helmet title="Crunch Platform" />
												<Switch>
													<Route path="/stock">
														<Sidebar
															activePlatform="Stock"
															links={VULCAN_SIDEBAR_ITEMS}
														/>
													</Route>
													<Route
														path={[
															'/actuals',
															'/strategy',
															'/opportunities',
															'/scenario',
															'/support',
															'/page-not-found',
															'/global',
															'/',
														]}
													>
														<StratosSidebar />
													</Route>
												</Switch>
												<div className="pl-40">
													<StrategyProvider>
														<EnabledRoutes />
													</StrategyProvider>
												</div>
											</Route>
										</ChannelProvider>
									</Switch>
								</div>
							</JWTProvider>
						</Auth0Provider>
					</BrowserRouter>
				</ModalProvider>
				<ToastQueue />
			</QueryClientProvider>
		</HelmetProvider>
	);
};

export default App;
