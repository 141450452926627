import Button from '../Button/Button';
import RepeatIcon from '../Icons/Repeat';

type Props = {
	dashboardRef: React.RefObject<any>;
};

const RefreshCumulioDashboardButton: React.FC<Props> = (props) => {
	return (
		<Button
			className="inline-flex items-center"
			variant="primary"
			size="small"
			onClick={() => props.dashboardRef.current?.reloadDashboard()}
		>
			Reload dashboard <RepeatIcon className="h-3.5 ml-2" />
		</Button>
	);
};

export default RefreshCumulioDashboardButton;
