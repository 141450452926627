import clsx from 'clsx';
import { Fragment, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { GET_BUSINESS_RULES_COMPONENT } from '../../../shared/api/business-rules';
import {
	GET_DEFAULT_OBJECTIVE_SCENARIOS,
	GET_STRATEGY,
	GET_STRATEGY_COMPONENT,
	UPDATE_STRATEGY_COMPONENT,
} from '../../../shared/api/strategies';

import useChannelQuery from '../../../shared/hooks/useChannelQuery';
import get from '../../../shared/utils/get';
import Button from '../../../shared/components/Button/Button';
import Checkbox from '../../../shared/components/Checkbox/Checkbox';
import InfoIcon from '../../../shared/components/Icons/Info';
import InputWithLabel from '../../../shared/components/InputWithLabel/InputWithLabel';
import LinearProgress from '../../../shared/components/Progress/LinearProgress';
import Table from '../../../shared/components/Table/Table';
import Tag from '../../../shared/components/Tag/Tag';
import Text from '../../../shared/components/Text/Text';
import TextInput from '../../../shared/components/TextInput/TextInput';
import Title from '../../../shared/components/Title/Title';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';
import ResidualValueGraph from '../../components/ResidualValueGraph';
import ResidualValuePresetSlider from '../../components/ResidualValuePresetSlider';
import Accordion from '../../../shared/components/Accordion/Accordion';

const HEADINGS = [
	{ id: 'title', label: 'Name' },
	{ id: 'br_type', label: 'Level' },
	{ id: 'tag', label: 'Tag' },
	{ id: 'action_type', label: 'Type' },
	{ id: 'discount', label: 'Discount' },
	{
		id: 'nr_applied',
		label: 'Applied',
		tooltip:
			'Indicates the amount of products to which the business rule is applied.',
	},
	{
		id: 'nr_violated',
		label: 'Violated',
		tooltip:
			'Indicates the amount of products to which the business rule is not applied.',
	},
];

/** internalValue: number */
const toInputValue = (internalValue) =>
	internalValue === (undefined || null)
		? ''
		: Math.round(internalValue).toString();
/** inputValue: string */
const toInternalValue = (inputValue) =>
	Number.isNaN(inputValue) ? null : inputValue;

const StrategyComponentsEdit = () => {
	const history = useHistory();
	const { strategyId, componentId } = useParams();
	const { formState, setValue, control, handleSubmit, watch } = useForm({
		defaultValues: {
			markdown_intensity: 0.0,
			objective_scenario_name: '',
			objective_min_residual_value: 0.0,
			objective_max_residual_value: 0.0,
			objective_sell_through_target: 0.0,
			objective_include_shipping_cost: true,
			objective_include_return_cost: true,
			end_period: '',
			business_rules_ids: [],
		},
	});
	const queryClient = useQueryClient();
	let headings = HEADINGS;
	if (!window._ENV_.REACT_APP_BUSINESS_RULE_CHECKER_ENABLED) {
		headings = headings.filter(
			(heading) => !['nr_applied', 'nr_violated'].includes(heading.id)
		);
	}

	const [
		watchMarkdownIntensity,
		watchObjectiveMinResidualValue,
		watchObjectiveMaxResidualValue,
		watchObjectiveSellThroughTarget,
	] = watch([
		'markdown_intensity',
		'objective_min_residual_value',
		'objective_max_residual_value',
		'objective_sell_through_target',
	]);

	const returnUrl = `/strategy/strategies/${strategyId}`;

	const {
		isLoading: isBusinessRulesLoading,
		isFetching: isBusinessRulesFetching,
		data: businessRules,
		channel,
	} = useChannelQuery(
		['business-rules', strategyId, componentId],
		() => GET_BUSINESS_RULES_COMPONENT(strategyId, componentId),
		{
			onError: async ({ response }) => {
				if (response?.status === 404) {
					history.push(returnUrl);
				}
			},
			retry: false,
		}
	);

	const { data: defaultObjectiveScenarios } = useChannelQuery(
		['default-objective-scenarios'],
		GET_DEFAULT_OBJECTIVE_SCENARIOS
	);

	useEffect(() => {
		if (watchMarkdownIntensity === 'custom' || !defaultObjectiveScenarios) {
			setValue('objective_scenario_name', watchMarkdownIntensity);
			return;
		}

		const currentObjectiveScenario = defaultObjectiveScenarios?.find(
			(objectiveScenario) =>
				objectiveScenario.intensity_level === watchMarkdownIntensity
		);

		setValue('objective_scenario_name', currentObjectiveScenario?.name);

		setValue(
			'objective_max_residual_value',
			currentObjectiveScenario?.max_residual_value,
			{ shouldDirty: true }
		);
		setValue(
			'objective_min_residual_value',
			currentObjectiveScenario?.min_residual_value,
			{ shouldDirty: true }
		);
		setValue(
			'objective_sell_through_target',
			currentObjectiveScenario?.sell_through_target,
			{ shouldDirty: true }
		);
	}, [watchMarkdownIntensity, defaultObjectiveScenarios]);

	useEffect(() => {
		if (!defaultObjectiveScenarios) return;

		const currentObjectiveScenario = defaultObjectiveScenarios?.find(
			(objectiveScenario) =>
				objectiveScenario.min_residual_value ===
					watchObjectiveMinResidualValue &&
				objectiveScenario.max_residual_value ===
					watchObjectiveMaxResidualValue &&
				objectiveScenario.sell_through_target ===
					watchObjectiveSellThroughTarget
		);

		if (currentObjectiveScenario) {
			setValue('markdown_intensity', currentObjectiveScenario.intensity_level, {
				shouldDirty: true,
			});
		} else {
			setValue('markdown_intensity', 'custom');
		}

		if (watchObjectiveMinResidualValue > watchObjectiveMaxResidualValue) {
			setValue('objective_min_residual_value', watchObjectiveMaxResidualValue);
		}
	}, [
		watchObjectiveMinResidualValue,
		watchObjectiveMaxResidualValue,
		watchObjectiveSellThroughTarget,
		defaultObjectiveScenarios,
	]);

	const { isLoading: isStrategyLoading, data: strategy } = useChannelQuery(
		['strategy', strategyId],
		() => GET_STRATEGY(strategyId)
	);

	const { isFetching, data: component } = useChannelQuery(
		['strategy-component', strategyId, componentId],
		() => GET_STRATEGY_COMPONENT(strategyId, componentId),
		{
			onError: async ({ response }) => {
				if (response?.status === 404) {
					history.push(returnUrl);
				}
			},
			retry: false,
		}
	);

	useEffect(() => {
		if (component) {
			setValue('setting_id', component?.setting?.id);
			setValue(
				'business_rules_ids',
				component?.business_rules?.map(({ id }) => id)
			);
			setValue('end_period', component?.end_period);

			setValue(
				'objective_max_residual_value',
				component?.objective_max_residual_value ??
					component?.objective_residual_value ??
					100, // 2023.10.06 - If data is still from old version, this will be used. Once all clients use new version, this should be removed.
				{ shouldDirty: true }
			);
			setValue(
				'objective_min_residual_value',
				component?.objective_min_residual_value,
				{ shouldDirty: true }
			);

			setValue(
				'objective_sell_through_target',
				component?.objective_sell_through_target,
				{ shouldDirty: true }
			);
			setValue(
				'objective_include_shipping_cost',
				component?.objective_include_shipping_cost
			);
			setValue(
				'objective_include_return_cost',
				component?.objective_include_return_cost
			);
		}
	}, [component]);

	const targetStrategy = strategyId;

	const {
		isLoading: isEditLoading,
		isSuccess: isEditSuccess,
		mutate: editComponent,
	} = useMutation(
		(payload) => UPDATE_STRATEGY_COMPONENT(strategyId, componentId, payload),
		{
			onMutate: async (payload) => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries([channel, targetStrategy]);

				// Snapshot the previous value
				const previousStrategy = queryClient.getQueryData([
					channel,
					targetStrategy,
				]);

				if (previousStrategy) {
					// Optimistically update to the new value
					queryClient.setQueryData([channel, targetStrategy], (old) => {
						return {
							...old,
							components: (old?.components || []).map((comp) => {
								if (comp.id === component.id) {
									return {
										...payload,
										business_rules: payload.business_rules_ids.map((bId) =>
											businessRules?.items.find(({ id }) => id === bId)
										),
										category: comp.category,
										category_products_count: comp.category_products_count,
										is_default: comp.is_default,
									};
								}

								return comp;
							}),
						};
					});
				}

				// Return a context object with the snapshotted value
				return { previousStrategy };
			},
			// If the mutation fails, use the context returned from onMutate to roll back
			onError: (err, payload, context) => {
				queryClient.setQueryData(
					[channel, targetStrategy],
					context.previousBusinessRules
				);
			},
			// Always refetch after error or success:
			onSettled: () => {
				queryClient.invalidateQueries([channel, targetStrategy]);
			},
		}
	);

	const onSubmit = (d) => {
		editComponent({
			...d,
			objective_max_residual_value: d.objective_max_residual_value ?? 1,
		});
	};

	const handleAnimationEnded = () => {
		if (isEditSuccess) {
			history.push(returnUrl);
		}
	};

	return (
		<>
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress
					visible={isBusinessRulesFetching || isEditLoading || isFetching}
					onAnimationEnded={handleAnimationEnded}
				/>
			</div>

			<div className="py-6">
				<Title type="section">Edit component</Title>
				{!isFetching && (
					<form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
						<Text type="secondary">
							Edit this strategy component by combining markdown settings and
							business rules for this category
						</Text>
						<div className="mt-6 space-y-5">
							<InputWithLabel
								label="Category"
								htmlFor="category_id"
								labelClassName={clsx(
									'w-64 mb-2 md:mb-0',
									formState?.errors?.category_id?.message && '-mt-5'
								)}
							>
								<Text>{component?.category?.name}</Text>
							</InputWithLabel>
							<InputWithLabel
								label="End of markdown period"
								htmlFor="end_period"
								labelClassName={clsx(
									'w-64 mb-2 md:mb-0',
									formState?.errors?.end_period?.message && '-mt-5'
								)}
							>
								{!isStrategyLoading && strategy?.is_default && (
									<Controller
										name="end_period"
										control={control}
										rules={{ required: 'Required field' }}
										render={({ field }) => (
											<TextInput
												id="end_period"
												type="date"
												className="w-full sm:w-64"
												value={field.value}
												onChange={(val) => field.onChange(val)}
												error={formState?.errors?.end_period?.message}
											/>
										)}
									/>
								)}
								{!isStrategyLoading && !strategy?.is_default && (
									<Text>{component?.end_period}</Text>
								)}
							</InputWithLabel>

							<InputWithLabel
								label="Include shipping cost"
								labelClassName="w-64"
							>
								<Controller
									name="objective_include_shipping_cost"
									control={control}
									render={({ field }) => (
										<Checkbox
											checked={field.value}
											onChange={field.onChange}
											label=""
										/>
									)}
								/>
							</InputWithLabel>

							<InputWithLabel label="Include return cost" labelClassName="w-64">
								<Controller
									name="objective_include_return_cost"
									control={control}
									render={({ field }) => (
										<Checkbox
											checked={field.value}
											onChange={field.onChange}
											label=""
										/>
									)}
								/>
							</InputWithLabel>

							<InputWithLabel
								label="Markdown intensity"
								htmlFor="markdown_intensity"
								labelClassName="w-64 mb-2 md:mb-0"
							>
								<Controller
									name="markdown_intensity"
									control={control}
									render={({ field }) => (
										<div className="w-full flex-grow mt-5 mb-5 ml-3">
											<ResidualValuePresetSlider
												className="max-w-lg"
												options={defaultObjectiveScenarios}
												value={watchMarkdownIntensity}
												onChange={(e) => {
													field.onChange(e);
												}}
											/>
										</div>
									)}
								/>
							</InputWithLabel>
							<div>
								<div className="flex gap-3">
									<ResidualValueGraph
										maxResidualValue={watchObjectiveMaxResidualValue}
										sellThroughTarget={watchObjectiveSellThroughTarget ?? 100}
										minResidualValue={
											watchObjectiveMinResidualValue !== null
												? watchObjectiveMinResidualValue
												: watchObjectiveMaxResidualValue
										}
										height={250}
										width={450}
									/>
									<div>
										<Accordion
											startOpen={
												component !== undefined && // component should never be undefined because this only get rendered if isFetching === false
												component.objective_scenario_name === 'custom'
											}
											renderTrigger={() => (
												<Text className="text-zinc-500 underline">
													Advanced properties
												</Text>
											)}
											renderContent={() => (
												<div className="mr-2 flex flex-col gap-2 py-2">
													<InputWithLabel
														label="Max residual value (%)"
														htmlFor="objective_max_residual_vaue"
														labelClassName="w-44 mb-2 md:mb-0"
													>
														<Controller
															name="objective_max_residual_value"
															control={control}
															render={({ field }) => (
																<TextInput
																	placeholder="..."
																	id="objective_max_residual_value"
																	type="number"
																	min="0"
																	step="1"
																	className="w-full md:w-24 lg:w-28"
																	value={toInputValue(field.value)}
																	onChange={(e) => {
																		const newValue = parseFloat(e.target.value);
																		field.onChange(toInternalValue(newValue));
																	}}
																/>
															)}
														/>
													</InputWithLabel>
													<InputWithLabel
														label="Min residual value (%)"
														htmlFor="objective_min_residual_vaue"
														labelClassName="w-44 mb-2 md:mb-0"
													>
														<Controller
															name="objective_min_residual_value"
															control={control}
															render={({ field }) => (
																<TextInput
																	placeholder="..."
																	id="objective_min_residual_value"
																	type="number"
																	max={watchObjectiveMaxResidualValue * 100}
																	step="1"
																	className="w-full md:w-24 lg:w-28"
																	value={toInputValue(field.value)}
																	onChange={(e) => {
																		const newValue = parseFloat(e.target.value);
																		field.onChange(toInternalValue(newValue));
																	}}
																/>
															)}
														/>
													</InputWithLabel>
													<InputWithLabel
														label="Sell through target (%)"
														htmlFor="objective_sell_through_target"
														labelClassName="w-44 mb-2 md:mb-0"
													>
														<Controller
															name="objective_sell_through_target"
															control={control}
															render={({ field }) => (
																<TextInput
																	placeholder="..."
																	id="objective_sell_through_target"
																	type="number"
																	min="0"
																	max="100"
																	step="1"
																	className="w-full md:w-24 lg:w-28"
																	value={toInputValue(field.value)}
																	onChange={(e) => {
																		const newValue = parseFloat(e.target.value);
																		field.onChange(toInternalValue(newValue));
																	}}
																/>
															)}
														/>
													</InputWithLabel>
												</div>
											)}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="mt-6">
							<div className="mb-4 flex justify-between items-end">
								<Text type="secondary">
									Business rules to enable in this strategy:&nbsp;
								</Text>
								{formState?.errors?.business_rules_ids?.message && (
									<p className="text-ca-red text-sm text-right">
										{formState?.errors?.business_rules_ids?.message}
									</p>
								)}
							</div>
							<Controller
								name="business_rules_ids"
								control={control}
								render={({ field }) => (
									<Table
										headings={headings}
										rows={businessRules?.items}
										selectable
										selected={field.value}
										onSelectedChange={(val) => field.onChange(val)}
										loading={isBusinessRulesLoading}
										itemsLoading={3}
										emptyState="No business rules found..."
										renderCell={(row, columnId) => {
											const isSelected = field.value.includes(row?.id);

											if (columnId === 'tag') {
												return row?.[columnId] ? (
													<Tag label={row?.[columnId]} />
												) : null;
											}

											if (columnId === 'discount') {
												if (row?.action_type === 'Custom_fixed') {
													return `Fixed ${(
														row?.custom_fixed_action?.fixed_discount * 100
													).toFixed(0)}%`;
												}

												if (row?.action_type === 'Custom_min_change') {
													return `Minimal change ${(
														row?.custom_min_change_action?.min_change_discount *
														100
													).toFixed(0)}%`;
												}

												if (row?.action_type === 'Custom_max_increase') {
													return `Maximal increase ${(
														row?.custom_max_increase_action
															?.max_increase_discount * 100
													).toFixed(0)}%`;
												}

												if (row?.action_type === 'Custom_average') {
													return `Average ${(
														row?.custom_average_action?.average_discount * 100
													).toFixed(0)}%`;
												}

												if (row?.action_type === 'Custom_distribution') {
													return (
														<Tooltip
															content={
																<>
																	<span className="capitalize">
																		{row?.custom_distribution_action?.limiter}
																	</span>{' '}
																	<span>
																		{(
																			row?.custom_distribution_action
																				?.distribution * 100
																		).toFixed(0)}
																		%
																	</span>
																	{' of '}
																	<span>
																		{row?.custom_distribution_action?.type}
																	</span>
																	{' should be discounted at '}
																	<span>
																		{(
																			row?.custom_distribution_action
																				?.discount * 100
																		).toFixed(0)}
																		%
																	</span>
																</>
															}
														>
															<span className="flex items-center space-x-1">
																<span>Discount distribution</span>
																<InfoIcon className="inline-block text-ca-gray h-3.5" />
															</span>
														</Tooltip>
													);
												}

												if (row?.action_type === 'Custom_minmax') {
													const output = [];

													if (
														!Number.isNaN(
															parseFloat(
																row?.custom_minmax_action?.min_discount,
																10
															)
														)
													) {
														output.push(
															`Min. ${(
																row?.custom_minmax_action?.min_discount * 100
															).toFixed(0)}%`
														);
													}

													if (
														!Number.isNaN(
															parseFloat(
																row?.custom_minmax_action?.max_discount,
																10
															)
														)
													) {
														output.push(
															`Max. ${(
																row?.custom_minmax_action?.max_discount * 100
															).toFixed(0)}%`
														);
													}

													return output.join(', ');
												}

												if (row?.action_type === 'Custom_possible') {
													return (
														<Tooltip
															content={
																<>
																	{row?.custom_possible_action?.markdowns.map(
																		(m) => (
																			<Fragment key={m}>
																				{Math.round(m * 100)}%<br />
																			</Fragment>
																		)
																	)}
																</>
															}
														>
															<span className="flex items-center space-x-1">
																<span>
																	{
																		row?.custom_possible_action?.markdowns
																			.length
																	}{' '}
																	values
																</span>
																<InfoIcon className="inline-block text-ca-gray h-3.5" />
															</span>
														</Tooltip>
													);
												}
											}
											if (columnId === 'action_type') {
												if (row?.[columnId] === 'Custom_minmax')
													return 'Min/max. discount';
												if (row?.[columnId] === 'Custom_min_change')
													return 'Minimal change discount';
												if (row?.[columnId] === 'Custom_max_increase')
													return 'Maximal increase discount';
												if (row?.[columnId] === 'Custom_fixed')
													return 'Fixed discount';
												if (row?.[columnId] === 'Custom_possible')
													return 'Possible discounts';
												if (row?.[columnId] === 'Custom_average')
													return 'Average discount';
												if (row?.[columnId] === 'Custom_distribution')
													return 'Discount distribution';
												if (row?.[columnId] === 'Global')
													return 'Built-in rules';

												return '';
											}

											if (columnId === 'nr_applied') {
												if (isSelected && !row?.nr_applied) return 0;
												return row?.nr_applied;
											}

											if (columnId === 'nr_violated') {
												if (isSelected && !row?.nr_violated) return 0;
												return row?.nr_violated;
											}

											if (columnId === 'br_type') {
												if (
													row?.action_type === 'Custom_distribution' ||
													row?.action_type === 'Custom_average'
												) {
													return 'Group';
												}
												return 'Product';
											}

											const rowValue = get(row, columnId);

											if (typeof rowValue === 'number') {
												return `${rowValue}%`;
											}

											return rowValue?.toString();
										}}
									/>
								)}
							/>
						</div>
						<div className="mt-6 space-x-5">
							<Button type="submit" disabled={isEditLoading || isEditSuccess}>
								Save
							</Button>
							<Button variant="link" onClick={() => history.push(returnUrl)}>
								Cancel
							</Button>
						</div>
					</form>
				)}
			</div>
		</>
	);
};

export default StrategyComponentsEdit;
