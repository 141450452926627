import { ChangeEvent, FunctionComponent } from 'react';
import { Control, Controller, UseFieldArrayRemove } from 'react-hook-form';
import IconButton from '../../../shared/components/IconButton/IconButton';
import Cross from '../../../shared/components/Icons/Cross';
import TextInput from '../../../shared/components/TextInput/TextInput';
import {
	MaximumSKUAmountConstraint,
	Configuration,
	Constraint,
	ConstraintType,
	CooldownPeriodConstraint,
	DoNotBreakSizeChartConstraint,
	MaximumShipmentAmountConstraint,
	MaximumVolumeMovedConstraint,
	MinimumProductAmountConstraint,
	MinimumROIConstraint,
	MinimumShipmentAmountConstraint,
	OnlyRestoreSizeChartsConstraint,
} from '../../../shared/models/configuration';
import ScopeDescription from './ScopeDescription';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';

type WithIds<Type> = Type & {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
};

type BaseFieldsetProps<C> = C & {
	control: Control<Configuration>;
	remove: UseFieldArrayRemove;
	index: number;
};

interface Props {
	constraint: Constraint;
	control: Control<Configuration>;
	remove: UseFieldArrayRemove;
	index: number;
}

const MaximumVolumeMovedConstraintFieldset: FunctionComponent<
	WithIds<BaseFieldsetProps<MaximumVolumeMovedConstraint>>
> = ({ maxMovementCount, scope, index, control, remove, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<div className="flex gap-4 justify-between">
			<p className="text-ca-gray">
				Move no more than a total of{' '}
				<Controller
					name={`constraints.${index}.maxMovementCount`}
					control={control}
					rules={{ required: 'Required field', min: 1 }}
					render={({ field }) => (
						<TextInput
							type="number"
							className=""
							endAdornment={undefined}
							startAdornment={undefined}
							error=""
							id=""
							placeholder={maxMovementCount}
							size="regular"
							value={field.value}
							min={1}
							step={1}
							onChange={(event: ChangeEvent<HTMLInputElement>) =>
								field.onChange(parseInt(event.target.value, 10))
							}
						/>
					)}
				/>{' '}
				SKUs.
			</p>
			<IconButton
				onClick={() => remove(index)}
				icon={Cross}
				className="w-2.5"
			/>
		</div>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const MinimumShipmentAmountConstraintFieldset: FunctionComponent<
	WithIds<BaseFieldsetProps<MinimumShipmentAmountConstraint>>
> = ({ minimumShipmentAmount, scope, index, control, remove, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<div className="flex gap-4 justify-between">
			<p className="text-ca-gray">
				Don&apos;t create shipments with less than{' '}
				<Controller
					name={`constraints.${index}.minimumShipmentAmount`}
					control={control}
					rules={{ required: 'Required field', min: 1 }}
					render={({ field }) => (
						<TextInput
							type="number"
							className=""
							endAdornment={undefined}
							startAdornment={undefined}
							error=""
							id=""
							placeholder={minimumShipmentAmount}
							size="regular"
							value={field.value}
							min={1}
							step={1}
							onChange={(event: ChangeEvent<HTMLInputElement>) =>
								field.onChange(parseInt(event.target.value, 10))
							}
						/>
					)}
				/>{' '}
				SKUs.
			</p>
			<IconButton
				onClick={() => remove(index)}
				icon={Cross}
				className="w-2.5"
			/>
		</div>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const MaximumShipmentAmountConstraintFieldset: FunctionComponent<
	WithIds<BaseFieldsetProps<MaximumShipmentAmountConstraint>>
> = ({ maximumShipmentAmount, scope, index, control, remove, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<div className="flex gap-4 justify-between">
			<p className="text-ca-gray">
				Don&apos;t create shipments with more than{' '}
				<Controller
					name={`constraints.${index}.maximumShipmentAmount`}
					control={control}
					rules={{ required: 'Required field', min: 1 }}
					render={({ field }) => (
						<TextInput
							type="number"
							className=""
							endAdornment={undefined}
							startAdornment={undefined}
							error=""
							id=""
							placeholder={maximumShipmentAmount}
							size="regular"
							value={field.value}
							min={1}
							step={1}
							onChange={(event: ChangeEvent<HTMLInputElement>) =>
								field.onChange(parseInt(event.target.value, 10))
							}
						/>
					)}
				/>{' '}
				SKUs.
			</p>
			<IconButton
				onClick={() => remove(index)}
				icon={Cross}
				className="w-2.5"
			/>
		</div>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const DoNotBreakSizeChartConstraintFieldset: FunctionComponent<
	WithIds<BaseFieldsetProps<DoNotBreakSizeChartConstraint>>
> = ({ scope, remove, index, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<div className="flex gap-4 justify-between">
			<p className="text-ca-gray">Don&apos;t break size charts.</p>
			<IconButton
				onClick={() => remove(index)}
				icon={Cross}
				className="w-2.5"
			/>
		</div>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const CooldownPeriodConstraintFieldset: FunctionComponent<
	WithIds<BaseFieldsetProps<CooldownPeriodConstraint>>
> = ({ minCooldownDays, scope, remove, index, control, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<div className="flex gap-4 justify-between">
			<p className="text-ca-gray">
				Don&apos;t move products within{' '}
				<Controller
					name={`constraints.${index}.minCooldownDays`}
					control={control}
					rules={{ required: 'Required field', min: 1 }}
					render={({ field }) => (
						<TextInput
							type="number"
							className=""
							endAdornment={undefined}
							startAdornment={undefined}
							error=""
							id=""
							placeholder={minCooldownDays}
							size="regular"
							value={field.value}
							min={1}
							step={1}
							onChange={(event: ChangeEvent<HTMLInputElement>) =>
								field.onChange(parseInt(event.target.value, 10))
							}
						/>
					)}
				/>{' '}
				days after they have been delivered to a location.
			</p>
			<IconButton
				onClick={() => remove(index)}
				icon={Cross}
				className="w-2.5"
			/>
		</div>
		<ScopeDescription {...scope} {...ids} />
	</div>
);

const MinimumROIConstraintFieldset: FunctionComponent<
	WithIds<BaseFieldsetProps<MinimumROIConstraint>>
> = ({ minimumROI, scope, control, remove, index, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<div className="flex gap-4 justify-between">
			<p className="text-ca-gray">
				Don&apos;t move products unless we benefit at least{' '}
				<Controller
					name={`constraints.${index}.minimumROI`}
					control={control}
					rules={{ required: 'Required field', min: 0.01 }}
					render={({ field }) => (
						<TextInput
							type="number"
							className=""
							endAdornment={undefined}
							startAdornment="€"
							error=""
							id=""
							placeholder={minimumROI}
							size="regular"
							value={field.value}
							min={0.01}
							step={0.01}
							onChange={(event: ChangeEvent<HTMLInputElement>) =>
								field.onChange(parseFloat(event.target.value))
							}
						/>
					)}
				/>{' '}
				per SKU.
			</p>
			<IconButton
				onClick={() => remove(index)}
				icon={Cross}
				className="w-2.5"
			/>
		</div>

		<ScopeDescription {...scope} {...ids} />
	</div>
);

const MaximumSKUAmountConstraintFieldset: FunctionComponent<
	WithIds<BaseFieldsetProps<MaximumSKUAmountConstraint>>
> = ({ scope, remove, index, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<div className="flex gap-4 justify-between">
			<p className="text-ca-gray">
				Limit the maximum allowed number of units per SKU per location. These
				limits have been predefined.
			</p>
			<IconButton
				onClick={() => remove(index)}
				icon={Cross}
				className="w-2.5"
			/>
		</div>

		<ScopeDescription {...scope} {...ids} />
	</div>
);

const OnlyRestoreSizeChartsConstraintFieldset: FunctionComponent<
	WithIds<BaseFieldsetProps<OnlyRestoreSizeChartsConstraint>>
> = ({ scope, remove, index, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<div className="flex gap-4 justify-between">
			<p className="text-ca-gray">
				Only allow moves which can repair broken size charts.
			</p>
			<IconButton
				onClick={() => remove(index)}
				icon={Cross}
				className="w-2.5"
			/>
		</div>

		<ScopeDescription {...scope} {...ids} />
	</div>
);

const MinimumProductAmountFieldset: FunctionComponent<
	WithIds<BaseFieldsetProps<MinimumProductAmountConstraint>>
> = ({ scope, remove, index, control, minimumProductAmount, ...ids }) => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg w-full">
		<div className="flex gap-4 justify-between">
			<p className="text-ca-gray">
				Require a minimum of{' '}
				<Controller
					name={`constraints.${index}.minimumProductAmount`}
					control={control}
					rules={{ required: 'Required field', min: 1 }}
					render={({ field }) => (
						<TextInput
							type="number"
							className=""
							endAdornment={undefined}
							startAdornment={undefined}
							error=""
							id=""
							placeholder={minimumProductAmount}
							size="regular"
							value={field.value}
							min={1}
							step={1}
							onChange={(event: ChangeEvent<HTMLInputElement>) =>
								field.onChange(parseInt(event.target.value, 10))
							}
						/>
					)}
				/>{' '}
				units per product per location.
			</p>
			<IconButton
				onClick={() => remove(index)}
				icon={Cross}
				className="w-2.5"
			/>
		</div>

		<ScopeDescription {...scope} {...ids} />
	</div>
);

const ConstraintFieldset: FunctionComponent<WithIds<Props>> = ({
	constraint,
	...rest
}) => {
	const renderConstraintFieldset = () => {
		switch (constraint.type) {
			case ConstraintType.MinimumProductAmount:
				return <MinimumProductAmountFieldset {...constraint} {...rest} />;

			case ConstraintType.MaximumVolumeMoved:
				return (
					<MaximumVolumeMovedConstraintFieldset {...constraint} {...rest} />
				);
			case ConstraintType.MinimumShipmentAmount:
				return (
					<MinimumShipmentAmountConstraintFieldset {...constraint} {...rest} />
				);
			case ConstraintType.MaximumShipmentAmount:
				return (
					<MaximumShipmentAmountConstraintFieldset {...constraint} {...rest} />
				);
			case ConstraintType.DoNotBreakSizeChart:
				return (
					<DoNotBreakSizeChartConstraintFieldset {...constraint} {...rest} />
				);
			case ConstraintType.CooldownPeriod:
				return <CooldownPeriodConstraintFieldset {...constraint} {...rest} />;
			case ConstraintType.MinimumROI:
				return <MinimumROIConstraintFieldset {...constraint} {...rest} />;
			case ConstraintType.MaximumSKUAmount:
				return <MaximumSKUAmountConstraintFieldset {...constraint} {...rest} />;
			case ConstraintType.OnlyRestoreSizeCharts:
				return (
					<OnlyRestoreSizeChartsConstraintFieldset {...constraint} {...rest} />
				);
			default:
				return null;
		}
	};

	return (
		<div className="flex gap-3 items-center">{renderConstraintFieldset()}</div>
	);
};

export default ConstraintFieldset;
