import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import dayjs from 'dayjs';

import {
	DELETE_BUSINESS_RULE,
	GET_BUSINESS_RULES_PRODUCT_LEVEL,
	GET_BUSINESS_RULES_ASSORTMENT_LEVEL,
	CREATE_BUSINESS_RULE,
	UPDATE_BUSINESS_RULES_PRIORITIES_PRODUCT_LEVEL,
	UPDATE_BUSINESS_RULES_PRIORITIES_ASSORTMENT_LEVEL,
} from '../../../shared/api/business-rules';

import get from '../../../shared/utils/get';
import pluralise from '../../../shared/utils/pluralise';
import useModal from '../../../shared/hooks/useModal';
import useChannelQuery from '../../../shared/hooks/useChannelQuery';

import Modal from '../../../shared/components/Modal/Modal';
import Button from '../../../shared/components/Button/Button';
import IconButton from '../../../shared/components/IconButton/IconButton';
import Table from '../../../shared/components/Table/Table';
import Tag from '../../../shared/components/Tag/Tag';
import Text from '../../../shared/components/Text/Text';
import Title from '../../../shared/components/Title/Title';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';

import EditIcon from '../../../shared/components/Icons/Edit';
import InfoIcon from '../../../shared/components/Icons/Info';
import DuplicateIcon from '../../../shared/components/Icons/Duplicate';
import TrashIcon from '../../../shared/components/Icons/Trash';
import CheckmarkIcon from '../../../shared/components/Icons/Checkmark';
import CrossIcon from '../../../shared/components/Icons/Cross';
import LinearProgress from '../../../shared/components/Progress/LinearProgress';

const HEADINGS = [
	{ id: 'dragdrop', label: '', align: 'center' },
	{ id: 'title', label: 'Name' },
	{ id: 'action_type', label: 'Type' },
	{ id: 'tag', label: 'Tag' },
	{ id: 'discount', label: 'Discount' },
	{ id: 'date_added', label: 'Date added' },
	{ id: 'in_use', label: 'In use' },
	{ id: 'actions', label: '', align: 'right' },
];

const BusinessRulesOverview = () => {
	const history = useHistory();
	const { open, close } = useModal();
	const queryClient = useQueryClient();

	const {
		isLoading: isLoadingProductLevel,
		data: dataProductLevel,
		queryKey: queryKeyProductLevel,
		refetch: refetchProductLevel,
	} = useChannelQuery(
		['business-rules', 'product_level'],
		GET_BUSINESS_RULES_PRODUCT_LEVEL,
		{
			staleTime: 5 * 60 * 1000,
		}
	);

	const {
		isLoading: isLoadingAssortmentLevel,
		data: dataAssortmentLevel,
		queryKey: queryKeyAssortmentLevel,
		refetch: refetchAssortmentLevel,
	} = useChannelQuery(
		['business-rules', 'assortment_level'],
		GET_BUSINESS_RULES_ASSORTMENT_LEVEL,
		{
			staleTime: 5 * 60 * 1000,
		}
	);

	const queryKey = queryKeyProductLevel.slice(
		0,
		queryKeyProductLevel.length - 1
	);
	const refetch = () => {
		refetchAssortmentLevel();
		refetchProductLevel();
	};

	const { isLoading: isRemoveLoading, mutate: remove } = useMutation(
		(id) => DELETE_BUSINESS_RULE(id),
		{
			onMutate: async (idToRemove) => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(queryKey);

				// Snapshot the previous value
				const productLevelPrevious =
					queryClient.getQueryData(queryKeyProductLevel);
				const assortmentLevelPrevious = queryClient.getQueryData(
					queryKeyAssortmentLevel
				);

				// Optimistically update to the new value
				queryClient.setQueryData(queryKeyProductLevel, (old) => ({
					...old,
					items: old.items.filter(({ id }) => id !== idToRemove),
					total: old.total - 1,
				}));
				queryClient.setQueryData(queryKeyAssortmentLevel, (old) => ({
					...old,
					items: old.items.filter(({ id }) => id !== idToRemove),
					total: old.total - 1,
				}));

				// Return a context object with the snapshotted value
				return { productLevelPrevious, assortmentLevelPrevious };
			},
			// If the mutation fails, use the context returned from onMutate to roll back
			onError: (err, idToRemove, context) => {
				queryClient.setQueryData(
					queryKeyProductLevel,
					context.productLevelPrevious
				);
				queryClient.setQueryData(
					queryKeyAssortmentLevel,
					context.assortmentLevelPrevious
				);
			},
			// Always refetch after error or success:
			onSettled: () => {
				queryClient.invalidateQueries(queryKey);
			},
		}
	);

	const { isLoading: isDuplicateLoading, mutate: duplicate } = useMutation(
		CREATE_BUSINESS_RULE,
		{
			onSuccess: refetch,
		}
	);

	const { mutate: prioritiseProductLevel } = useMutation(
		UPDATE_BUSINESS_RULES_PRIORITIES_PRODUCT_LEVEL,
		{
			onSuccess: refetch,
		}
	);

	const { mutate: prioritiseAssortmentLevel } = useMutation(
		UPDATE_BUSINESS_RULES_PRIORITIES_ASSORTMENT_LEVEL,
		{
			onSuccess: refetch,
		}
	);

	const handleDuplicate = (d) => {
		close();
		duplicate({
			action_type: d?.action_type,
			conditions: d?.conditions,
			custom_min_change_action: d?.custom_min_change_action,
			custom_min_change_action_id: d?.custom_min_change_action_id,
			custom_max_increase_action: d?.custom_max_increase_action,
			custom_max_increase_action_id: d?.custom_max_increase_action_id,
			custom_fixed_action: d?.custom_fixed_action,
			custom_fixed_action_id: d?.custom_fixed_action_id,
			custom_minmax_action: d?.custom_minmax_action,
			custom_minmax_action_id: d?.custom_minmax_action_id,
			custom_possible_action: d?.custom_possible_action,
			custom_possible_action_id: d?.custom_possible_action_id,
			custom_average_action: d?.custom_average_action,
			custom_average_action_id: d?.custom_average_action_id,
			custom_distribution_action: d?.custom_distribution_action,
			custom_distribution_action_id: d?.custom_distribution_action_id,
			global_action_ids: d?.global_actions?.map(({ id: aId }) => aId),
			tag_id: d?.tag_id,
			tag: d?.tag,
			title: `Copy of ${d?.title}`,
		});
	};

	const handleDelete = (id) => {
		close();
		remove(id);
	};

	const renderCell = (row, columnId) => {
		if (columnId === 'actions') {
			return (
				<>
					<IconButton
						disabled={isRemoveLoading || isDuplicateLoading}
						tooltip="Duplicate"
						icon={DuplicateIcon}
						onClick={() => handleDuplicate(row)}
					/>
					<IconButton
						disabled={isRemoveLoading}
						tooltip="Edit"
						icon={EditIcon}
						onClick={() =>
							history.push(`/strategy/business-rules/${row?.id}/edit`)
						}
					/>
					<IconButton
						disabled={row?.in_use || isRemoveLoading}
						tooltip={
							row?.in_use ? 'Rule is in use and cannot be deleted' : 'Delete'
						}
						icon={TrashIcon}
						onClick={() =>
							open(
								<Modal.Root>
									<Modal.Content>
										<Modal.Title>Delete business rule</Modal.Title>
										<div className="space-y-4">
											<Text>Are you sure you want to delete this rule?</Text>
											<Text>
												Title: <strong>{row?.title}</strong> <br />
												{row?.tag && (
													<>
														Tag: <strong>{row?.tag?.name}</strong>
														<br />
													</>
												)}
												Type:{' '}
												<strong>
													{row?.action_type === 'Custom_minmax' &&
														'Min/max. discount'}
													{row?.action_type === 'Custom_min_change' &&
														'Minimal change discount'}
													{row?.action_type === 'Custom_max_increase' &&
														'Maximal increase discount'}
													{row?.action_type === 'Custom_fixed' &&
														'Fixed discount'}
													{row?.action_type === 'Custom_possible' &&
														'Possible discounts'}
													{row?.action_type === 'Custom_average' &&
														'Average discount'}
													{row?.action_type === 'Custom_distribution' &&
														'Discount distribution'}
													{row?.action_type === 'Global' && 'Built-in rules'}
												</strong>
												<br />
												{row?.action_type === 'Custom_minmax' &&
													!Number.isNaN(
														parseFloat(
															row?.custom_minmax_action?.min_discount,
															10
														)
													) && (
														<>
															Min. discount:{' '}
															<strong>
																{`${(
																	row?.custom_minmax_action?.min_discount * 100
																).toFixed(0)}%`}
															</strong>
															<br />
														</>
													)}
												{row?.action_type === 'Custom_minmax' &&
													!Number.isNaN(
														parseFloat(
															row?.custom_minmax_action?.max_discount,
															10
														)
													) && (
														<>
															Max. discount:{' '}
															<strong>
																{`${(
																	row?.custom_minmax_action?.max_discount * 100
																).toFixed(0)}%`}
															</strong>
															<br />
														</>
													)}
												{row?.action_type === 'Custom_min_change' && (
													<>
														Minimal change discount:{' '}
														<strong>
															{`${(
																row?.custom_min_change_action
																	?.min_change_discount * 100
															).toFixed(0)}%`}
														</strong>
														<br />
													</>
												)}
												{row?.action_type === 'Custom_max_increase' && (
													<>
														Mmaximal increase discount:{' '}
														<strong>
															{`${(
																row?.custom_max_increase_action
																	?.max_increase_discount * 100
															).toFixed(0)}%`}
														</strong>
														<br />
													</>
												)}
												{row?.action_type === 'Custom_fixed' && (
													<>
														Fixed discount:{' '}
														<strong>
															{`${(
																row?.custom_fixed_action?.fixed_discount * 100
															).toFixed(0)}%`}
														</strong>
														<br />
													</>
												)}
												{row?.action_type === 'Custom_average' && (
													<>
														Average discount:{' '}
														<strong>
															{`${(
																row?.custom_average_action?.average_discount *
																100
															).toFixed(0)}%`}
														</strong>
														<br />
													</>
												)}
												{row?.action_type === 'Custom_distribution' && (
													<>
														Discount distribution:{' '}
														<strong>
															<>
																<span className="capitalize">
																	{row?.custom_distribution_action?.limiter}
																</span>{' '}
																<span>
																	{(
																		row?.custom_distribution_action
																			?.distribution * 100
																	).toFixed(0)}
																	%
																</span>
																{' of '}
																<span>
																	{row?.custom_distribution_action?.type}
																</span>
																{' should be discounted at '}
																<span>
																	{(
																		row?.custom_distribution_action?.discount *
																		100
																	).toFixed(0)}
																	%
																</span>
															</>
														</strong>
														<br />
													</>
												)}
												{row?.action_type === 'Custom_possible' && (
													<>
														Possible discounts:{' '}
														<strong>
															{row?.custom_possible_action?.markdowns
																.map((m) => `${Math.round(m * 100)}%`)
																.join(', ')}
														</strong>
														<br />
													</>
												)}
												Date added:{' '}
												<strong>
													{dayjs(row?.date_added).format('DD/MM/YYYY')}
												</strong>
												<br />
											</Text>
											<Text className="italic">
												This action is irreversible. Please proceed with
												caution.
											</Text>
										</div>
									</Modal.Content>
									<Modal.Actions>
										<div className="flex justify-between">
											<Button variant="secondary" onClick={close}>
												Cancel
											</Button>
											<Button
												variant="danger"
												onClick={() => handleDelete(row.id)}
											>
												Delete
											</Button>
										</div>
									</Modal.Actions>
								</Modal.Root>
							)
						}
					/>
				</>
			);
		}

		if (columnId === 'discount') {
			if (row?.action_type === 'Custom_fixed') {
				return `Fixed ${(
					row?.custom_fixed_action?.fixed_discount * 100
				).toFixed(0)}%`;
			}
			if (row?.action_type === 'Custom_min_change') {
				return `Minimal change ${(
					row?.custom_min_change_action?.min_change_discount * 100
				).toFixed(0)}%`;
			}
			if (row?.action_type === 'Custom_max_increase') {
				return `Maximal increase ${(
					row?.custom_max_increase_action?.max_increase_discount * 100
				).toFixed(0)}%`;
			}

			if (row?.action_type === 'Custom_average') {
				return `Average ${(
					row?.custom_average_action?.average_discount * 100
				).toFixed(0)}%`;
			}

			if (row?.action_type === 'Custom_distribution') {
				return (
					<Tooltip
						content={
							<>
								<span className="capitalize">
									{row?.custom_distribution_action?.limiter}
								</span>{' '}
								<span>
									{(
										row?.custom_distribution_action?.distribution * 100
									).toFixed(0)}
									%
								</span>
								{' of '}
								<span>{row?.custom_distribution_action?.type}</span>
								{' should be discounted at '}
								<span>
									{(row?.custom_distribution_action?.discount * 100).toFixed(0)}
									%
								</span>
							</>
						}
					>
						<span className="flex items-center space-x-1">
							<span>Discount distribution</span>
							<InfoIcon className="inline-block text-ca-gray h-3.5" />
						</span>
					</Tooltip>
				);
			}

			if (row?.action_type === 'Custom_minmax') {
				const output = [];

				if (
					!Number.isNaN(parseFloat(row?.custom_minmax_action?.min_discount, 10))
				) {
					output.push(
						`Min. ${(row?.custom_minmax_action?.min_discount * 100).toFixed(
							0
						)}%`
					);
				}

				if (
					!Number.isNaN(parseFloat(row?.custom_minmax_action?.max_discount, 10))
				) {
					output.push(
						`Max. ${(row?.custom_minmax_action?.max_discount * 100).toFixed(
							0
						)}%`
					);
				}

				return output.join(', ');
			}

			if (row?.action_type === 'Custom_possible') {
				return (
					<Tooltip
						content={
							<>
								{row?.custom_possible_action?.markdowns.map((m) => (
									<Fragment key={m}>
										{Math.round(m * 100)}%<br />
									</Fragment>
								))}
							</>
						}
					>
						<span className="flex items-center space-x-1">
							<span>
								{pluralise(
									row?.custom_possible_action?.markdowns.length,
									'value',
									'values'
								)}
							</span>
							<InfoIcon className="inline-block text-ca-gray h-3.5" />
						</span>
					</Tooltip>
				);
			}
		}

		if (columnId === 'action_type') {
			if (row?.[columnId] === 'Custom_minmax') return 'Min/max. discount';
			if (row?.[columnId] === 'Custom_fixed') return 'Fixed discount';
			if (row?.[columnId] === 'Custom_min_change')
				return 'Minimal change discount';
			if (row?.[columnId] === 'Custom_max_increase')
				return 'Maximal increase discount';
			if (row?.[columnId] === 'Custom_possible') return 'Possible discounts';
			if (row?.[columnId] === 'Custom_average') return 'Average discount';
			if (row?.[columnId] === 'Custom_distribution')
				return 'Discount distribution';
			if (row?.[columnId] === 'Global') return 'Built-in rules';

			return '';
		}

		const rowValue = get(row, columnId);

		if (columnId === 'tag') {
			return rowValue ? <Tag label={rowValue} /> : null;
		}

		if (dayjs.isDayjs(rowValue)) {
			return rowValue.format('DD/MM/YYYY');
		}

		if (columnId === 'in_use') {
			return rowValue ? (
				<CheckmarkIcon className="h-2 text-ca-green" />
			) : (
				<CrossIcon className="h-2 text-ca-red" />
			);
		}

		return rowValue?.toString();
	};

	return (
		<>
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress
					visible={
						isLoadingProductLevel ||
						isLoadingAssortmentLevel ||
						isRemoveLoading ||
						isDuplicateLoading
					}
				/>
			</div>
			<div className="py-6">
				<div className="space-y-6">
					<Button
						size="small"
						onClick={() => history.push('/strategy/business-rules/create')}
					>
						Add rule
					</Button>
					<div className="space-y-3">
						<Title className="text-ca-purple font-bold" type="section">
							<span>Product level</span>
						</Title>
						<Text type="secondary" size="xs">
							These are strict rules applied to individual products.
							<br />
							The business rule at the top of the table has the highest priority
							and will always be applied. Rules that are lower in the table will
							only be applied if they do not conflict with prior rules.
						</Text>
					</div>
					<Table
						loading={isLoadingProductLevel}
						itemsLoading={5}
						emptyState="There are currently no product level business rules. Click the button above to create one."
						headings={HEADINGS}
						rows={dataProductLevel?.items || []}
						onDragChange={prioritiseProductLevel}
						renderCell={renderCell}
					/>
					<div className="space-y-3">
						<Title className="text-ca-purple font-bold" type="section">
							<span>Group level</span>
						</Title>
						<Text type="secondary" size="xs">
							These are flexible guidelines applied to a group of products.
						</Text>
					</div>
					<Table
						loading={isLoadingAssortmentLevel}
						itemsLoading={5}
						emptyState="There are currently no group level business rules. Click the button above to create one."
						headings={HEADINGS}
						rows={dataAssortmentLevel?.items || []}
						onDragChange={prioritiseAssortmentLevel}
						renderCell={renderCell}
					/>
				</div>
			</div>
		</>
	);
};

export default BusinessRulesOverview;
