import useStrategyStore from '../../data/strategies';

import Dropdown from '../Dropdown/Dropdown';

const StrategySelector = () => {
	const { strategies, activeStrategy, setActiveStrategy } = useStrategyStore();

	const options = strategies.map(({ slug, name }) => ({
		value: slug,
		label: name,
	}));

	const handleStrategyChange = (strategy) => {
		setActiveStrategy(strategy);
	};

	return (
		<Dropdown
			onChange={handleStrategyChange}
			value={activeStrategy}
			options={options}
			className="w-64"
		/>
	);
};

export default StrategySelector;
