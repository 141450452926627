import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import {
	DELETE_CATEGORY,
	GET_CATEGORIES,
	UPDATE_CATEGORIES_PRIORITIES,
} from '../../../shared/api/categories';

import useModal from '../../../shared/hooks/useModal';
import useChannelQuery from '../../../shared/hooks/useChannelQuery';

import Modal from '../../../shared/components/Modal/Modal';
import Button from '../../../shared/components/Button/Button';
import IconButton from '../../../shared/components/IconButton/IconButton';
import Table from '../../../shared/components/Table/Table';
import Text from '../../../shared/components/Text/Text';
import LinearProgress from '../../../shared/components/Progress/LinearProgress';
import EditIcon from '../../../shared/components/Icons/Edit';
import TrashIcon from '../../../shared/components/Icons/Trash';
import CircularProgress from '../../../shared/components/Progress/CircularProgress';

const HEADINGS = [
	{ id: 'dragdrop', label: '', align: 'left' },
	{ id: 'name', label: 'Name', align: 'center' },
	{ id: 'category_products_count', label: 'Products', align: 'center' },
	{ id: 'end_period', label: 'End of markdown period', align: 'center' },
	{ id: 'actions', label: '', align: 'right' },
];

const ProductCategoriesOverview = () => {
	const history = useHistory();
	const { open, close } = useModal();
	const queryClient = useQueryClient();

	const { isFetching, isLoading, data, refetch, dataUpdatedAt, queryKey } =
		useChannelQuery('categories', GET_CATEGORIES, {
			staleTime: 5 * 60 * 1000,
		});

	const { isLoading: isRemoveLoading, mutate: remove } = useMutation(
		DELETE_CATEGORY,
		{
			onMutate: async (idToRemove) => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(queryKey);

				// Snapshot the previous value
				const previousCategories = queryClient.getQueryData(queryKey);

				// Optimistically update to the new value
				queryClient.setQueryData(queryKey, (old) => ({
					...old,
					items: old.items.filter(({ id }) => id !== idToRemove),
					total: old.total - 1,
				}));

				// Return a context object with the snapshotted value
				return { previousCategories };
			},
			// If the mutation fails, use the context returned from onMutate to roll back
			onError: (err, idToRemove, context) => {
				queryClient.setQueryData(queryKey, context.previousCategories);
			},
			// Always refetch after error or success:
			onSettled: () => {
				queryClient.invalidateQueries(queryKey);
			},
		}
	);

	const { mutate: prioritise } = useMutation(UPDATE_CATEGORIES_PRIORITIES, {
		onSuccess: refetch,
		onError: refetch,
	});

	const handleDelete = (id) => {
		close();
		remove(id);
	};

	return (
		<>
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress visible={isLoading || isRemoveLoading} />
			</div>

			<div className="py-6 space-y-6">
				<Button
					size="small"
					onClick={() => history.push('/strategy/categories/create')}
				>
					Add category
				</Button>
				<Table
					loading={isLoading}
					itemsLoading={5}
					headings={HEADINGS}
					rows={data?.items}
					onDragChange={prioritise}
					dragDisabled={data?.items
						?.map(({ changeable }, index) => changeable === true || index)
						?.filter((index) => !Number.isNaN(parseInt(index, 10)))}
					key={dataUpdatedAt}
					renderCell={(row, columnId) => {
						if (columnId === 'category_products_count') {
							return isFetching ? (
								<div className="relative">
									<span className="absolute left-0 top-50 transform -translate-y-1/2 -translate-x-1/4">
										<CircularProgress size="small" />
									</span>
								</div>
							) : (
								row?.[columnId]
							);
						}

						if (columnId === 'actions') {
							return (
								<>
									<IconButton
										disabled={isRemoveLoading}
										tooltip="Edit"
										icon={EditIcon}
										onClick={() =>
											history.push(`/strategy/categories/${row?.id}/edit`)
										}
									/>
									<IconButton
										disabled={!row.changeable || isRemoveLoading}
										tooltip={
											row.name === 'Default'
												? 'The default category cannot be deleted'
												: 'Delete'
										}
										icon={TrashIcon}
										onClick={() =>
											open(
												<Modal.Root>
													<Modal.Content>
														<Modal.Title>Delete product category</Modal.Title>
														<div className="space-y-4">
															<Text>
																Are you sure you want to delete this category?
															</Text>
															<Text>
																Name: <strong>{row?.name}</strong> <br />
																Amount of products:{' '}
																<strong>{row?.category_products_count}</strong>
															</Text>
															<Text className="font-bold">
																The category will be removed from both the
																active & test strategies!
															</Text>
															<Text className="italic">
																This action is irreversible. Please proceed with
																caution.
															</Text>
														</div>
													</Modal.Content>
													<Modal.Actions>
														<div className="flex justify-between">
															<Button variant="secondary" onClick={close}>
																Cancel
															</Button>
															<Button
																variant="danger"
																onClick={() => handleDelete(row.id)}
															>
																Delete
															</Button>
														</div>
													</Modal.Actions>
												</Modal.Root>
											)
										}
									/>
								</>
							);
						}

						return row?.[columnId];
					}}
				/>
			</div>
		</>
	);
};

export default ProductCategoriesOverview;
