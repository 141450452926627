import { drop, factory } from '@mswjs/data';
import { faker } from '@faker-js/faker';
import dictionary from './dictionary';
import {
	ConstraintTypeDTO,
	ScopeTypeDTO,
	SecondaryObjectiveTypeDTO,
	MarketingActionTypeDTO,
	ReportStatusDTO,
	InventoryAllocationStatusDTO,
} from '../shared/models/schema';

const db = factory(dictionary);

export const rollbackDatabase = () => {
	drop(db);
	faker.seed(123);

	const allocation1 = db.inventoryAllocation.create({
		name: 'Empty configuration',
	});

	db.inventoryAllocation.update({
		where: { id: { equals: allocation1.id } },
		data: {
			submitted_report: db.report.create({
				status: ReportStatusDTO.Submitted,
				allocation_id: allocation1.id,
			}).id,
			status: InventoryAllocationStatusDTO.Submitted,
		},
	});

	const allocation2 = db.inventoryAllocation.create({
		name: 'Complex configuration',
	});

	db.inventoryAllocation.update({
		where: { id: { equals: allocation2.id } },
		data: {
			status: InventoryAllocationStatusDTO.Submitted,
			submitted_report: db.report.create({
				allocation_id: allocation2.id,
				constraints: [
					db.constraint.create({
						constraint_type: ConstraintTypeDTO.CooldownPeriod,
						cooldown_period_days: 10,
						scopes: [
							db.scope.create({
								scope_type: ScopeTypeDTO.ProductScope,
								products_to_include: [
									db.product.create().id,
									db.product.create().id,
									db.product.create().id,
								],
							}),
						],
					}),
					db.constraint.create({
						constraint_type: ConstraintTypeDTO.DoNotBreakSizeCharts,
					}),
					db.constraint.create({
						constraint_type: ConstraintTypeDTO.MaximumVolumeMoved,
						maximum_number_of_movements: 5,
					}),
					db.constraint.create({
						constraint_type: ConstraintTypeDTO.MinimumROI,
						minimum_roi: 1.0,
					}),
				],
				secondary_objectives: [
					db.secondaryObjective.create({
						secondary_objective_type:
							SecondaryObjectiveTypeDTO.FixBrokenSizeChart,
					}),
					db.secondaryObjective.create({
						secondary_objective_type:
							SecondaryObjectiveTypeDTO.PenalizeBrokenSizeCharts,
					}),
				],
				marketing_actions: [
					db.marketingAction.create({
						marketing_action_type: MarketingActionTypeDTO.IncreaseDemand,
						name_marketing_action: 'Nike promotional event',
						expected_sales_uplift: 3.0,
					}),
				],
				status: ReportStatusDTO.Submitted,
			}).id,
		},
	});

	const allocation3 = db.inventoryAllocation.create();

	db.inventoryAllocation.update({
		where: { id: { equals: allocation3.id } },
		data: {
			report_ids: [
				db.report.create({
					name: 'Draft 1',
					status: ReportStatusDTO.Proposal,
					allocation_id: allocation3.id,
				}).id,
				db.report.create({
					name: 'Draft 2',
					allocation_id: allocation3.id,
					status: ReportStatusDTO.InProgress,
					constraints: [
						db.constraint.create({
							constraint_type: ConstraintTypeDTO.CooldownPeriod,
							cooldown_period_days: 10,
							scopes: [
								db.scope.create({
									scope_type: ScopeTypeDTO.ProductScope,
									products_to_include: [
										db.product.create().id,
										db.product.create().id,
										db.product.create().id,
									],
								}),
							],
						}),
					],
				}).id,
				db.report.create({
					name: 'Draft 3',
					status: ReportStatusDTO.Draft,
					allocation_id: allocation3.id,
				}).id,
			],
		},
	});

	const allocation4 = db.inventoryAllocation.create({
		report_ids: [
			db.report.create({ name: 'Draft 1' }).id,
			db.report.create({ name: 'Draft 2' }).id,
			db.report.create({ name: 'Draft 3' }).id,
			db.report.create({ name: 'Draft 4' }).id,
		],
	});

	db.inventoryAllocation.update({
		where: { id: { equals: allocation4.id } },
		data: {
			report_ids: [
				db.report.create({ name: 'Draft 1', allocation_id: allocation4.id }).id,
				db.report.create({ name: 'Draft 2', allocation_id: allocation4.id }).id,
				db.report.create({ name: 'Draft 3', allocation_id: allocation4.id }).id,
				db.report.create({ name: 'Draft 4', allocation_id: allocation4.id }).id,
			],
		},
	});

	[...Array(100)].forEach(db.product.create);
	[...Array(20)].forEach(db.location.create);

	db.locationColumn.create();
	db.locationColumn.create();
	db.productColumn.create();
	db.productColumn.create();
};

rollbackDatabase();

export default db;
