import { FunctionComponent } from 'react';

import Sidebar, { SidebarLinkType } from '../Sidebar/Sidebar';
import ActualsIcon from '../Icons/Actual';
import BarChartIcon from '../Icons/BarChart';
import ExperimentIcon from '../Icons/Experiment';
import StrategyIcon from '../Icons/Strategy';
import ValidationIcon from '../Icons/Validation';

const STRATOS_SIDEBAR_ITEMS: SidebarLinkType[] = [
	{
		id: 'actuals',
		label: 'Actuals',
		to: '/actuals',
		icon: <ActualsIcon />,
		isEnabled: window._ENV_.REACT_APP_ACTUALS_ENABLED,
	},
	{
		id: 'strategy',
		label: 'Strategy',
		to: '/strategy',
		icon: <StrategyIcon />,
		isEnabled: window._ENV_.REACT_APP_STRATEGY_ENABLED,
	},
	{
		id: 'opportunities',
		label: 'Opportunities',
		to: '/opportunities',
		icon: <BarChartIcon />,
		isEnabled: window._ENV_.REACT_APP_OPPORTUNITIES_ENABLED,
	},
	{
		id: 'scenario',
		label: 'Scenario',
		to: '/scenario',
		icon: <ExperimentIcon />,
		isEnabled: window._ENV_.REACT_APP_SCENARIO_ENABLED,
	},
	{
		id: 'support',
		label: 'Data Validation',
		to: '/support',
		icon: <ValidationIcon />,
		isEnabled: false,
	},
	{
		id: 'global',
		label: 'Global',
		to: '/global',
		icon: <ActualsIcon />,
		isEnabled: false,
	},
];

const StratosSidebar: FunctionComponent = () => {
	return <Sidebar activePlatform="Markdown" links={STRATOS_SIDEBAR_ITEMS} />;
};

export default StratosSidebar;
