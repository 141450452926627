import { GET_CUMULIO_ACTUALS_INVENTORY_SSO } from '../../shared/api/reports';
import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';

const ActualsInventory = () => {
	return (
		<FullCumulioDashboard
			queryKey="cumulio-actuals-inventory"
			ssoQuery={GET_CUMULIO_ACTUALS_INVENTORY_SSO}
		/>
	);
};

export default ActualsInventory;
