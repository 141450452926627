import { useRef } from 'react';
import { GET_CUMULIO_OPPORTUNITIES_OVERVIEW_SSO } from '../../shared/api/reports';
import useStrategyStore from '../../shared/data/strategies';
import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';
import Button from '../../shared/components/Button/Button';
import useMarkdownTransferModal from '../hooks/useMarkdownTransferModal';
import RefreshCumulioDashboardButton from '../../shared/components/RefreshCumulioDashboardButton/RefreshCumulioDashboardButton';
import LoadingSpinner from '../../shared/components/LoadingSpinner/LoadingSpinner';

const OpportunitiesSummaryView = () => {
	const { activeStrategy } = useStrategyStore();
	const cumulioRef = useRef(null);
	const {
		isPossible: transferIsPossible,
		transferInProgress,
		openModal: openTransferModal,
	} = useMarkdownTransferModal(cumulioRef);

	return (
		<>
			{transferIsPossible && (
				<div className="flex justify-end items-end mt-4 ml-[auto] gap-2">
					<Button
						variant="primary"
						size="small"
						disabled={transferInProgress}
						onClick={openTransferModal}
					>
						<span className="h-3.5 inline-flex items-center">
							{transferInProgress ? (
								<div>
									<span>Transfering...</span>
									<LoadingSpinner variant="sm" />
								</div>
							) : (
								<span>Transfer markdowns</span>
							)}
						</span>
					</Button>
					<RefreshCumulioDashboardButton dashboardRef={cumulioRef} />
				</div>
			)}
			<FullCumulioDashboard
				ref={cumulioRef}
				queryKey={[activeStrategy, 'cumulio-opportunities-overview']}
				ssoQuery={() => GET_CUMULIO_OPPORTUNITIES_OVERVIEW_SSO(activeStrategy)}
				noRefreshButton
			/>
		</>
	);
};

export default OpportunitiesSummaryView;
