import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Nav from '../../shared/components/Nav/Nav';

const NAV_ITEMS = [
	{
		id: 'summary',
		label: 'Summary',
		to: '/opportunities/summary',
	},
	{
		id: 'details',
		label: 'Details',
		to: '/opportunities/details',
	},
];

const OpportunitiesNav = () => {
	const { pathname } = useLocation();

	let navItems = NAV_ITEMS;
	// global mode
	if (pathname.startsWith('/opportunities/global')) {
		navItems = [
			{
				id: 'global_retrospective',
				label: 'Retrospective',
				to: '/opportunities/global/retrospective',
			},
			{
				id: 'global_summary',
				label: 'Summary',
				to: '/opportunities/global/summary',
			},
		];
	}

	const match = useMemo(
		() => navItems.find((item) => pathname.startsWith(item.to)),
		[pathname]
	);

	return <Nav active={match?.id} items={navItems} />;
};

export default OpportunitiesNav;
