import clsx from 'clsx';
import {
	ButtonHTMLAttributes,
	DetailedHTMLProps,
	forwardRef,
	ForwardRefExoticComponent,
	PropsWithoutRef,
	RefAttributes,
} from 'react';

type HTMLButtonProps = DetailedHTMLProps<
	ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
>;

interface Props extends Omit<HTMLButtonProps, 'onClick'> {
	variant?:
		| 'primary'
		| 'secondary'
		| 'info'
		| 'link'
		| 'danger'
		| 'success'
		| 'warning'
		| 'unstyled';
	size?: 'regular' | 'small';
	onClick?: () => void;
}

const Button: ForwardRefExoticComponent<
	PropsWithoutRef<Props> & RefAttributes<HTMLButtonElement>
> = forwardRef(
	(
		{
			variant = 'primary',
			type = 'button',
			size = 'regular',
			disabled,
			form,
			className,
			children,
			onClick = () => {},
			...rest
		}: Props,
		ref
	) => (
		<button
			ref={ref}
			className={clsx(
				'transition-colors leading-none focus:outline-none focus-visible:ring-4 focus-visible:ring-opacity-10 focus-visible:ring-ca-purple',
				disabled
					? 'cursor-not-allowed text-black bg-ca-gray opacity-40'
					: clsx(
							variant === 'primary' &&
								'font-bold text-white bg-ca-purple focus-visible:bg-ca-purple-a hover:bg-ca-purple-a active:bg-ca-purple-a',
							variant === 'secondary' &&
								'font-bold text-white bg-ca-gray focus-visible:bg-ca-gray-a hover:bg-ca-gray-a active:bg-ca-gray-a',
							variant === 'danger' &&
								'font-bold text-white bg-ca-red focus-visible:bg-ca-red-a hover:bg-ca-red-a active:bg-ca-red-a',
							variant === 'success' &&
								'font-bold text-white bg-ca-green focus-visible:bg-ca-green-a hover:bg-ca-green-a active:bg-ca-green-a',
							variant === 'warning' &&
								'font-bold text-white bg-ca-orange focus-visible:bg-ca-orange-a hover:bg-ca-orange-a active:bg-ca-orange-a',
							variant === 'link' && 'text-ca-purple hover:underline'
					  ),
				{
					'py-3 px-4 rounded-lg text-sm':
						size === 'regular' && variant !== 'unstyled',
					'py-2 px-3 rounded text-xxs':
						size === 'small' && variant !== 'unstyled',
				},
				className
			)}
			type={type}
			disabled={disabled}
			onClick={() => !disabled && onClick()}
			form={form}
			{...rest}
		>
			{children}
		</button>
	)
);

export default Button;
