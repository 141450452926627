import Title from '../../shared/components/Title/Title';
import Greeting from '../../shared/components/Greeting/Greeting';
import ChannelSelector from '../../shared/components/ChannelSelector/ChannelSelector';

import ActualsNav from './ActualsNav';

const ActualsHeader = () => (
	<>
		<div className="flex justify-between">
			<Title>Actuals</Title>
			<ChannelSelector />
		</div>
		<div className="my-2">
			<Greeting />
		</div>
		<div className="mt-6">
			<ActualsNav />
		</div>
	</>
);

export default ActualsHeader;
