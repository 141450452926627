import { Route } from 'react-router-dom';

import RouterSwitch from '../../shared/components/RouterSwitch/RouterSwitch';
import Page from '../../shared/components/Page/Page';
import Title from '../../shared/components/Title/Title';
import ChannelSelector from '../../shared/components/ChannelSelector/ChannelSelector';
import GlobalDashboard from './GlobalDashboard';

const GlobalView = () => {
	return (
		<Page>
			<div className="relative flex justify-between">
				<Title>Global</Title>
				<ChannelSelector />
			</div>
			<RouterSwitch>
				<Route exact path="/global" component={GlobalDashboard} />
			</RouterSwitch>
		</Page>
	);
};

export default GlobalView;
