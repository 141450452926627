import { useRef, useState, useEffect, useLayoutEffect } from 'react';
import clsx from 'clsx';
import TriangleDown from '../Icons/TriangleDown';

type AccordionProps = {
	/**
	 * The element that will open and close the accordion when clicked.
	 * Is wrapped inside a div which listens for the onClick events
	 */
	renderTrigger: () => React.ReactNode;
	/**
	 * Classname for the element that indicates if open or closed
	 */
	triangleClassName?: string;
	renderContent: () => React.ReactNode;
	startOpen: boolean;
};

const Accordion: React.FC<AccordionProps> = (props) => {
	const contentRef = useRef<HTMLDivElement | null>(null);
	const [open, setOpen] = useState<boolean>(true);
	const [openHeight, setOpenHeight] = useState<number | null>(null);

	/**
	 * Gets called before react renders
	 * useEffect() => single frame where 'open' is not set => full height
	 */
	useLayoutEffect(() => {
		if (contentRef.current) {
			// set 'openHeight' only once. At this point clientHeight is unaffected and acts as if it is open (aka it has its full height)
			if (openHeight === null) {
				setOpenHeight(contentRef.current.clientHeight);
			}
		}
	}, [contentRef]);

	useEffect(() => {
		setOpen(props.startOpen);
	}, [props.startOpen]);

	return (
		<div
			className={clsx(
				open ? 'border-opacity-100' : 'border-opacity-0',
				'border-b border-b-zinc-200 text-zinc-500 pb-2'
			)}
		>
			<button
				onClick={(e) => {
					e.preventDefault();
					setOpen(!open);
				}}
				className="cursor-pointer w-max flex items-center gap-2"
			>
				<TriangleDown
					className={clsx(
						open && 'rotate-180',
						props.triangleClassName ?? '',
						'text-inherit w-2 transition-all duration-500'
					)}
				/>
				{props.renderTrigger()}
			</button>

			<div
				ref={contentRef}
				className="overflow-hidden transition-all duration-500"
				style={
					openHeight !== null
						? { maxHeight: open ? `${openHeight}px` : '0px' }
						: {}
				}
			>
				{props.renderContent()}
			</div>
		</div>
	);
};

export default Accordion;
