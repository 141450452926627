import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import useModal from '../../hooks/useModal';
import useChannelQuery from '../../hooks/useChannelQuery';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import Text from '../Text/Text';
import Tooltip from '../Tooltip/Tooltip';
import CheckmarkIcon from '../Icons/Checkmark';
import RepeatIcon from '../Icons/Repeat';
import capitalize from '../../utils/capitalize';

const EngineButton = ({
	name,
	strategyId,
	statusGetCall,
	runPostCall,
	engineName,
}) => {
	const { open, close } = useModal();
	const queryClient = useQueryClient();
	const { data, queryKey } = useChannelQuery(
		['strategy-run-status', engineName, strategyId],
		() => statusGetCall(strategyId),
		{
			// poll algorithm status every 30 sec, even when tab is in the background
			refetchInterval: 30000,
			refetchIntervalInBackground: true,
		}
	);
	const { mutate: runStrategy } = useMutation(runPostCall, {
		onMutate: async () => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries(queryKey);
			const previousRunStatus = queryClient.getQueryData(queryKey);
			// override opportunities to have a loading state set to `true` for the overwritten opp
			queryClient.setQueriesData(queryKey, () => ({
				is_running: true,
				is_up_to_date: false,
				last_successful_run: null,
				last_errored_run: null,
			}));
			// Return a context object with the snapshotted value
			return { previousRunStatus };
		},
		// If the mutation fails, use the context returned from onMutate to roll back
		onError: (err, payload, context) => {
			queryClient.setQueryData(queryKey, context.previousRunStatus);
		},
		// Always refetch after error or success:
		onSettled: () => {
			queryClient.invalidateQueries(queryKey);
		},
	});
	const handleRun = () => {
		runStrategy(strategyId);
		close();
	};
	const onClick = () =>
		open(
			<Modal.Root>
				<Modal.Content>
					<Modal.Title>
						Run {engineName} for {name} strategy
					</Modal.Title>
					<div className="space-y-4">
						<Text>
							Are you sure you want to run the {engineName} for this strategy?
						</Text>
						{!!data?.last_errored_run && !data?.last_was_successful && (
							<>
								<Text className="text-ca-red">
									Warning: The last {engineName} run of this strategy was
									unsuccesful.
								</Text>
								<Text type="secondary">
									If the problem persists,{' '}
									<a className="underline" href="mailto:support@crunch.fashion">
										contact Crunch
									</a>
									.
								</Text>
							</>
						)}
					</div>
				</Modal.Content>
				<Modal.Actions>
					<div className="flex justify-between">
						<Button variant="secondary" onClick={close}>
							Cancel
						</Button>
						<Button onClick={handleRun}>Run {engineName}</Button>
					</div>
				</Modal.Actions>
			</Modal.Root>
		);
	// hide this component when no status has been received yet
	if (!data) {
		return null;
	}
	return (
		<>
			{!data?.is_running && data?.is_up_to_date && (
				<Tooltip
					content={
						<>
							{!!data?.last_started_run && (
								<>
									Last started at:{' '}
									{dayjs(data?.last_started_run).format(
										'DD/MM/YYYY [at] HH:mm:ss'
									)}
									. <br />
								</>
							)}
							{!!data?.last_successful_run && (
								<>
									Last successfully finished at:{' '}
									<b>
										{dayjs(data?.last_successful_run).format(
											'DD/MM/YYYY [at] HH:mm:ss'
										)}
									</b>
									.
								</>
							)}
						</>
					}
				>
					<span>
						<Button variant="success" disabled className="flex items-center">
							{capitalize(engineName)} is up-to-date
							<CheckmarkIcon className="h-2 ml-2" />
						</Button>
					</span>
				</Tooltip>
			)}
			{!data?.is_running && !data?.is_up_to_date && (
				<Tooltip
					content={
						<>
							{!!data?.last_started_run && (
								<>
									Last started at:{' '}
									{dayjs(data?.last_started_run).format(
										'DD/MM/YYYY [at] HH:mm:ss'
									)}
									. <br />
								</>
							)}
							{!!data?.last_successful_run && (
								<>
									Last successfully finished at:{' '}
									{dayjs(data?.last_successful_run).format(
										'DD/MM/YYYY [at] HH:mm:ss'
									)}
									. <br />
									<br />
								</>
							)}
							{!!data?.last_errored_run && !data?.last_was_successful && (
								<>
									Last run failed at:{' '}
									{dayjs(data?.last_errored_run).format(
										'DD/MM/YYYY [at] HH:mm:ss'
									)}
									. <br />
									<br />
								</>
							)}
							Click to manually trigger a new run.
						</>
					}
				>
					<span>
						{!!data?.last_errored_run && !data?.last_was_successful ? (
							<Button
								variant="danger"
								onClick={onClick}
								className="flex items-center"
							>
								{capitalize(engineName)} run failed. Click to try again
								<RepeatIcon className="h-3.5 ml-2" />
							</Button>
						) : (
							<Button onClick={onClick}>Run {engineName}</Button>
						)}
					</span>
				</Tooltip>
			)}
			{data?.is_running && (
				<Tooltip
					content={
						data?.last_started_run
							? `Started at: ${dayjs(data?.last_started_run).format(
									'DD/MM/YYYY [at] HH:mm:ss'
							  )}`
							: null
					}
				>
					<span>
						<Button variant="warning" disabled className="flex items-center">
							{capitalize(engineName)} running
							<span className="relative flex h-2 w-2 ml-2">
								<span className="absolute inline-flex h-full w-full rounded-full opacity-80 animate-ping bg-white" />
								<span className="relative inline-flex rounded-full h-2 w-2 bg-white" />
							</span>
						</Button>
					</span>
				</Tooltip>
			)}
		</>
	);
};
EngineButton.propTypes = {
	name: PropTypes.string.isRequired,
	strategyId: PropTypes.string.isRequired,
	statusGetCall: PropTypes.func.isRequired,
	runPostCall: PropTypes.func.isRequired,
	engineName: PropTypes.string.isRequired,
};
export default EngineButton;
