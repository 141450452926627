import { FunctionComponent } from 'react';
import Title from '../../../shared/components/Title/Title';
import { Configuration } from '../../../shared/models/configuration';
import SecondaryObjectiveOption, {
	NoSecondaryObjectives,
} from './SecondaryObjectiveOption';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';

interface Props extends Pick<Configuration, 'secondaryObjectives'> {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
	isInProgress?: boolean;
	isProposal?: boolean;
}

const SecondaryObjectivesWizardSection: FunctionComponent<Props> = ({
	inventoryAllocationId,
	reportId,
	secondaryObjectives,
	isInProgress,
	isProposal,
}) => {
	return (
		<div className="my-16">
			<Title type="section">Secondary objectives</Title>
			<p className="text-ca-gray max-w-lg pt-2 pb-8">
				Secondary objectives will be pursued as much as needed, but cannot be
				enforced.
			</p>
			<div className="flex flex-col gap-2">
				{secondaryObjectives.length === 0 && <NoSecondaryObjectives />}
				{secondaryObjectives.map((secondaryObjective) => (
					<SecondaryObjectiveOption
						key={secondaryObjective.type}
						inventoryAllocationId={inventoryAllocationId}
						reportId={reportId}
						secondaryObjective={secondaryObjective}
						isInProgress={isInProgress}
						isProposal={isProposal}
					/>
				))}
			</div>
		</div>
	);
};

export default SecondaryObjectivesWizardSection;
