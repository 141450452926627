import PropTypes from 'prop-types';

import Text from '../Text/Text';
import PlusIcon from '../Icons/Plus';

import CircularProgress from '../Progress/CircularProgress';

const FilterButton = ({ disabled, loading, onClick }) => (
	<div className="inline-flex items-center">
		<Text size="text-base" className="font-bold">
			Filters
		</Text>
		{!disabled && (
			<button
				className="p-2 ml-3 text-white bg-ca-purple rounded-lg transition-colors leading-none focus:outline-none focus-visible:ring-4 focus-visible:ring-opacity-10 focus-visible:ring-ca-purple focus-visible:bg-ca-purple-a hover:bg-ca-purple-a active:bg-ca-purple-a"
				type="button"
				onClick={onClick}
			>
				<PlusIcon className="w-4" />
			</button>
		)}
		{loading && <CircularProgress size="small" />}
	</div>
);

FilterButton.propTypes = {
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	onClick: PropTypes.func,
};

FilterButton.defaultProps = {
	disabled: false,
	loading: false,
	onClick: () => {},
};

export default FilterButton;
