import PropTypes from 'prop-types';

const Cross = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 8 8"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m7.93361384 1.32536241-1.32211314-1.32536241-2.64516129 2.65072482-2.62459093-2.63104868-1.34174848 1.30568627 2.64422627 2.6507343-2.64422627 2.65167262 1.32211314 1.32536241 2.64422627-2.6507343 2.69191211 2.69760256 1.34174848-1.30568627-2.71061244-2.71821702z"
			fill="currentColor"
		/>
	</svg>
);

Cross.propTypes = {
	className: PropTypes.string,
};

Cross.defaultProps = {
	className: '',
};

export default Cross;
