import { useMutation, useQueryClient } from 'react-query';

import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import { LIST_STRATEGIES, CREATE_STRATEGY } from '../../shared/api/strategies';
import Button from '../../shared/components/Button/Button';
import Modal from '../../shared/components/Modal/Modal';
import useModal from '../../shared/hooks/useModal';

import InputWithLabel from '../../shared/components/InputWithLabel/InputWithLabel';
import CircularProgress from '../../shared/components/Progress/CircularProgress';
import TextInput from '../../shared/components/TextInput/TextInput';

import Dropdown from '../../shared/components/Dropdown/Dropdown';
import useChannelQuery from '../../shared/hooks/useChannelQuery';

const StrategyCreateModal = () => {
	const { close } = useModal();
	const { formState, control, handleSubmit } = useForm();

	const queryClient = useQueryClient();
	const {
		isLoading,
		data: strategies,
		channel: activeChannel,
	} = useChannelQuery(['strategies'], LIST_STRATEGIES);
	const { mutate: createScenario, isLoading: isCreateLoading } = useMutation(
		CREATE_STRATEGY,
		{
			onMutate: async (strategy) => {
				await queryClient.cancelQueries({
					queryKey: [activeChannel, 'strategies'],
				});

				// Snapshot the previous value
				const previousStrategies = queryClient.getQueryData([
					activeChannel,
					'strategies',
				]);

				// Optimistically update to the new value
				queryClient.setQueryData([activeChannel, 'strategies'], (old) => [
					...old,
					strategy,
				]);

				// Return a context object with the snapshotted value
				return { previousStrategies };
			},
			onError: (context) => {
				queryClient.setQueryData(
					[activeChannel, 'strategies'],
					context.previousStrategies
				);
			},
			// Always refetch after error or success:
			onSettled: () => {
				queryClient.invalidateQueries({
					queryKey: [activeChannel, 'strategies'],
				});
				close();
			},
		}
	);

	if (isCreateLoading || isLoading) {
		return <CircularProgress />;
	}

	return (
		<Modal.Root>
			<Modal.Content>
				<Modal.Title>New strategy</Modal.Title>
				<form onSubmit={handleSubmit(createScenario)} id="strategy-create">
					<InputWithLabel
						label="Strategy name"
						htmlFor="name"
						className="w-80 justify-between"
						labelClassName={clsx(
							'gap-2 mb-2 md:mb-0',
							formState?.errors?.name?.message && '-mt-5'
						)}
					>
						<Controller
							name="name"
							defaultValue=""
							control={control}
							rules={{ required: 'Required field' }}
							render={({ field }) => (
								<TextInput
									id="title"
									className="w-full sm:w-44"
									value={field.value}
									onChange={field.onChange}
									error={formState?.errors?.name?.message}
									placeholder="Name"
									type="text"
									endAdornment={undefined}
									startAdornment={undefined}
									size="regular"
								/>
							)}
						/>
					</InputWithLabel>
					{!!strategies?.length && (
						<InputWithLabel
							label="Copy from strategy"
							htmlFor="from_strategy"
							className="w-80 justify-between"
							labelClassName={clsx(
								'gap-2 mb-2 md:mb-0',
								formState?.errors?.from_strategy?.message && '-mt-5'
							)}
						>
							<Controller
								name="from_strategy"
								defaultValue={null}
								control={control}
								render={({ field }) => (
									<Dropdown
										id="title"
										className="w-full sm:w-44 mt-2"
										value={field.value}
										onChange={field.onChange}
										error={formState?.errors?.name?.message}
										options={strategies.map((strategy) => ({
											label: strategy.name,
											value: strategy.slug,
										}))}
										placeholder="Start empty"
										size="regular"
										allowEmpty
									/>
								)}
							/>
						</InputWithLabel>
					)}
				</form>
			</Modal.Content>
			<Modal.Actions>
				<div className="flex justify-between">
					<Button variant="secondary" onClick={close}>
						Cancel
					</Button>
					<Button
						type="submit"
						variant="primary"
						form="strategy-create"
						disabled={isCreateLoading}
					>
						Create
					</Button>
				</div>
			</Modal.Actions>
		</Modal.Root>
	);
};

export default StrategyCreateModal;
