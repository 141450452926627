import { useQueries } from 'react-query';

import useChannelStore from '../data/channels';

// wrapper for useQuery that ensures caching on channel level
const useChannelQueries = (queries) => {
	const activeChannel = useChannelStore((s) => s.activeChannel);

	const channelQueries =
		queries?.queries?.map((query) => {
			let key = query.queryKey;
			if (!Array.isArray(key)) {
				key = [key];
			}
			const queryKeyWithActiveChannel = [activeChannel, ...key];
			return { ...query, queryKey: queryKeyWithActiveChannel };
		}) || [];

	return [...useQueries(channelQueries)];
};

export default useChannelQueries;
