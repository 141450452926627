import api from './api';

export const GET_OPPORTUNITIES_FILTERS = (strategySlug) => {
	return api.get(`api/v2/opportunities/filters/${strategySlug}`).json();
};

export const GET_OPPORTUNITIES_SCHEMA = () => {
	return api.get(`api/v2/opportunities/schema`).json();
};

export const GET_OPPORTUNITIES = (strategySlug, searchParams) => {
	return api
		.get(`api/v2/opportunities/${strategySlug}`, {
			searchParams,
		})
		.json();
};

export const CREATE_OPPORTUNITY = ({ strategySlug, ...payload }) => {
	return api
		.post(`api/v2/opportunities/${strategySlug}/overwrite`, {
			json: payload,
		})
		.json();
};

export const GET_OPPORTUNITIES_EXPORT = (strategySlug, all, searchParams) => {
	return api
		.get(`api/v2/opportunities/${strategySlug}/export${all ? '/all' : ''}`, {
			searchParams,
		})
		.blob();
};

export const CREATE_MARKDOWN_TRANSFER = ({
	strategySlug,
	filterSearchParams,
	...payload
}) => {
	return api
		.post(`api/v2/opportunities/${strategySlug}/markdown_transfer`, {
			searchParams: filterSearchParams,
			json: payload,
		})
		.json();
};

export const GET_NUMBER_TRANSFERS_BUSINESS_RULES = ({
	strategySlug,
	filters,
	from,
	to,
	unit,
}) => {
	return api
		.post(
			`api/v2/opportunities/${strategySlug}/nr_products_transfer_business_rules`,
			{
				searchParams: filters,
				json: {
					from_bucket: from,
					to_bucket: to,
					units: unit,
					amount: 0,
				},
			}
		)
		.json();
};

export const GET_BUCKETS = (strategySlug, searchParams = {}) => {
	return api
		.get(`api/v2/opportunities/${strategySlug}/markdown_buckets`, {
			searchParams,
		})
		.json();
};
