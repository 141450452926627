/* eslint-disable class-methods-use-this */
import { UseQueryResult } from 'react-query';
import { MutationDTO } from '../../shared/models/API';
import {
	MaximumSKUAmountConstraint,
	Constraint,
	ConstraintType,
	CooldownPeriodConstraint,
	DoNotBreakSizeChartConstraint,
	FixBrokenSizeChartsSecondaryObjective,
	MarketingExpectation,
	MaximumShipmentAmountConstraint,
	MaximumVolumeMovedConstraint,
	MinimumProductAmountConstraint,
	MinimumROIConstraint,
	MinimumShipmentAmountConstraint,
	OnlyRestoreSizeChartsConstraint,
	PenalizeBrokenSizeChartsSecondaryObjective,
	PrimaryObjective,
	Scope,
	SecondaryObjective,
	SecondaryObjectiveType,
} from '../../shared/models/configuration';
import { DTOMap, ToInternal } from '../../shared/models/DTOMap';
import {
	InventoryAllocationReport,
	InventoryAllocationReportStatus,
} from '../../shared/models/inventoryAllocationReport';
import { IOMap } from '../../shared/models/IOMap';
import {
	MaximumSKUAmountConstraintDTO,
	ConstraintDTO,
	ConstraintTypeDTO,
	CooldownPeriodConstraintDTO,
	CreateReportBodyDTO,
	CreateReportParametersDTO,
	CreateReportResponseDTO,
	DoNotBreakSizeChartsConstraintDTO,
	GetReportResponseDTO,
	GetReportsResponseDTO,
	LocationScopeDTO,
	MarketingActionDTO,
	MarketingActionTypeDTO,
	MaximumShipmentAmountConstraintDTO,
	MaximumVolumeMovedConstraintDTO,
	MinimumROIConstraintDTO,
	MinimumShipmentAmountConstraintDTO,
	MinimumProductAmountConstraintDTO,
	OnlyRestoreSizeChartsConstraintDTO,
	FixBrokenSizeChartSecondaryObjectiveDTO,
	PenalizeBrokenSizeChartsSecondaryObjectiveDTO,
	ProductScopeDTO,
	ReportDTO,
	ReportStatusDTO,
	ScopeDTO,
	ScopeTypeDTO,
	SecondaryObjectiveDTO,
	SecondaryObjectiveTypeDTO,
	StartEngineBodyDTO,
	StartEngineParametersDTO,
	StartEngineResponseDTO,
	UpdateReportBodyDTO,
	UpdateReportParametersDTO,
	UpdateReportResponseDTO,
} from '../../shared/models/schema';
import {
	CreateReportMutation,
	ReportAPI,
	StartAlgorithmMutation,
	SubmitReportMutation,
	UpdateReportMutation,
} from './useReportsUseCases';

export type ReportAPIDTO = UseQueryResult<
	GetReportsResponseDTO | GetReportResponseDTO
>;

export type CreateReportMutationDTO = MutationDTO<
	CreateReportParametersDTO,
	CreateReportBodyDTO,
	CreateReportResponseDTO
>;

export type UpdateReportMutationDTO = MutationDTO<
	UpdateReportParametersDTO,
	UpdateReportBodyDTO,
	UpdateReportResponseDTO
>;

export type StartEngineMutationDTO = MutationDTO<
	StartEngineParametersDTO,
	StartEngineBodyDTO,
	StartEngineResponseDTO
>;

interface Mapper {
	toAPI: (API?: ReportAPIDTO) => ReportAPI;
	toReport: ToInternal<ReportDTO, InventoryAllocationReport>;
	reportStatus: DTOMap<ReportStatusDTO, InventoryAllocationReportStatus>;
	scope: DTOMap<ScopeDTO[], Scope>;
	constraint: DTOMap<ConstraintDTO, Constraint>;
	constraintType: DTOMap<ConstraintTypeDTO, ConstraintType>;
	maximumVolumeMovedConstraint: DTOMap<
		MaximumVolumeMovedConstraintDTO,
		MaximumVolumeMovedConstraint
	>;
	minimumShipmentAmountConstraint: DTOMap<
		MinimumShipmentAmountConstraintDTO,
		MinimumShipmentAmountConstraint
	>;
	minimumProductAmountConstraint: DTOMap<
		MinimumProductAmountConstraintDTO,
		MinimumProductAmountConstraint
	>;
	maximumShipmentAmountConstraint: DTOMap<
		MaximumShipmentAmountConstraintDTO,
		MaximumShipmentAmountConstraint
	>;
	cooldownPeriodConstraint: DTOMap<
		CooldownPeriodConstraintDTO,
		CooldownPeriodConstraint
	>;
	minimumROIConstraint: DTOMap<MinimumROIConstraintDTO, MinimumROIConstraint>;
	doNotBreakSizeChartConstraint: DTOMap<
		DoNotBreakSizeChartsConstraintDTO,
		DoNotBreakSizeChartConstraint
	>;
	maximumSKUAmountConstraint: DTOMap<
		MaximumSKUAmountConstraintDTO,
		MaximumSKUAmountConstraint
	>;
	onlyRestoreSizeChartsConstraint: DTOMap<
		OnlyRestoreSizeChartsConstraintDTO,
		OnlyRestoreSizeChartsConstraint
	>;
	secondaryObjective: DTOMap<SecondaryObjectiveDTO, SecondaryObjective>;
	secondaryObjectiveType: DTOMap<
		SecondaryObjectiveTypeDTO,
		SecondaryObjectiveType
	>;
	fixBrokenSizeChartsSecondaryObjective: DTOMap<
		FixBrokenSizeChartSecondaryObjectiveDTO,
		FixBrokenSizeChartsSecondaryObjective
	>;
	penalizeBrokenSizeChartsSecondaryObjective: DTOMap<
		PenalizeBrokenSizeChartsSecondaryObjectiveDTO,
		PenalizeBrokenSizeChartsSecondaryObjective
	>;
	marketingExpectation: DTOMap<MarketingActionDTO, MarketingExpectation>;
	toCreateMutation: ToInternal<CreateReportMutationDTO, CreateReportMutation>;
	toUpdateMutation: ToInternal<UpdateReportMutationDTO, UpdateReportMutation>;
	toStartAlgorithmMutation: ToInternal<
		StartEngineMutationDTO,
		StartAlgorithmMutation
	>;
	toSubmitMutation: ToInternal<UpdateReportMutationDTO, SubmitReportMutation>;
}

class ReportMapper implements Mapper {
	isSingleEntity = (
		data: GetReportResponseDTO | GetReportsResponseDTO
	): data is GetReportResponseDTO => 'id' in (data || {});

	isProductScope = (DTO: ScopeDTO): DTO is ProductScopeDTO =>
		DTO.scope_type === ScopeTypeDTO.ProductScope;

	isLocationScope = (DTO: ScopeDTO): DTO is LocationScopeDTO =>
		DTO.scope_type === ScopeTypeDTO.LocationScope;

	maximumVolumeMovedConstraint: Mapper['maximumVolumeMovedConstraint'] = {
		toInternal: (DTO) => ({
			type: ConstraintType.MaximumVolumeMoved,
			maxMovementCount: DTO.maximum_volume_moved,
			estimatedMissedRevenue: DTO.lost_revenue,
			scope: this.scope.toInternal(DTO.scopes || []),
		}),
		toDTO: (internal) => ({
			constraint_type: ConstraintTypeDTO.MaximumVolumeMoved,
			maximum_volume_moved: internal.maxMovementCount || 0,
			lost_revenue: internal.estimatedMissedRevenue || 0,
			scopes: this.scope.toDTO(internal.scope),
		}),
	};

	minimumShipmentAmountConstraint: Mapper['minimumShipmentAmountConstraint'] = {
		toInternal: (DTO) => ({
			type: ConstraintType.MinimumShipmentAmount,
			minimumShipmentAmount: DTO.minimum_shipment_amount,
			estimatedMissedRevenue: DTO.lost_revenue,
			scope: this.scope.toInternal(DTO.scopes || []),
		}),
		toDTO: (internal) => ({
			constraint_type: ConstraintTypeDTO.MinimumShipmentAmount,
			minimum_shipment_amount: internal.minimumShipmentAmount || 0,
			lost_revenue: internal.estimatedMissedRevenue || 0,
			scopes: this.scope.toDTO(internal.scope),
		}),
	};

	maximumShipmentAmountConstraint: Mapper['maximumShipmentAmountConstraint'] = {
		toInternal: (DTO) => ({
			type: ConstraintType.MaximumShipmentAmount,
			maximumShipmentAmount: DTO.maximum_shipment_amount,
			estimatedMissedRevenue: DTO.lost_revenue,
			scope: this.scope.toInternal(DTO.scopes || []),
		}),
		toDTO: (internal) => ({
			constraint_type: ConstraintTypeDTO.MaximumShipmentAmount,
			maximum_shipment_amount: internal.maximumShipmentAmount || 0,
			lost_revenue: internal.estimatedMissedRevenue || 0,
			scopes: this.scope.toDTO(internal.scope),
		}),
	};

	cooldownPeriodConstraint: Mapper['cooldownPeriodConstraint'] = {
		toInternal: (DTO) => ({
			type: ConstraintType.CooldownPeriod,
			minCooldownDays: DTO.cooldown_period_days,
			estimatedMissedRevenue: DTO.lost_revenue,
			scope: this.scope.toInternal(DTO.scopes || []),
		}),
		toDTO: (internal) => ({
			constraint_type: ConstraintTypeDTO.CooldownPeriod,
			cooldown_period_days: internal.minCooldownDays || 0,
			lost_revenue: internal.estimatedMissedRevenue || 0,
			scopes: this.scope.toDTO(internal.scope),
		}),
	};

	minimumROIConstraint: Mapper['minimumROIConstraint'] = {
		toInternal: (DTO) => ({
			type: ConstraintType.MinimumROI,
			minimumROI: DTO.minimum_roi,
			estimatedMissedRevenue: DTO.lost_revenue,
			scope: this.scope.toInternal(DTO.scopes || []),
		}),
		toDTO: (internal) => ({
			constraint_type: ConstraintTypeDTO.MinimumROI,
			minimum_roi: internal.minimumROI || 0,
			lost_revenue: internal.estimatedMissedRevenue || 0,
			scopes: this.scope.toDTO(internal.scope),
		}),
	};

	doNotBreakSizeChartConstraint: Mapper['doNotBreakSizeChartConstraint'] = {
		toInternal: (DTO) => ({
			type: ConstraintType.DoNotBreakSizeChart,
			estimatedMissedRevenue: DTO.lost_revenue,
			scope: this.scope.toInternal(DTO.scopes || []),
		}),
		toDTO: (internal) => ({
			constraint_type: ConstraintTypeDTO.DoNotBreakSizeCharts,
			lost_revenue: internal.estimatedMissedRevenue || 0,
			scopes: this.scope.toDTO(internal.scope),
			cannot_break_size_chart: true,
		}),
	};

	maximumSKUAmountConstraint: Mapper['maximumSKUAmountConstraint'] = {
		toInternal: (DTO) => ({
			type: ConstraintType.MaximumSKUAmount,
			estimatedMissedRevenue: DTO.lost_revenue,
			scope: this.scope.toInternal(DTO.scopes || []),
		}),
		toDTO: (internal) => ({
			constraint_type: ConstraintTypeDTO.MaximumSKUAmount,
			lost_revenue: internal.estimatedMissedRevenue || 0,
			scopes: this.scope.toDTO(internal.scope),
			activate_max_sku_amount: true,
		}),
	};

	onlyRestoreSizeChartsConstraint: Mapper['onlyRestoreSizeChartsConstraint'] = {
		toInternal: (DTO) => ({
			type: ConstraintType.OnlyRestoreSizeCharts,
			estimatedMissedRevenue: DTO.lost_revenue,
			scope: this.scope.toInternal(DTO.scopes || []),
		}),
		toDTO: (internal) => ({
			constraint_type: ConstraintTypeDTO.OnlyRestoreSizeCharts,
			lost_revenue: internal.estimatedMissedRevenue || 0,
			scopes: this.scope.toDTO(internal.scope),
			only_restore_size_charts: true,
		}),
	};

	minimumProductAmountConstraint: Mapper['minimumProductAmountConstraint'] = {
		toInternal: (DTO) => ({
			type: ConstraintType.MinimumProductAmount,
			scope: this.scope.toInternal(DTO.scopes ?? []),
			estimatedMissedRevenue: DTO.lost_revenue,
			minimumProductAmount: DTO.minimum_product_amount,
		}),
		toDTO: (internal) => ({
			constraint_type: ConstraintTypeDTO.MinimumProductAmount,
			lost_revenue: internal.estimatedMissedRevenue ?? 0,
			scopes: this.scope.toDTO(internal.scope),
			minimum_product_amount: internal.minimumProductAmount,
		}),
	};

	fixBrokenSizeChartsSecondaryObjective: Mapper['fixBrokenSizeChartsSecondaryObjective'] =
		{
			toInternal: (DTO) => ({
				type: SecondaryObjectiveType.FixBrokenSizeCharts,
				scope: this.scope.toInternal(DTO.scopes || []),
				estimatedMissedRevenue: DTO.lost_revenue,
				weight: DTO.weight,
			}),
			toDTO: (internal) => ({
				secondary_objective_type: SecondaryObjectiveTypeDTO.FixBrokenSizeChart,
				lost_revenue: internal.estimatedMissedRevenue || 0,
				scopes: this.scope.toDTO(internal.scope),
				description: internal.type.toString(),
				weight: internal.weight || 0,
				fix_broken_size_charts: true,
			}),
		};

	penalizeBrokenSizeChartsSecondaryObjective: Mapper['penalizeBrokenSizeChartsSecondaryObjective'] =
		{
			toInternal: (DTO) => ({
				type: SecondaryObjectiveType.PenalizeBrokenSizeCharts,
				scope: this.scope.toInternal(DTO.scopes || []),
				estimatedMissedRevenue: DTO.lost_revenue,
				weight: DTO.weight,
			}),
			toDTO: (internal) => ({
				secondary_objective_type:
					SecondaryObjectiveTypeDTO.PenalizeBrokenSizeCharts,
				lost_revenue: internal.estimatedMissedRevenue || 0,
				scopes: this.scope.toDTO(internal.scope),
				description: internal.type.toString(),
				weight: internal.weight || 0,
				penalize_broken_size_charts: true,
			}),
		};

	toAPI: Mapper['toAPI'] = (API) => {
		const getDTOs = (data: ReportAPIDTO['data']): ReportDTO[] => {
			if (data && this.isSingleEntity(data)) return [data];
			if (data && !this.isSingleEntity(data)) return data.items;
			return [];
		};

		const entities = getDTOs(API?.data).map(this.toReport);

		return {
			entities,
			entity: entities[0],
			count: entities.length,
			isLoading: API?.isLoading || false,
			refetch: API?.refetch || (() => {}),
		};
	};

	toReport: Mapper['toReport'] = (DTO) => ({
		id: DTO.id,
		inventoryAllocationId: DTO.allocation_id || undefined,
		title: DTO.name,
		status: this.reportStatus.toInternal(DTO.status),
		message: DTO.message || '',
		configuration: {
			primaryObjective: PrimaryObjective.Revenue,
			scope: this.scope.toInternal(DTO.scopes || []),
			constraints: (DTO.constraints || []).map(this.constraint.toInternal),
			secondaryObjectives: (DTO.secondary_objectives || []).map(
				this.secondaryObjective.toInternal
			),
			marketingExpectations: (DTO.marketing_actions || []).map(
				this.marketingExpectation.toInternal
			),
		},
		movementCount: DTO.proposed_number_of_movements,
		receiverCount: DTO.proposed_number_of_receivers,
		senderCount: DTO.proposed_number_of_senders,
	});

	scope: Mapper['scope'] = {
		toInternal: (DTOs) => ({
			products: DTOs.find(this.isProductScope)?.products_to_include || [],
			locations: DTOs.find(this.isLocationScope)?.shops_to_include || [],
		}),
		toDTO: (scope) => [
			{
				scope_type: ScopeTypeDTO.LocationScope,
				shops_to_include: scope.locations,
			},
			{
				scope_type: ScopeTypeDTO.ProductScope,
				products_to_include: scope.products,
			},
		],
	};

	constraint: Mapper['constraint'] = {
		toInternal: (DTO) => {
			switch (DTO.constraint_type) {
				case ConstraintTypeDTO.MaximumVolumeMoved:
					return this.maximumVolumeMovedConstraint.toInternal(DTO);
				case ConstraintTypeDTO.MinimumShipmentAmount:
					return this.minimumShipmentAmountConstraint.toInternal(DTO);
				case ConstraintTypeDTO.MaximumShipmentAmount:
					return this.maximumShipmentAmountConstraint.toInternal(DTO);
				case ConstraintTypeDTO.CooldownPeriod:
					return this.cooldownPeriodConstraint.toInternal(DTO);
				case ConstraintTypeDTO.MinimumROI:
					return this.minimumROIConstraint.toInternal(DTO);
				case ConstraintTypeDTO.DoNotBreakSizeCharts:
					return this.doNotBreakSizeChartConstraint.toInternal(DTO);
				case ConstraintTypeDTO.MaximumSKUAmount:
					return this.maximumSKUAmountConstraint.toInternal(DTO);
				case ConstraintTypeDTO.OnlyRestoreSizeCharts:
					return this.onlyRestoreSizeChartsConstraint.toInternal(DTO);
				case ConstraintTypeDTO.MinimumProductAmount:
					return this.minimumProductAmountConstraint.toInternal(DTO);
				default:
					return this.minimumROIConstraint.toInternal(DTO as any);
			}
		},
		toDTO: (internal) => {
			switch (internal.type) {
				case ConstraintType.MaximumVolumeMoved:
					return this.maximumVolumeMovedConstraint.toDTO(internal);
				case ConstraintType.MinimumShipmentAmount:
					return this.minimumShipmentAmountConstraint.toDTO(internal);
				case ConstraintType.MaximumShipmentAmount:
					return this.maximumShipmentAmountConstraint.toDTO(internal);
				case ConstraintType.CooldownPeriod:
					return this.cooldownPeriodConstraint.toDTO(internal);
				case ConstraintType.MinimumROI:
					return this.minimumROIConstraint.toDTO(internal);
				case ConstraintType.DoNotBreakSizeChart:
					return this.doNotBreakSizeChartConstraint.toDTO(internal);
				case ConstraintType.MaximumSKUAmount:
					return this.maximumSKUAmountConstraint.toDTO(internal);
				case ConstraintType.OnlyRestoreSizeCharts:
					return this.onlyRestoreSizeChartsConstraint.toDTO(internal);
				case ConstraintType.MinimumProductAmount:
					return this.minimumProductAmountConstraint.toDTO(internal);
				default:
					return this.minimumROIConstraint.toDTO(internal as any);
			}
		},
	};

	secondaryObjective: Mapper['secondaryObjective'] = {
		toInternal: (DTO) => {
			switch (DTO.secondary_objective_type) {
				case SecondaryObjectiveTypeDTO.FixBrokenSizeChart:
					return this.fixBrokenSizeChartsSecondaryObjective.toInternal(DTO);
				case SecondaryObjectiveTypeDTO.PenalizeBrokenSizeCharts:
					return this.penalizeBrokenSizeChartsSecondaryObjective.toInternal(
						DTO
					);
				default:
					return this.fixBrokenSizeChartsSecondaryObjective.toInternal(
						DTO as any
					);
			}
		},
		toDTO: (internal) => {
			switch (internal.type) {
				case SecondaryObjectiveType.FixBrokenSizeCharts:
					return this.fixBrokenSizeChartsSecondaryObjective.toDTO(internal);
				case SecondaryObjectiveType.PenalizeBrokenSizeCharts:
					return this.penalizeBrokenSizeChartsSecondaryObjective.toDTO(
						internal
					);
				default:
					return this.fixBrokenSizeChartsSecondaryObjective.toDTO(
						internal as any
					);
			}
		},
	};

	marketingExpectation: Mapper['marketingExpectation'] = {
		toInternal: (DTO) => ({
			campaignName: DTO.name_marketing_action,
			salesModifier: DTO.expected_sales_uplift,
			scope: this.scope.toInternal([]),
		}),
		toDTO: (internal) => ({
			marketing_action_type: MarketingActionTypeDTO.IncreaseDemand,
			name_marketing_action: internal.campaignName,
			expected_sales_uplift: internal.salesModifier || 0,
			predicted_sales_uplift: 0,
			sales_multiplication_factor: 0,
			products_in_marketing_action: internal.scope.products,
		}),
	};

	reportStatus: Mapper['reportStatus'] = {
		toInternal: (DTO) => {
			switch (DTO) {
				case ReportStatusDTO.Draft:
					return InventoryAllocationReportStatus.Draft;
				case ReportStatusDTO.InProgress:
					return InventoryAllocationReportStatus.InProgress;
				case ReportStatusDTO.Proposal:
					return InventoryAllocationReportStatus.Proposal;
				case ReportStatusDTO.Submitted:
					return InventoryAllocationReportStatus.Submitted;
				case ReportStatusDTO.NoSuggestedMoves:
					return InventoryAllocationReportStatus.NoSuggestedMoves;
				case ReportStatusDTO.Error:
					return InventoryAllocationReportStatus.Error;
				case ReportStatusDTO.Outdated:
					return InventoryAllocationReportStatus.Outdated;
				default:
					return InventoryAllocationReportStatus.Draft;
			}
		},
		toDTO: (internal) => {
			switch (internal) {
				case InventoryAllocationReportStatus.Draft:
					return ReportStatusDTO.Draft;
				case InventoryAllocationReportStatus.InProgress:
					return ReportStatusDTO.InProgress;
				case InventoryAllocationReportStatus.Proposal:
					return ReportStatusDTO.Proposal;
				case InventoryAllocationReportStatus.Submitted:
					return ReportStatusDTO.Submitted;
				case InventoryAllocationReportStatus.NoSuggestedMoves:
					return ReportStatusDTO.NoSuggestedMoves;
				case InventoryAllocationReportStatus.Error:
					return ReportStatusDTO.Error;
				case InventoryAllocationReportStatus.Outdated:
					return ReportStatusDTO.Outdated;
				default:
					return ReportStatusDTO.Draft;
			}
		},
	};

	constraintType: Mapper['constraintType'] = {
		toInternal: (DTO) => {
			switch (DTO) {
				case ConstraintTypeDTO.MaximumVolumeMoved:
					return ConstraintType.MaximumVolumeMoved;
				case ConstraintTypeDTO.MinimumShipmentAmount:
					return ConstraintType.MinimumShipmentAmount;
				case ConstraintTypeDTO.MaximumShipmentAmount:
					return ConstraintType.MaximumShipmentAmount;
				case ConstraintTypeDTO.CooldownPeriod:
					return ConstraintType.CooldownPeriod;
				case ConstraintTypeDTO.DoNotBreakSizeCharts:
					return ConstraintType.DoNotBreakSizeChart;
				case ConstraintTypeDTO.MinimumROI:
					return ConstraintType.MinimumROI;
				case ConstraintTypeDTO.MaximumSKUAmount:
					return ConstraintType.MaximumSKUAmount;
				case ConstraintTypeDTO.OnlyRestoreSizeCharts:
					return ConstraintType.OnlyRestoreSizeCharts;
				case ConstraintTypeDTO.MinimumProductAmount:
					return ConstraintType.MinimumProductAmount;
				default:
					return ConstraintType.Unknown;
			}
		},
		toDTO: (internal) => {
			switch (internal) {
				case ConstraintType.MaximumVolumeMoved:
					return ConstraintTypeDTO.MaximumVolumeMoved;
				case ConstraintType.MinimumShipmentAmount:
					return ConstraintTypeDTO.MinimumShipmentAmount;
				case ConstraintType.MaximumShipmentAmount:
					return ConstraintTypeDTO.MaximumShipmentAmount;
				case ConstraintType.CooldownPeriod:
					return ConstraintTypeDTO.CooldownPeriod;
				case ConstraintType.DoNotBreakSizeChart:
					return ConstraintTypeDTO.DoNotBreakSizeCharts;
				case ConstraintType.MinimumROI:
					return ConstraintTypeDTO.MinimumROI;
				case ConstraintType.MaximumSKUAmount:
					return ConstraintTypeDTO.MaximumSKUAmount;
				case ConstraintType.OnlyRestoreSizeCharts:
					return ConstraintTypeDTO.OnlyRestoreSizeCharts;
				case ConstraintType.MinimumProductAmount:
					return ConstraintTypeDTO.MinimumProductAmount;
				default:
					return ConstraintTypeDTO.DoNotBreakSizeCharts;
			}
		},
	};

	secondaryObjectiveType: Mapper['secondaryObjectiveType'] = {
		toInternal: (DTO) => {
			switch (DTO) {
				case SecondaryObjectiveTypeDTO.FixBrokenSizeChart:
					return SecondaryObjectiveType.FixBrokenSizeCharts;
				case SecondaryObjectiveTypeDTO.PenalizeBrokenSizeCharts:
					return SecondaryObjectiveType.PenalizeBrokenSizeCharts;
				default:
					return SecondaryObjectiveType.Unknown;
			}
		},
		toDTO: (internal) => {
			switch (internal) {
				case SecondaryObjectiveType.FixBrokenSizeCharts:
					return SecondaryObjectiveTypeDTO.FixBrokenSizeChart;
				case SecondaryObjectiveType.PenalizeBrokenSizeCharts:
					return SecondaryObjectiveTypeDTO.PenalizeBrokenSizeCharts;
				default:
					return SecondaryObjectiveTypeDTO.Unknown;
			}
		},
	};

	toCreateMutation: Mapper['toCreateMutation'] = (mutation) => {
		const map: IOMap<CreateReportMutationDTO, CreateReportMutation> = {
			toInput: (input) => ({
				name: input.title,
				status: ReportStatusDTO.Draft,
				scopes: this.scope.toDTO(
					input.configuration?.scope || { locations: [], products: [] }
				),
				constraints: (input.configuration?.constraints || []).map(
					this.constraint.toDTO
				),
				secondary_objectives: (
					input.configuration?.secondaryObjectives || []
				).map(this.secondaryObjective.toDTO),
				marketing_actions: (
					input.configuration?.marketingExpectations || []
				).map(this.marketingExpectation.toDTO),
				allocation_id: input.inventoryAllocation,
			}),
			toOutput: this.toReport,
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	toUpdateMutation: Mapper['toUpdateMutation'] = (mutation) => {
		const map: IOMap<UpdateReportMutationDTO, UpdateReportMutation> = {
			toInput: (input) => ({
				allocation_id: input.inventoryAllocationId,
				id: input.id,
				scopes: input.configuration?.scope
					? this.scope.toDTO(input.configuration.scope)
					: undefined,
				constraints: input.configuration?.constraints?.map(
					this.constraint.toDTO
				),
				secondary_objectives: input.configuration?.secondaryObjectives?.map(
					this.secondaryObjective.toDTO
				),
				marketing_actions: input.configuration?.marketingExpectations?.map(
					this.marketingExpectation.toDTO
				),
				report_id: input.id,
				name: input.title,
				status: input.status
					? this.reportStatus.toDTO(input.status)
					: undefined,
			}),
			toOutput: this.toReport,
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	toStartAlgorithmMutation: Mapper['toStartAlgorithmMutation'] = (mutation) => {
		const map: IOMap<StartEngineMutationDTO, StartAlgorithmMutation> = {
			toInput: (input) => ({
				allocation_id: input.inventoryAllocationId,
				report_id: input.id,
			}),
			toOutput: () => {},
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};

	toSubmitMutation: Mapper['toSubmitMutation'] = (mutation) => {
		const map: IOMap<UpdateReportMutationDTO, SubmitReportMutation> = {
			toInput: (input) => ({
				allocation_id: input.inventoryAllocationId,
				report_id: input.id,
				status: ReportStatusDTO.Submitted,
				name: input.title,
			}),
			toOutput: this.toReport,
		};

		return (input) => mutation(map.toInput(input)).then(map.toOutput);
	};
}

export default ReportMapper;
