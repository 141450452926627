import { FunctionComponent } from 'react';
import useModal from '../../../shared/hooks/useModal';
import { MarketingExpectation } from '../../../shared/models/configuration';
import LocationsTableModal from '../modals/LocationsTableModal';
import ProductsTableModal from '../modals/ProductsTableModal';
import { InventoryAllocationReport } from '../../../shared/models/inventoryAllocationReport';
import { InventoryAllocation } from '../../../shared/models/inventoryAllocation';

interface Props {
	inventoryAllocationId: InventoryAllocation['id'];
	reportId: InventoryAllocationReport['id'];
	marketingExpectation: MarketingExpectation;
}

export const NoMarketingExpectations: FunctionComponent = () => (
	<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg">
		<p className="text-ca-gray">There are no active campaigns.</p>
	</div>
);

const MarketingExpectationOption: FunctionComponent<Props> = ({
	inventoryAllocationId,
	reportId,
	marketingExpectation,
}) => {
	const { open } = useModal();

	function openProductsModal(): void {
		open(
			<ProductsTableModal
				inventoryAllocationId={inventoryAllocationId}
				reportId={reportId}
				selectedProducts={marketingExpectation.scope.products}
			/>
		);
	}

	function openLocationsModal(): void {
		open(
			<LocationsTableModal
				inventoryAllocationId={inventoryAllocationId}
				reportId={reportId}
				selectedLocations={marketingExpectation.scope.locations}
			/>
		);
	}

	return (
		<div className="border-ca-silver border rounded-lg bg-white px-4 py-5 -ml-4 max-w-lg">
			<p className="text-ca-gray">
				Due to{' '}
				<strong className="text-ca-purple">
					{marketingExpectation.campaignName}
				</strong>{' '}
				we expect{' '}
				<button type="button" onClick={openProductsModal}>
					<strong className="text-ca-purple">{`${marketingExpectation.scope.products.length} products`}</strong>
				</button>{' '}
				over{' '}
				<button type="button" onClick={openLocationsModal}>
					<strong className="text-ca-purple">{`${marketingExpectation.scope.locations.length} locations`}</strong>
				</button>{' '}
				to sell{' '}
				<strong className="text-ca-purple">
					{marketingExpectation.salesModifier}
				</strong>{' '}
				times as well this period.
			</p>
		</div>
	);
};

export default MarketingExpectationOption;
