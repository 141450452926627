import { useState, useEffect } from 'react';
import {
	QueryFunction,
	QueryKey,
	UseQueryOptions,
	UseQueryResult,
	useQuery,
} from 'react-query';

interface DebounceOptions {
	debounceDelay?: number;
}

export const useDebouncedQuery = <
	TQueryFnData = unknown,
	TError = unknown,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey
>(
	queryKey: TQueryKey,
	queryFn: QueryFunction<TQueryFnData, TQueryKey>,
	options?: Omit<
		UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
		'queryKey' | 'queryFn'
	> &
		DebounceOptions
): UseQueryResult<TData, TError> => {
	const [newQueryKey, setNewQueryKey] = useState<TQueryKey>(queryKey);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (JSON.stringify(queryKey) !== JSON.stringify(newQueryKey)) {
			const timerId = setTimeout(
				() => setNewQueryKey(queryKey),
				options?.debounceDelay ?? 0
			);
			return () => clearTimeout(timerId);
		}
	}, [queryKey]);

	return useQuery<TQueryFnData, TError, TData, TQueryKey>(
		newQueryKey,
		queryFn,
		options
	);
};
