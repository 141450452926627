import { useQueryClient } from 'react-query';

import useChannelStore from '../data/channels';

const useChannelPrefetch = () => {
	const queryClient = useQueryClient();
	const activeChannel = useChannelStore((s) => s.activeChannel);

	return (queryKey, ...params) => {
		let key = queryKey;
		if (!Array.isArray(queryKey)) {
			key = [queryKey];
		}

		queryClient.prefetchQuery([activeChannel, ...key], ...params);
	};
};

export default useChannelPrefetch;
