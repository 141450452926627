import { useMemo } from 'react';
import { useMutation } from 'react-query';
import PropTypes from 'prop-types';

import {
	GET_BUSINESS_RULES_TAGS,
	CREATE_BUSINESS_RULES_TAG,
} from '../../../shared/api/business-rules';
import TagInput from '../../../shared/components/TagInput/TagInput';
import useChannelQuery from '../../../shared/hooks/useChannelQuery';

const BusinessRulesTagInput = ({ id: fieldId, value, error, onChange }) => {
	const {
		isFetching,
		isLoading,
		data = [],
		refetch,
	} = useChannelQuery('business-rules-tags', GET_BUSINESS_RULES_TAGS);

	const { mutate: createTag, isLoading: isMutationLoading } = useMutation(
		CREATE_BUSINESS_RULES_TAG,
		{
			onSuccess: (tag) => {
				onChange(tag?.id);
				refetch();
			},
		}
	);

	const options = useMemo(
		() =>
			data?.items?.map(({ id, name }) => ({
				value: id,
				label: name,
			})),
		[data?.items]
	);

	const handleChange = (val, isNew) => {
		if (isFetching) {
			return;
		}

		// check if tag really does not exist yet
		const existingTag = options?.find((o) => o.value === val);

		if (isNew && !existingTag) {
			createTag({ name: val });
		} else {
			onChange(existingTag?.value || null);
		}
	};

	return (
		<TagInput
			id={fieldId}
			className="w-full sm:w-64"
			value={value}
			onChange={handleChange}
			options={options}
			loading={isLoading || isMutationLoading}
			error={error}
		/>
	);
};

BusinessRulesTagInput.propTypes = {
	id: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	onChange: PropTypes.func,
};

BusinessRulesTagInput.defaultProps = {
	value: undefined,
	error: false,
	onChange: () => {},
};

export default BusinessRulesTagInput;
