import { Fragment, useState, useRef } from 'react';
import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';

import useOnClickOutside from '../../hooks/useOnClickOutside';

import TableIcon from '../Icons/Table';
import CheckmarkIcon from '../Icons/Checkmark';

import CircularProgress from '../Progress/CircularProgress';
import Text from '../Text/Text';

const TableHeaderButton = ({ headings, loading, onChange, onReset }) => {
	const ref = useRef();
	const [isOpen, setIsOpen] = useState(false);
	useOnClickOutside(ref, () => setIsOpen(false));

	return (
		<div className="relative select-none" ref={ref}>
			<button
				className="p-2.5 flex items-center justify-center text-white bg-ca-purple rounded-lg transition-colors leading-none focus:outline-none focus-visible:ring-4 focus-visible:ring-opacity-10 focus-visible:ring-ca-purple focus-visible:bg-ca-purple-a hover:bg-ca-purple-a active:bg-ca-purple-a"
				type="button"
				onClick={() => setIsOpen((o) => !o)}
			>
				<TableIcon className="w-4" />
			</button>
			<Transition
				appear
				show={isOpen}
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<div className="absolute origin-top-right -mr-10 -translate-x-0.5 right-full w-64 mt-2 py-1 bg-white rounded-lg shadow-ca cursor-pointer focus:outline-none z-20">
					{loading && (
						<div className="flex justify-center py-4">
							<CircularProgress />
						</div>
					)}
					{!loading && (
						<>
							{headings.map((o) => (
								<div
									role="button"
									tabIndex="0"
									key={o.id}
									className="flex justify-between items-center text-ca-black px-4 py-2 cursor-pointer transition-colors hover:bg-ca-silver"
									onClick={() => onChange(o.id)}
									onKeyDown={() => onChange(o.id)}
								>
									<Text>{o.label}</Text>
									{o.enabled && (
										<CheckmarkIcon className="ml-2 h-2 text-ca-purple" />
									)}
								</div>
							))}
							{onReset && (
								<div
									role="button"
									tabIndex="0"
									className="px-4 py-2 cursor-pointer transition-colors hover:bg-ca-silver"
									onClick={onReset}
									onKeyDown={onReset}
								>
									<Text className="font-bold">Reset headings</Text>
								</div>
							)}
						</>
					)}
				</div>
			</Transition>
		</div>
	);
};

TableHeaderButton.propTypes = {
	headings: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
			enabled: PropTypes.bool,
		})
	),
	loading: PropTypes.bool,
	onChange: PropTypes.func,
	onReset: PropTypes.func,
};

TableHeaderButton.defaultProps = {
	headings: [],
	loading: false,
	onChange: () => {},
	onReset: () => {},
};

export default TableHeaderButton;
