import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
import tailwindColors from 'tailwindcss/colors';
import tailwindTheme from 'tailwindcss/defaultTheme';
import { ResidualValueDataPoint } from '../types/residualValueTypes';

/**
 * Between 0 and 100
 */
type Percentage = number;

type ResidualValueGraphProps = {
	height: number;
	width: number;
	extraDataPoints: ResidualValueDataPoint[];
	/**
	 * Between 0 and 100
	 */
	maxResidualValue?: Percentage;
	/**
	 * Between 0 and 100
	 */
	minResidualValue?: Percentage;
	/**
	 * Between 0 and 100
	 */
	sellThroughTarget?: Percentage;
};

const ResidualValueGraph: React.FC<ResidualValueGraphProps> = (props) => {
	const svgRef = useRef<SVGSVGElement>(null);

	const buildLineGraph = (
		element: SVGSVGElement,
		data: ResidualValueDataPoint[]
	) => {
		const marginLeft = 4;
		const marginTop = 8;
		const marginBottom = 8;
		const marginRight = 12;

		const scaleXHeight = 10;
		const scaleYWidth = 30;
		const titleYWidth = 25; // space between svg.left and graphWithScaleY.left (width)
		const titleXHeight = 40; // space between svg.bottom and graph.bottom (height)

		const strokeDashArray = [12, 5];

		const titleFill = tailwindColors.zinc['400'];
		const titleSize = tailwindTheme.fontSize.sm[0];
		const axisStyling = 'text-zinc-400 text-right';
		const labelStyling = 'text-xs text-zinc-800';

		const graph = d3.select(element);
		graph.selectAll('*').remove();
		graph
			.attr('widht', props.width)
			.attr('height', props.height)
			.attr('viewBox', [0, 0, props.width, props.height])
			.attr('style', 'max-width: 100%; height: auto; height: intrinsic;');

		const xScale = d3
			.scaleLinear()
			.domain([0, 100])
			.range([
				marginLeft + titleYWidth + scaleYWidth,
				props.width - marginRight,
			]);
		const yScale = d3
			.scaleLinear()
			.domain([0, 100])
			.range([
				props.height - titleXHeight - scaleXHeight - marginBottom,
				marginTop,
			]);

		const xAxis = d3.axisBottom(xScale).ticks(10).tickPadding(5).tickSize(5);
		const yAxis = d3.axisLeft(yScale).ticks(100 / 20);

		const graphLeft = marginLeft + titleYWidth + scaleYWidth;
		const graphRight = props.width - marginRight;
		const graphTop = marginTop;
		const graphBottom =
			props.height - marginBottom - titleXHeight - scaleXHeight;

		// Horizontal dotted guides
		graph
			.append('g')
			.selectAll('.thisdoesntmatter')
			.data(yScale.ticks(100 / 20))
			.enter()
			.append('line')
			.attr('class', `horizontalGuide`)
			.attr('y1', (d) => yScale(d))
			.attr('y2', (d) => yScale(d))
			.attr('x1', graphLeft)
			.attr('x2', graphRight)
			.attr('fill', 'none')
			.attr('stroke', `${tailwindColors.zinc['200']}`)
			.attr('stroke-width', '1px')
			.attr('shape-rendering', 'crispEdges')
			.attr('stroke-dasharray', `${strokeDashArray.join(' ')}`);

		// Vertical dotted guides
		graph
			.append('g')
			.selectAll('.thisdoesntmatter')
			.enter()
			.data(xScale.ticks(100 / 10))
			.enter()
			.append('line')
			.attr('class', 'verticalGuide')
			.attr('x1', (d) => xScale(d))
			.attr('x2', (d) => xScale(d))
			.attr('y1', graphTop)
			.attr('y2', graphBottom)
			.attr('fill', 'none')
			.attr('stroke', `${tailwindColors.zinc['200']}`)
			.attr('stroke-width', '1px')
			.attr('shape-rendering', 'crispEdges')
			.attr('stroke-dasharray', `${strokeDashArray.join(' ')}`);

		// Makes outer guides (most top and right) not dashed lines but solid lines.
		graph
			.select('line.verticalGuide:last-of-type')
			.attr('stroke-dasharray', 'none');

		graph
			.select('line.horizontalGuide:last-of-type')
			.attr('stroke-dasharray', 'none');

		// y Axis
		const $yAxis = graph.append('g');
		$yAxis
			.attr(
				'transform',
				`translate(${marginLeft + titleYWidth + scaleYWidth}, 0)`
			)
			.attr('class', axisStyling)
			.call(yAxis);

		// x Axis
		const $xAxis = graph.append('g');
		$xAxis
			.attr(
				'transform',
				`translate(0,${
					props.height - marginBottom - scaleXHeight - titleXHeight
				})`
			)
			.attr('class', axisStyling)
			.call(xAxis);
		$xAxis.selectAll('g > text').attr('class', labelStyling);
		$yAxis.selectAll('g > text').attr('class', labelStyling);

		// X axis title
		graph
			.append('text')
			.attr(
				'transform',
				`translate(${(graphRight - graphLeft) / 2 + graphLeft},${
					props.height - marginBottom
				})`
			)
			.attr('dx', '0')
			.attr('text-anchor', 'middle')
			.attr('fill', titleFill)
			.attr('font-size', titleSize)
			.text('% Unsold inventory');

		// Y axis title
		graph
			.append('text')
			.text('% Residual value')
			.attr('text-anchor', 'middle')
			.attr('dominant-baseline', 'hanging') // .attr('dy', '1em') // same effect as 'dominant-baseline'
			.attr(
				'transform',
				`translate(${marginLeft},${
					(graphBottom - graphTop) / 2 + marginTop
				})rotate(-90)`
			)
			.attr('fill', titleFill)
			.attr('font-size', titleSize);

		// Line
		const line = d3.line(
			(d) => xScale(d[0]),
			(d) => yScale(d[1])
		);

		const transformedData: [number, number][] = data.map((d) => [
			d.atUnsoldStockPercent,
			d.residualValue,
		]);

		graph
			.append('path')
			.attr('d', line(transformedData))
			.attr('stroke', '#6111C7')
			.attr('stroke-width', 1.5)
			.attr('fill', 'none');
	};

	useEffect(() => {
		if (svgRef.current) {
			let data = props.extraDataPoints ?? [];

			const validate = (value: number | undefined, fallback: number): number =>
				!Number.isNaN(value) ? value ?? fallback : fallback;
			const transformedMaxResidualValue: number = validate(
				props.maxResidualValue,
				1
			);
			const transformedMinResidualValue: number = validate(
				props.minResidualValue,
				transformedMaxResidualValue
			);
			const transformedSellThroughTarget: number =
				100 - validate(props.sellThroughTarget, 50);
			data.push({
				atUnsoldStockPercent: 0,
				residualValue: transformedMaxResidualValue,
			});
			data.push({
				atUnsoldStockPercent: transformedSellThroughTarget,
				residualValue: transformedMaxResidualValue,
			});
			data.push({
				atUnsoldStockPercent: 100,
				residualValue: transformedMinResidualValue,
			});

			data = data.sort(
				(a, b) => a.atUnsoldStockPercent - b.atUnsoldStockPercent
			);
			buildLineGraph(svgRef.current, data);
		}
	}, [svgRef, props]);
	return <svg ref={svgRef} />;
};

export default ResidualValueGraph;
