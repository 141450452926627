import { Route, Redirect } from 'react-router-dom';

import Page from '../../shared/components/Page/Page';
import RouterSwitch from '../../shared/components/RouterSwitch/RouterSwitch';
import StrategyHeader from '../components/StrategyHeader';

import ProductCategoriesCreateEdit from './ProductCategories/CreateEdit';
import ProductCategoriesOverview from './ProductCategories/Overview';

import BusinessRulesCreateEdit from './BusinessRules/CreateEdit';
import BusinessRulesOverview from './BusinessRules/Overview';
import StrategyComponentsEdit from './Components/Edit';
import StrategyComponentsOverview from './Components/Overview';
import StrategyOverview from './Overview';

const StrategyView = () => (
	<Page>
		<StrategyHeader />
		<RouterSwitch>
			<Route exact path="/strategy">
				<Redirect to="/strategy/strategies" />
			</Route>
			<Route
				exact
				path="/strategy/categories"
				component={ProductCategoriesOverview}
			/>
			<Route
				exact
				path="/strategy/categories/create"
				component={ProductCategoriesCreateEdit}
			/>
			<Route
				exact
				path="/strategy/categories/:categoryId/edit"
				component={ProductCategoriesCreateEdit}
			/>
			<Route
				exact
				path="/strategy/business-rules"
				component={BusinessRulesOverview}
			/>
			<Route
				exact
				path="/strategy/business-rules/create"
				component={BusinessRulesCreateEdit}
			/>
			<Route
				exact
				path="/strategy/business-rules/:businessRuleId/edit"
				component={BusinessRulesCreateEdit}
			/>
			<Route
				exact
				path="/strategy/strategies/:strategyId/components/:componentId/edit"
				component={StrategyComponentsEdit}
			/>
			<Route exact path="/strategy/strategies" component={StrategyOverview} />
			<Route
				exact
				path="/strategy/strategies/:strategyId"
				component={StrategyComponentsOverview}
			/>
		</RouterSwitch>
	</Page>
);

export default StrategyView;
