import { Route, Redirect } from 'react-router-dom';

import RouterSwitch from '../../shared/components/RouterSwitch/RouterSwitch';
import Page from '../../shared/components/Page/Page';
import SupportHeader from '../components/SupportHeader';
import SupportValidation from './SupportValidation';

const ActualsView = () => (
	<Page>
		<SupportHeader />
		<RouterSwitch>
			<Route exact path="/support">
				<Redirect to="/support/validation" />
			</Route>
			<Route exact path="/support/validation" component={SupportValidation} />
		</RouterSwitch>
	</Page>
);

export default ActualsView;
