import ChannelSelector from '../../shared/components/ChannelSelector/ChannelSelector';
import Greeting from '../../shared/components/Greeting/Greeting';
import Title from '../../shared/components/Title/Title';

import StrategySelector from '../../shared/components/StrategySelector/StrategySelector';
import OpportunitiesNav from './OpportunitiesNav';

const OpportunitiesHeader = () => (
	<>
		<div className="flex justify-between">
			<div className="flex flex-col gap-2">
				<Title>Opportunities</Title>
				<Greeting />
			</div>
			<div className="flex flex-col gap-2">
				<ChannelSelector />
				<StrategySelector />
			</div>
		</div>
		<div className="mt-6">
			<OpportunitiesNav />
		</div>
	</>
);

export default OpportunitiesHeader;
