import PropTypes from 'prop-types';

const TriangleDown = ({ className }) => (
	<svg
		className={className}
		viewBox="0 0 12 8"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m2.20376823 0h9.59246357c.5522847 0 1 .44771525 1 1 0 .24222315-.0879183.47621311-.2474233.65850461l-4.79623181 5.48140774c-.36368205.41563663-.99544467.45775414-1.4110813.09407209-.03341077-.02923443-.06483766-.06066132-.09407208-.09407209l-4.79623178-5.48140774c-.36368205-.41563663-.32156454-1.04739925.09407209-1.4110813.18229149-.15950506.41628146-.24742331.65850461-.24742331z"
			fill="currentColor"
			fillRule="evenodd"
			transform="translate(-1)"
		/>
	</svg>
);

TriangleDown.propTypes = {
	className: PropTypes.string,
};

TriangleDown.defaultProps = {
	className: '',
};

export default TriangleDown;
