import clsx from 'clsx';
import Tooltip from '../Tooltip/Tooltip';

const MarkdownTransferRadios = ({
	options,
	comingSoonOptions,
	value,
	onChange,
}) => {
	const optionsExtended = [];
	options.forEach((option) =>
		optionsExtended.push({ value: option, isComingSoon: false })
	);
	comingSoonOptions.forEach((comingSoonOption) =>
		optionsExtended.push({ value: comingSoonOption, isComingSoon: true })
	);

	const createLabelInner = (state, optionValue, htmlFor = '') => {
		return (
			<label
				className={clsx(
					state === 'focused' &&
						'border border-green-500 text-green-500 bg-green-100 cursor-pointer',
					state === 'default' &&
						'hover:border hover:border-gray-300 cursor-pointer',
					(state === 'default' || state === 'comingSoon') &&
						'text-gray-500 bg-gray-200 border border-gray-200 ',
					state === 'comingSoon' && 'opacity-30 cursor-not-allowed',
					'select-none py-1.5 px-3 transition-all capitalize rounded-lg'
				)}
				htmlFor={htmlFor}
			>
				{optionValue}
			</label>
		);
	};

	return (
		<div className="flex gap-2 mb-4">
			{optionsExtended.map((optionObject, index) => (
				<div key={optionObject.value} className="">
					<input
						className="hidden"
						type="radio"
						value={optionObject.value}
						name="units"
						id={`unit-${index}`}
						checked={value === optionObject.value}
						onChange={(e) => onChange(e.currentTarget.value)}
					/>
					{optionObject.isComingSoon ? (
						<Tooltip content="This option is coming soon!">
							<span>{createLabelInner('comingSoon', optionObject.value)}</span>
						</Tooltip>
					) : (
						createLabelInner(
							value === optionObject.value ? 'focused' : 'default',
							optionObject.value,
							`unit-${index}`
						)
					)}
				</div>
			))}
		</div>
	);
};

export default MarkdownTransferRadios;
